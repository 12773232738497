import React, {Component} from 'react';
import Header from '../components/Header';
import Dashboard from '../components/Dashboard';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { fetchAllTAccountinfo } from '../actions/accountinfo';

class DashboardContainer extends Component {
	componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(fetchAllTAccountinfo());
    }
    render(){
        const {accountinfo}= this.props;
        return (
            <div id="main-wrapper">
                <Header/>
                    <Dashboard accountinfo={accountinfo}/>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        accountinfo: state.accountinfo,
    };
};
export default connect(
  mapStateToProps,
  null
)(DashboardContainer);