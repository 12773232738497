import { FETCH_SUB_CATEGORY_PRO } from '../actions/types';

export default function subcategoryproReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SUB_CATEGORY_PRO:
      return action.subcategorypro;
    default:
      return state;
  }
}
