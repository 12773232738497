import { FETCH_RELATEDPRO,BASE_URL } from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'relatedpro';

export const fetchRProducts = (fetchrelatedpro) => {
	return {
		type: FETCH_RELATEDPRO,
		fetchrelatedpro
	}
};

export const fetchAllRProducts = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
      .then(response => {
		if(response.data.logstatus ==='failed'){			 
		}else{
			dispatch(fetchRProducts(response.data))
		}
      })
      .catch(error => {
        throw(error);
      });
  };
};