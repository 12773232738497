import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";



export default class ProductSlider extends React.Component {
  render() {
    return <ImageGallery items={this.props.arrayImage} 
    loading={'lazy'} showNav ={false} thumbnailPosition={'left'} showFullscreenButton={false} showPlayButton={false}/>;
  }
}