import {FETCH_BLOGCOMMENT,ADD_COMMENT,ADDBLOGCOMMENT_SUBMIT} from '../actions/types';
const initialState = {
  loading: '',
};

export default function blogcommentReducer(state = [], action) {
	const { type, payload } = action;
		switch (action.type) {
	    	case ADDBLOGCOMMENT_SUBMIT: 
	            return {
	                ...state,
	                loading: true
	            }
			case ADD_COMMENT:
				return [...state];

			case FETCH_BLOGCOMMENT:      
				return action.blogcomment
			default:
			return state;
 		}
}