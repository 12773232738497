import { SIGNUP,SIGNUP_SUBMIT,BASE_URL,SIGNUP_FAILED, SET_CURRENT_USER,
  AUTH_ERROR,
  LOGIN,
  LOGIN_SUBMIT,
  LOGOUT,
LOGIN_FAIL,AUTH_CHECK
} from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const addapiUrl = BASE_URL+'register';
const addapiloginUrl =BASE_URL+'login';  


export const signup = ({name,lastname,email,phone,password,sms_preference,email_preference,whatsapp_preference}) => {
  return (dispatch) => {
    dispatch(signupSubmit());
    return axios.post(addapiUrl, {name,lastname,email,phone,password,sms_preference,email_preference,whatsapp_preference})
      .then(response => {
        if(response.data.logstatus =='failed'){
           dispatch(signupFailed()); 
           dispatch(setAlert('Your Email Id already exists with The Organic Forest.', 'danger'));
        }else{
          dispatch(signupSuccess(response.data));
          dispatch(setAlert('Thank You for Registering with The Organic Forest.', 'success'));
        }
      })
      .catch(error => {
        throw(error);
      });
  };
};

export const signupSuccess =  (data) => {
  return {
    type: SIGNUP,
    payload: {
     name: data.name,
     lastname: data.lastname,
     email: data.email,
     phone: data.phone,
     password: data.password,
     sms_preference: data.sms_preference,
     email_preference: data.email_preference,
     whatsapp_preference: data.whatsapp_preference,
   }
  }
};



function signupSubmit() {
    return {
        type: SIGNUP_SUBMIT
    }
}

function loginSubmit() {
    return {
        type: LOGIN_SUBMIT
    }
}





function signupFailed() {
    return {
        type: SIGNUP_FAILED
    }
}




export function setCurrentUser(data) {
  return {
    type: SET_CURRENT_USER,
    payload:data.data
  };
}


export const loginPost = ({ email, password }) => {
  return (dispatch) => {
     dispatch(loginSubmit());
    return axios.post(addapiloginUrl, {email, password})
      .then(response => {
        
         if(response.data.error)
        {
       
        
        dispatch({
         type: LOGIN_FAIL
     
       });
       dispatch(setAlert(response.data.error, 'danger','logindiv'));

       }
        else
       {
          
         localStorage.setItem("token", response.data.token);
          if (localStorage.token) {
          setAuthToken(localStorage.token);
          }

         dispatch(loginSuccess(response.data)) 
         //console.log(jwtDecode(localStorage.token));
         dispatch(setCurrentUser(jwtDecode(localStorage.token)));

        }
        
  

      })
      .catch(error => {
        throw(error);
      });

  };
};


export const loginSuccess =  (data) => {

  return {
    type: LOGIN,
    payload:data
  }
};




export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
  setAuthToken(localStorage.token);
};


