import { ADD_SUBSCRIBE,SUBSCRIBE_SUBMIT,SUBSCRIBE_FAILED } from '../actions/types';

const initialState = {
  loading: '',
};


export default function createsubscribeReducer(state =initialState, action) {
 const { payload } = action;

  switch (action.type) {
  	case SUBSCRIBE_SUBMIT: 
            return {
                ...state,
                loading: true
            }
    case SUBSCRIBE_FAILED: 
            return {
                ...state,
                loading: false
            }        
    case ADD_SUBSCRIBE:
    return {
        ...state,
        ...payload,
        loading: false
      };

 default:
      return state;
  }
}



