import React, {Component} from 'react';
import Header from '../components/Header';
import Faq from '../components/Faq';
import Footer from '../components/Footer';


export default class FaqContainer extends Component {


	componentDidMount() {
    window.scrollTo(0, 0);
}

    render(){
        return (

        <div id="main-wrapper">
        <Header/>
        <Faq/>
        <Footer/>
        </div>



        )
    }
}