import { FETCH_ORDERSTATUS,BASE_URL,STATUS_CHANGE} from './types';
import axios from 'axios';
import { setAlert } from './alert';

const apiUrl = BASE_URL+'orderstatuschange/';

export const fetchOrder = (orderstatus) => {
  return {
    type: FETCH_ORDERSTATUS,
    orderstatus
  }
};

export const fetchAllOrder = (id) => {
  return (dispatch) => {
    return axios.get(apiUrl+id)
      .then(response => {
        dispatch(fetchOrder(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};

//let id = window.location.href.split("/").pop(); 
const addapiUrl = BASE_URL+'statuschange/';

export const createstatus = ({id,enumstatus,reason}) => {	
	return (dispatch) => {
		return axios.put(addapiUrl+id, {id,enumstatus,reason})

 .then(response => {
    if(response.data.logstatus ==='true'){
        dispatch(createstatussuccess(response.data))
        dispatch(setAlert('Order Status Changed successfully.', 'success'));

      }
      if(response.data.logstatus ==='failed'){ 
        dispatch(setAlert('Please try again', 'danger'));

      
      }
      if(response.data.logstatus ==='userid not found'){  
     
        dispatch(setAlert('No record found for this user', 'danger'));
      }
       })
		.catch(error => {
			throw(error);
		});
	};
};


export const createstatussuccess =  (post) => {
  return {
    type: STATUS_CHANGE,
    payload: {
     enumstatus: post.enumstatus,
     reason: post.reason,
     id: post.id,
   }
  }
};

