import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTPosts } from '../actions/wishlist';
import { connect } from 'react-redux';
import Wishlist from '../components/Wishlist';
import Accountsidebar from '../components/Accountsidebar';
import Footer from '../components/Footer';

class WishlistContainer extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
       const id=this.props.auth.user.id

        this.props.dispatch(fetchAllTPosts());
    }

    render(){
        const {wishlist}  = this.props;
        if (!wishlist) return <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;

        return (
            <div id="main-wrapper">
                <Header/>   
                    <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    
                                    <div className="page-banner text-center">
                                        <h1>My Account</h1>
                                        <ul className="page-breadcrumb">
                                            <li><a href="/">Home</a></li>
                                            <li>Wishlist</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
                    <div class="container">
                        <div class="row">
                    
                    <div class="col-12">
                        <div class="row">
                           <Accountsidebar/>
                            <div class="col-lg-9 col-12">
                                <div class="tab-content" id="myaccountContent">                                   
                                    <div class="tab-pane fade show active" id="orders" role="tabpanel">
                                        {wishlist==''?<center>
                                            <h3 className="wishli">No Wishlist Found</h3>
                                                <br/>
                                                <br/>
                                                    <h5>Use the wishlist to add your favorite items as you start shopping.</h5>
                                                <br/>
                                                <br/>
                                                    <a href="/" class="btn">CONTINUE SHOPPING</a><br/>
                                                <br/>
                                                <br/>
                                            <br/>
                                        </center>:
                                           <div class="myaccount-contentab">
                                            <h3 class="text-center wishtext">Wishlist</h3>   
                                             <div class="myaccount-table table-responsive text-center">
                                                <table class="table table-bordered">
                                                    <thead class="thead-light">                               
                                                        <tr>                                                            
                                                            <th class="pro-thumbnail">Item</th> 
                                                            <th class="pro-addtocart"></th>

                                                            <th class="pro-addtocart">Add to cart</th>
                                                            <th class="pro-remove">Remove</th>
                                                        </tr>
                                                    </thead>

                                                        <Wishlist wishlist={wishlist} />
                                        



                                                </table>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>                           
                        </div>    
                    </div>                    
                </div> 
            </div>           
        </div>
     
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    wishlist: state.wishlist.wishlist,
        auth: state.auth


  };
};


export default connect(
  mapStateToProps,
  null
)(WishlistContainer);


