import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTPosts } from '../actions/tag';
import { connect } from 'react-redux';
import Product from '../components/Products';
import Footer from '../components/Footer';

class TagContainer extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
		const slug = window.location.href.split("/").pop(); 
		this.props.dispatch(fetchAllTPosts(slug));
	}

 
 render(){

 
 const {tag}  = this.props;
      



        return (
			<div id="main-wrapper">
				<Header/>
					<div class="shop-section section pt-60 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
						<div class="container">       
							<div class="row">
								<div class="col-12">
									<div class="shop-banner-title">
										<h2 class="leafbg" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>Products</h2>
									</div>
								</div>
							</div>
						  <div className="row">
                    <div className="col-12">                        
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="products">
                                <div className="row" >
                                  {tag.map(post => {
                                        return (
                                             <Product post={ post }  key={ post.id } />
                                         );
                                    })} 
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


							
						</div>
					</div>
				<Footer/>
			</div>
        )
    }
}
const mapStateToProps = state => {
  return {
    tag: state.tag
  };
};


export default connect(
  mapStateToProps,
  null
)(TagContainer);