import { BASE_URL,FETCH_PROREVIEW,ADD_REVIEW,REVIEWCOMMENT_SUBMIT} from '../actions/types';
const initialState = {
  loading: '',
};

export default function reviewsubmitReducer(state = [], action) {
const { type, payload } = action;

  switch (action.type) {

  	case REVIEWCOMMENT_SUBMIT: 
            return {
                ...state,
                loading: true
            }
    case ADD_REVIEW:
      return [...state];

     case FETCH_PROREVIEW:      
			return action.review

    default:
      return state;
  }
}
