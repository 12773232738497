import { RELATED_PRODUCTS } from '../actions/types';

export default function relatedproductsReducer(state = [], action) {
  switch (action.type) {
    case RELATED_PRODUCTS:
      return action.relatedproducts;
    default:
      return state;
  }
}
