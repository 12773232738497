import { FETCH_CATEGORY_PRO } from '../actions/types';

export default function categoryproReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CATEGORY_PRO:
      return action.categorypro;
    default:
      return state;
  }
}
