import React, {Component} from 'react';

import Oursolution from '../components/Oursolution';
import { fetchAllSolutions } from '../actions/solutions';
import { connect } from 'react-redux';

class OursolutionsContainer extends Component {


componentDidMount() {
window.scrollTo(0, 0);
this.props.dispatch(fetchAllSolutions());

}




render(){

const {fetchsolutions}  = this.props;


return (


<div id="main-wrapper">



<div className="product-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50 oursolutionsec rangespage">
<div className="container">

<div className="row">

<div className="col">
<div className="section-title mb-20 mb-xs-20 text-center">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>OUR RANGES</h2>
</div>
</div>

</div>
<div className="row">
<div className="col-12">                        
<div className="tab-content">
<div className="tab-pane fade show active" id="products">
<div className="row" >
{/*<div className="col-lg-1"></div>*/}

{fetchsolutions.map(post => {
return (

<Oursolution post={ post }  key={ post.id } />

);
})}


{/*<div className="col-lg-1"></div>*/}





</div>
</div>                           

</div>
</div>
</div>

</div>
</div>



</div>



)
}
}





const mapStateToProps = state => {
return {
fetchsolutions: state.fetchsolutions
};
};


export default connect(
mapStateToProps,
null
)(OursolutionsContainer);