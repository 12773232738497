import React from 'react';

export default class Philosophy extends React.Component {
    render(){
    return (
  <div className="about-us-area section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 oursolutionsec">
<div className="container">

<div class="row"><div class="col"><div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28'}}>Our Philosophy</h2>

</div></div></div>
<div className="row">
<p class="prod-title ptag">
We are on a mission to take Hair and Skin from Blah to Brilliant through a perfect combination of all organic super nutrients thus creating the best product that allows you to shine the brightest.
</p>




<p class="prod-title ptag "><h2 align="center" className="" style={{'fontWeight':'600','color':'#db9c28'}}>OUR VALUES</h2></p>

<p class="prod-title ptag"><h4 align="center" className="" style={{'fontWeight':'600','color':'#db9c28'}}>COMPASSIONATE</h4></p>

<p class="prod-title ptag textAlignCenter">

Compassionate is one of the core value in today’s environment humanity needs the most, Our commitment for environment sustainability,  animal care & to reduce carbon footprints  to overall less impact on environment denotes it. <br/>

<span style={{'textAlign':'center'}}><span style=
{{'fontSize': '24px'}}>“</span>Compassion is the basis of morality.<span style=
{{'fontSize': '24px'}}>”</span> </span> 

</p>



<p class="prod-title ptag"><h4 align="center" className="" style={{'fontWeight':'600','color':'#db9c28'}}>HONEST</h4></p>

<p class="prod-title ptag textAlignCenter">

Being Honest & Transparent in what we offer, As a brand we kept of list of ingredients accessible to all. 
 <br/>

<span style={{'textAlign':'center'}}><span style=
{{'fontSize': '24px'}}>“</span>Honesty and transparency make you vulnerable. Be honest and transparent anyway.<span style=
{{'fontSize': '24px'}}>”</span> </span> 

</p>



<p class="prod-title ptag"><h4 align="center" className="" style={{'fontWeight':'600','color':'#db9c28'}}>NATURAL</h4></p>

<p class="prod-title ptag textAlignCenter">
A Big Believer of nature’s strength to rejuvenate & Renew & What role it plays in our life.
 <br/>

<span style={{'textAlign':'center'}}><span style=
{{'fontSize': '24px'}}>“</span>Nature does not hurry, yet everything is accomplished.<span style=
{{'fontSize': '24px'}}>”</span> </span> 

</p>



<p class="prod-title ptag"><h4 align="center" className="" style={{'fontWeight':'600','color':'#db9c28'}}>CARING</h4></p>

<p class="prod-title ptag textAlignCenter">
Sometimes, a little extra care is needed to get the desired results.
 <br/>

<span style={{'textAlign':'center'}}><span style=
{{'fontSize': '24px'}}>“</span>Without a sense of caring, there can be no sense of community.<span style=
{{'fontSize': '24px'}}>”</span> </span> 

</p>


<p class="prod-title ptag"><h4 align="center" className="" style={{'fontWeight':'600','color':'#db9c28'}}>INCLUSIVE</h4></p>

<p class="prod-title ptag textAlignCenter">
A world inclusive of men, women, children, animals and their wellness is what we look forward to.  
 <br/>

<span style={{'textAlign':'center'}}><span style=
{{'fontSize': '24px'}}>“</span>Being inclusive means to appreciate differences and enjoy the diverse viewpoints.<span style=
{{'fontSize': '24px'}}>”</span> </span> 

</p>





<p class="prod-title ptag">

At The Organic Forest, securing and maintaining your trust is our most important job. And we’ve been committed to upholding your trust .As we’ve worked to have even more open conversations with you, it’s clear that many of you are asking about the ingredients in our products. At The Organic Forest, we’ve always believed you deserve a safe product experience. And while your definition of what is safe has evolved over time, our commitment to continuing to raise the bar on your safety has not wavered. This is not a one person job- in fact we work with external scientists, regulatory agencies and internal experts on new safety methods. We are committed to continuing to make our products effective and safe for you and everyone who uses them.

</p>

<p class="prod-title ptag">
Here is the list of “STRICT NO LIST”</p>
<p class="prod-title ptag">
SLS | SLES | PARABENS | MEA | DEA | TEA | PETROLATUM | PHTHALATES | ETHOXYLATES | ARTIFICIAL COLOURS | ARTIFICIAL FRAGRANCES | SILICONES</p>








<p class="prod-title ptag textAlignCenter">&nbsp;</p>

</div>
</div>
</div> 
)
    }
}



