import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';

export default ({ post: {headline,review,rating,tof_product,status} }) => {
	const rstatus = status;
	return (
    		
    <div class="row historytbl">
   
    <div class="col-sm-12  border border-secondry historytblnew">
    <div class="myaccount-table table-responsive text-center reviewdiv">
    <Link to ={'/product/' +tof_product.slug} style={{'float':'left'}}>
    <table cellspacing="0" cellpadding="0" border="0" class="table tablebordered" style={{'marginBottom':'0.1rem'}}> 
    <tbody>
    <tr class="tabletr"> 
    <td class="tdimgdata" valign="top" align="left" style={{'vertical-align':'top','textAlign':'center'}}> 
    <div classname="reviewimgcon">
    <img src={IMG_BASE_URL+'products/'+tof_product.image} alt=""  class="CToWUd loading" border="0" data-was-processed="true"  style={{'height':'30'}} /> 
   </div>
    </td> 
    <td class="tabletd tablebordertd" valign="top" align="left" style={{'paddingTop':'12px','verticalAlign':'top !important','paddingBottom':'0px',}}>
    <p class="tablep" style={{'marginBottom':'15px','padding-bottom':'0%'}}> 
    {tof_product.name}
    <div class="ratingfill">
    <i className={"fa fa-leaf " + (rating < 1 ? 'unselctleaf' : '')}></i>
    <i className={"fa fa-leaf " + (rating < 2 ? 'unselctleaf' : '')}></i>
    <i className={"fa fa-leaf " + (rating < 3 ? 'unselctleaf' : '')}></i>
    <i className={"fa fa-leaf " + (rating < 4 ? 'unselctleaf' : '')}></i>
    <i className={"fa fa-leaf " + (rating < 5 ? 'unselctleaf' : '')}></i>
    <p class="ttlprice" style={{'marginBottom':'5px','padding-bottom':'0%','whiteSpace':'normal'}}>{status?'Active':'Inactive'}</p></div></p>
    <p class="ttlprice" style={{'marginBottom':'5px','padding-bottom':'0%','whiteSpace':'normal'}}>{headline}</p>
    <p style={{'whiteSpace':'normal','lineHeight':'20px','marginBottom':'5px','whiteSpace':'normal'}}>{review}



    </p>
    </td>
    </tr>
    </tbody>
    </table> </Link>
    </div></div>
   
    </div> 
   

	)
}











