import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import {Currency} from '../utils/currency';
export default ({ post: {tof_product,product_price,enumstatus,feature_image,sizes,id,quantities}}) => {
return (
		<tr class="tabletr"> 
			<td class="tdimgdata"valign="middle" align="left"> 
			<Link to ={'/product/' +tof_product.slug} >
<img src={IMG_BASE_URL+'products/'+feature_image} alt=""/>
			</Link> 
			</td> 
			<td class="tabletd tablebordertd" width="70%" valign="middle" align="left">
				<p class="tablep"> 
				<Link to ={'/product/' +tof_product.slug} style={{'whiteSpace':'pre-wrap','lineHeight':'18px'}} >
				{tof_product.name}</Link>

				</p> 
				<p class="ttlprice">Price : {Currency}{parseFloat(product_price).toFixed(2)}<br />
				<span class="prodstus">Status : {enumstatus}</span><br/>
				<span class="prodstus">Qty : {quantities}</span><br/>
				{sizes=== null ||sizes=='undefined'||sizes==''?'':<span class="prodstus">Size : {sizes}</span>}
				</p>
			</td>

			{/*<td width="20%" valign="middle" align="center" class="tdpdng tablebordertd">

{enumstatus=== 'Order Confirmed'|| enumstatus==='Processing' ?<Link to ={'/cancelorderproduct/'+id} className="descount-sticker_btn">
Cancel</Link>: enumstatus=== 'Shipped'||enumstatus=== 'Complete' ?<Link to ={'/returnorderproduct/'+ id} className="descount-sticker_btn">
Return </Link>:''}</td>*/}


			<td width="20%" valign="middle" align="center" class="tdpdng tablebordertd">
			<Link to ={'/product/' +tof_product.slug+'#review'} className="descount-sticker_btn1 descount-sticker_btn" target={"_blank"}>
			Share Review</Link>
			</td>
		</tr>

        )
    }
