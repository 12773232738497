import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { createaddtoWishlist } from '../actions/wishlist';
import {connect} from 'react-redux';

class Addtocart extends Component {

state = {
  wishlisticon:false,
}

addtoWishlist(post) {
    this.props.onAddtowishlist(post);
   
}
switchicon() {
	this.setState({wishlisticon: !this.state.wishlisticon}); 
}

 render(){
		const post= this.props.produtdata;
		const {isAuthenticated, loading}=this.props.auth;
		const icon = this.state.wishlisticon ? 'fa fa-heart fa-2x':'fa fa-heart-o fa-2x';
		return ( 
			<div className="wishicon">
				{!loading && (
				isAuthenticated?
					<a href={null} onClick={() => {this.addtoWishlist(post);this.switchicon(); }}><i className={icon} style={{'fontSize':'20px !important'}}></i></a>
					: 
					<Link to ={'/login'}>
					<a href={null} onClick={() => {this.addtoWishlist(post);this.switchicon(); }}><i className={icon} style={{'fontSize':'20px !important'}}></i></a>
				</Link>
				)}
			</div>
		)
	}
}



const mapDispatchToProps = dispatch => {
  return {
    onAddtowishlist: post => {
     dispatch(createaddtoWishlist(post));
    }
  };
};

const mapStateToProps = state => {
	return {
        auth:state.auth,
	};
}

export default connect(
mapStateToProps,
mapDispatchToProps
)(Addtocart);

