import React, {Component} from 'react';
import Alert from '../layout/Alert';
import Header from '../components/Header';
import Accountsidebar from '../components/Accountsidebar';
import { connect } from 'react-redux';
import Footer from '../components/Footer';

    const initialState =  {
        password:'',
        passwordError:'',
        new_password :'',
        new_passwordError:'',
        confirm_password:'',
        confirm_passwordError:'',
    };




class Changepassword extends Component {
    constructor(props) {
        super(props); 
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    state = initialState;


        handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });


    }
    validate = () => {          
        let new_passwordError = ""; 
        let confirm_passwordError = ""; 
        let passwordError = "";
         
            if (!this.state.new_password) {
            new_passwordError = "Password cannot be blank.";
            } 
            else if (this.state.new_password.length < 8) {
            new_passwordError = "Password must be 8 characters long.";
            } 
          
         if (!this.state.password) {
            passwordError = "Current Password cannot be blank.";
        }
        if (!this.state.confirm_password) {
            confirm_passwordError = "Confirm Password cannot be blank.";
        }
        
        if (this.state.new_password && this.state.confirm_password) {
            if (this.state.new_password !== this.state.confirm_password) {
                confirm_passwordError = " New and Confirm Password are not same.";
            }
        }
        
        if (new_passwordError || confirm_passwordError||passwordError) {
            this.setState({new_passwordError,confirm_passwordError,passwordError});
            return false;
        }
        return true;
    };

    handleSubmit = e => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {  
            this.props.onchange(this.state);
            this.setState(initialState);
        }
    };

    render(){
       const { loading } = this.props.changepass;
     return (
		<div>
        <Header/>
			<div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                            <h1>My Account</h1>
                            <ul className="page-breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li>Change Password</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
			<div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">                    
                    <div class="col-12">
                        <div class="row">                          
                              <Accountsidebar/>      

                            <div class="col-lg-9 col-12">
                                <div class="tab-content" id="myaccountContent">                                 
                                    <div class="tab-pane fade show active" id="account-info" role="tabpanel">
                                        <div class="myaccount-contentab">
                                            <h3>Change Password</h3>    
                                            <div class="account-details-form">
                                                    <Alert  msgdivid="change"/>
                                               <form onSubmit={ this.handleSubmit }>
                                                    <div class="row">
                                                    <div className="col-lg-8 col-12" style={{'paddingLeft':'0px'}}>
                                                        <div class="col-12 mb-30">
                                                            <input name="password" placeholder="Current Password" onChange={ this.handleInputChange } value={ this.state.password } type="password"/>
                                                            <div className='warning-msg'>{this.state.passwordError}</div>

                                                        </div>    
                                                        <div class="col-12 mb-30">
                                                            <input name="new_password" placeholder="New Password" onChange={ this.handleInputChange } value={ this.state.new_password } className="" type="password" required=""/>
                                                            <div className='warning-msg'>{this.state.new_passwordError}</div>

                                                        </div>    
                                                        <div class="col-12 mb-30">
                                                         <input name="confirm_password" placeholder="Confirm Password" onChange={ this.handleInputChange } value={ this.state.confirm_password } className="" type="password" required=""/>
                                                            <div className='warning-msg'>{this.state.confirm_passwordError}</div>
                                                        </div> 

                                                        <div class="col-12 mb-30">
                                                            <div className="login-submit ">
                                                                <button class="btn"  disabled={loading}>{loading && <span>Sending...</span>}
                                                                {!loading && <span>Save Changes</span>}</button>                          
                                                             </div> 
                                                        </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>                                 
                                </div>
                            </div>                           
                        </div>          
                    </div>                    
                </div> 
            </div>           
        </div>      
	   <Footer/>
	   </div>			

        )
    }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};


export default connect(
  mapStateToProps,
  null
)(Changepassword);
