import React, {Component} from 'react';
import { Link,NavLink,withRouter } from 'react-router-dom'
import { logout } from '../actions/auth';
import { connect } from 'react-redux';
var userurls = ["order-history","review","wishlist","addressbook"];
class Accountsidebar extends Component {


render(){
     return (
			<div className="col-lg-3 col-12"  >
				<div className="myaccount-tab-menuab nav" role="tablist" id="myinfoaccounttab">
				    <Link exact to="/dashboard" ><img src="/images/icons/dashboard-24.png" alt="Dashboard" title="Dashboard" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> Dashboard</Link>
				    <Link exact to="/account-info" ><img src="/images/icons/my-info-24.png" alt="My Info" title="My Info" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> My Info</Link>
				    <Link exact to="/order-history" ><img src="/images/icons/order-history-24.png" alt="History" title="History" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> Order History</Link>
					<Link exact to="/addressbook" ><img src="/images/icons/address-24.png" alt="Address" title="Address" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> Address Book</Link>
					<Link exact to="/review" ><img src="/images/icons/review-24.png" alt="Reviews" title="Reviews" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> My Reviews</Link>
					<Link exact to="/wishlist" ><img src="/images/icons/wish-list-24.png" alt="Wishlist" title="Wishlist" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> My Wishlist</Link>
					<Link exact to="/change-password" ><img src="/images/icons/password-24.png" alt="Password" title="Password" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> Change Password</Link>
					<Link  onClick={() => this.props.logout()} ><img src="/images/icons/logout-24.png" alt="Logout" title="Logout" style={{'height':'21px','marginTop':'-4px','marginRight':'6px'}} /> Logout</Link>
				</div>
			</div>
		)
	}
}


const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(
  mapStateToProps,
  { logout }
)(Accountsidebar);
