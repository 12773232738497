import React, {Component} from 'react';
import Header from '../components/Header';
import IngredientProduct from '../components/IngredientProduct';
import Footer from '../components/Footer';
import { fetchAllIRelated } from '../actions/ingredientproduct';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";
import {trimString} from '../utils/trimString';

class IngredientsProductContainer extends Component {


	componentDidMount() {
    window.scrollTo(0, 0);
    let slug = this.props.match.params.slug; 
    this.props.dispatch(fetchAllIRelated(slug));


}

render(){
const {ingredientInfo}  = this.props.fetchingredientrelated;
const {productInfo}  = this.props.fetchingredientrelated;






if (!ingredientInfo) return <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;
        return (
        <div id="main-wrapper">
        <Helmet>
<meta charSet="utf-8" />
<title>Ingredient {ingredientInfo.name}</title>
<meta name="description" content={trimString(ingredientInfo.description,120)} />
<meta name="Keywords" content={ingredientInfo.name}/>
</Helmet>

        <Header/>
        <IngredientProduct ingredientInfo = {ingredientInfo} productInfo={productInfo} />
        
        <Footer/>
        </div>



        )
    }





}



const mapStateToProps = state => {
    return {
        fetchingredientrelated: state.fetchingredientrelated
    };
};

export default connect(
  mapStateToProps,
  null
)(IngredientsProductContainer);


