import React, {Component} from 'react';
import Header from '../components/Header';
import Aboutus from '../components/Aboutus';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";



export default class AboutusContainer extends Component {

		componentDidMount() {
    window.scrollTo(0, 0);



}







    render(){
        return (



        <div id="main-wrapper">
        <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | The Organic Forest | Organic Products</title>
        <meta name="description" content="The Organic Forest began as a result of our simple mission of holistic healing, and spreading the concept of a healthy lifestyle to the loved ones." />
        <meta name="Keywords" content="About Us"/>
        </Helmet>

        <Header/>
        <Aboutus />
        <Footer/>
        </div>

     )
    }
}