import { ADDTOADDRESS,ADDTOADDRESS_SUBMIT,BASE_URL,FETCH_ADDRESS,DELETE_ADDRESS,ADDRESS_UPDATE } from '../actions/types';
const initialState = {
  loading: '',
};

export default function addressReducer(state =initialState, action) {
const { type, payload } = action;
  switch (action.type) {
   
    	case ADDTOADDRESS_SUBMIT: 
            return {
                ...state,
                loading: true

            }
    case ADDTOADDRESS:
    return {
        ...state,
     
        loading: false
      };
    case ADDRESS_UPDATE:
      return {
          ...state,
          ...payload,
          loading: false
        };
  case FETCH_ADDRESS:
      
       return {
         ...state,
        loading: false,
        address:payload
      };
 case DELETE_ADDRESS:
 
    return {
         ...state,
        loading: false,
        address:state.address.filter(post => post.id != payload.id)

      };
      
      

    default:
      return state;
  }
}






