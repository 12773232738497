import { BASE_URL,FETCH_PRODUCTREVIEW} from './types';


import axios from 'axios';

const apiUrl = BASE_URL+'torfoallproductreview/'


export const fetchTproductreview = (allreview) => {
  return {
    type: FETCH_PRODUCTREVIEW,   
      allreview
  }
};

export const fetchAllproductreview = (slug) => {
 
  return (dispatch) => {
    return axios.get(apiUrl+slug)
    .then(response => {
      dispatch(fetchTproductreview(response.data))
    })
    .catch(error => {
      throw(error);
    });
  };
};


