import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllCartpro } from '../actions/cart';
import {connect } from 'react-redux'
import Cart from '../components/Cart';
import Footer from '../components/Footer';
import Alert from '../layout/Alert';
import {NavLink} from 'react-router-dom';
import {Currency} from '../utils/currency';
import Cartcoupon from '../components/Cartcoupon';

class CartContainer extends Component {

constructor() {
super();
this.state = {
showReply: false,
}
}

componentDidMount() {
window.scrollTo(0, 0);

this.props.dispatch(fetchAllCartpro());



}





render(){
const {cartproduct}  = this.props;




if (!cartproduct) return <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;

return (
<div id="main-wrapper">

<Header/>
{cartproduct==''?<center><i className="fa fa-shopping-cart fa-5x" style={{'textAlign':'center', 'marginBottom': '80px','marginTop': '20px'}}>
</i>

<h1>SHOPPING CART IS EMPTY</h1>
<br/>
<br/>
<br/>
<h4>You have no items in your shopping cart.</h4>
<br/>
<br/>
<a href="/" class="btn">CONTINUE SHOPPING</a><br/>
<br/>
<br/>
<br/>
</center>:


<div className="cart-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">

<div class="container">
<div class="row">
<div class="col">
<div class="section-title section-title-shipping mb-2 mb-xs-20 text-center feature-item-txt">
<h2 class="leafbg" style={{'fontWeight':'600','color':'#002317'}}>Shopping Cart</h2>
</div>
</div>
</div>
</div>
<div className="container">
<div className="row">
<div className="col-lg-7 col-12">            
<div className="cart-table table-responsive mb-30">
<table className="table">
<thead>
<tr>
<th className="pro-thumbnail">Item</th>
<th className="pro-title">&nbsp;</th>
<th className="pro-price">Price</th>
<th className="pro-quantity">Quantity</th>
<th className="pro-subtotal">Total</th>
<th className="pro-remove">Remove</th>
</tr>
</thead>
<Cart cartproduct={cartproduct}  />
</table>
</div>
<div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0"> 
<Alert  msgdivid="couponalert"/>
</div>
  
</div>
{/*---------------------*/}
<div className="col-lg-5 col-12" style={{'paddingTop':'20px'}}>
<div style={{'background':'#dde5f0'}}>
<div className="cart-summary">
<div className="cart-summary-wrap">
<h4 className="text-center" style={{'paddingTop':'15px','paddingBottom':'10px'}}>Cart Summary</h4>
<p style={{borderBottom:'1px solid #ffffff'}}></p>
<p>Sub Total <span>{Currency}  {parseFloat(cartproduct.reduce((a, c) => (a +c.tof_product.Price * c.quantity), 0)).toFixed(2)}</span></p>
<p>Shipping Cost <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.shipping * 1), 0)).toFixed(2)}</span></p>

{( cartproduct[0].coupon_id!==0 && cartproduct[0].coupon_type==='percentage') &&
<div>
<p>Coupon Applied ({cartproduct[0].coupon_code}) <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0)*cartproduct[0].coupon_value/100).toFixed(2)}</span>

</p>
<h2>Grand Total  <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0) - cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0)*cartproduct[0].coupon_value/100).toFixed(2)}</span></h2>
</div>
}

{( cartproduct[0].coupon_id!==0 && cartproduct[0].coupon_type==='fixed') &&
<div>
<p>Coupon Applied ({cartproduct[0].coupon_code}) <span> {Currency} {parseFloat(cartproduct[0].coupon_value).toFixed(2)}</span></p>
<h2>Grand Total  <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0) - cartproduct[0].coupon_value).toFixed(2)}</span></h2>
</div>
}


{( cartproduct[0].coupon_id===0) &&
<div>
<h2>Grand Total  <span>{Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * c.quantity), 0)).toFixed(2)}</span></h2>
</div>
}
<div style={{'padding':'20px 0px 0px 0px'}}><Cartcoupon cartproduct={cartproduct}/> </div>
</div>

</div>
<div class="cart-summary-button" style={{'textAlign':'center','background':'#ffffff','padding':'0px 0px 0px 0px'}}>
<NavLink to ={'/shop'}  style={{'paddingTop':'25px'}}> <button className="descount-sticker_btn">Continue Shopping</button>
</NavLink>
<NavLink style={{'marginLeft':'20px','paddingTop':'25px'}} to ={'/shipping/'}  > <button className="descount-sticker_btn">Checkout</button>
</NavLink>
</div>
</div>
</div>
{/*---------------------*/}            
</div>
</div>


{/*<div className="cartcoupon-section">
<div className="container">
<div className="row"> 
<div className="col-md-6 offset-md-3 mb-30"></div>                                                       
<div className="col-md-6 offset-md-3 mb-30"></div> 
</div>
</div> 
</div>*/}



</div>
}
<Footer/>
</div>
)
}
}
const mapStateToProps = state => {
return {
cartproduct: state.cartproduct.cartproduct,

};
};


export default connect(
mapStateToProps,
null
)(CartContainer);