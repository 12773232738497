import { PRODUCT_DETAIL,BASE_URL,LOGOUT } from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'productdetail/';

export const fetchIProducts = (productdetail) => {
  return {
    type: PRODUCT_DETAIL,
    productdetail
  }
};

export const fetchAllProducts = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
      .then(response => {
		if(response.data.logstatus =='failed'){			 
		
		}else{
			dispatch(fetchIProducts(response.data))
		}	
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};