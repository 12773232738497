import { PLACE_ORDER} from '../actions/types';
const initialState = {
  loading: '',
};

export default function orderReducer(state =initialState, action) {
const {payload } = action;
  switch (action.type) {
  
    case PLACE_ORDER:
    return {
        ...state,
        ...payload,
        loading: false
      };

      default:
      return state;
  }
}






