import { PRODUCT_DETAIL } from '../actions/types';

export default function productdetailReducer(state = [], action) {
  switch (action.type) {
    case PRODUCT_DETAIL:
      return action.productdetail;
    default:
      return state;
  }
}
