import React, {Component} from 'react';
import { createaddtoAddress } from '../actions/address';
import Alert from '../layout/Alert';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Accountsidebar from '../components/Accountsidebar';


const initialState =  {
        name: '',
        phone:'',
        email: '',
        address: '',
        city: '',
        zip: '',
        state:'' ,
        nameErrors:'',
        phoneErrors:'',
        emailErrors:'',
        addressErrors:'',
        cityErrors:'',
        zipErrors:'',
        stateErrors:'',
        status:1,
      };
 class NewAddress extends Component {
    

  state = initialState;


  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };
   
 validate = () => {
    let nameErrors = "";
    let phoneErrors = "";
    let emailErrors = "";
    let addressErrors = "";
    let cityErrors = "";
    let zipErrors = "";
    let stateErrors = "";


   if (!this.state.name) {
       nameErrors = "Name cannot be blank";
    }
   if (!this.state.phone) {
       phoneErrors = "Phone Number cannot be blank";
    }
   if (!this.state.email) {
       emailErrors = "Email cannot be blank";
    }
   if (!this.state.address) {
       addressErrors = "Address cannot be blank";
    }
   if (!this.state.city) {
       cityErrors = "City cannot be blank";
    }
    if (!this.state.zip) {
       zipErrors = "Pincode cannot be blank";
    }
    if (!this.state.state) {
       stateErrors = "State cannot be blank";
    }
   

   if (nameErrors || phoneErrors || emailErrors || addressErrors || cityErrors || zipErrors || stateErrors) {
      this.setState({nameErrors,phoneErrors,emailErrors,addressErrors,cityErrors,zipErrors,stateErrors });
      return false;
    }
return true;
  };

handleSubmit = e => {
    e.preventDefault();
   const isValid = this.validate();
    if (isValid) {
      this.props.onAddAddress(this.state);
      
      this.setState(initialState);

  }
};
componentDidMount() {
    console.log(this.props);
} 



    render(){


  return (

 <div id="main-wrapper">
        <Header/>
          <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                            <h1>My Account</h1>
                            <ul className="page-breadcrumb">
                                <li><a href="index.html">Home</a></li>
                                <li>Address Edit</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row">  
                            <Accountsidebar/>   
                             <div className="col-lg-9 col-12">
                                <div className="tab-content" id="myaccountContent">
                                  <div className="tab-pane fade show active" id="address-edit" role="tabpanel">
                                    <div className="myaccount-contentab" >
                                      <h3>Address Book</h3>
                                      <div style={{'textAlign':'right'}}>
                                        <a href="/addressbook" ><button className="place-order btn btn-lg btn-round">Add Address</button></a>
                                      </div>
                                  
                                <form onSubmit={ this.handleSubmit }>
                                   <Alert/>
                    <div className="tt-login-form">
                       <div className="row justify-content-left">
                        <div className="col-md-12 col-lg-8">         
                            <div className="form-default">
                                  <div id="resp" className="col-md-6 col-md-offset-3">
                                  </div>
                                  <div style={{'clear':'both','height':'2px'}}></div>
                                    <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>Full Name*</label>
                          <input type="text" placeholder="Full Name" name="name" onChange={ this.handleInputChange } className="form-control" value={ this.state.name }/>
                          <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.nameErrors}</div>

                      </div>
                      <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>Email Address*</label>
                          <input type="text" placeholder="Email Address" name="email" onChange={ this.handleInputChange } className="form-control"  value={ this.state.email }/>
                          <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.emailErrors}</div>

                      </div>

                                    <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>Phone no*</label>
                          <input type="text" placeholder="Phone number" name="phone" onChange={ this.handleInputChange } className="form-control" value={ this.state.phone }/>
                           <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.phoneErrors}</div>

                      </div>

                                    <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>Address*</label>
                          <input type="text" placeholder="Address line 1" name="address" onChange={ this.handleInputChange } className="form-control" value={ this.state.address }/>                                            
                          <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.addressErrors}</div>

                      </div>
                      <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>Town/City*</label>
                          <input type="text" placeholder="Town/City" name="city" onChange={ this.handleInputChange } className="form-control" value={ this.state.city}/>
                           <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.cityErrors}</div>
                      </div>
                      <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>State*</label>
                          <input type="text" placeholder="State" name="state" onChange={ this.handleInputChange }className="form-control"  value={ this.state.state }/>
                          <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.stateErrors}</div>

                      </div>

                        <div className="form-group">
                          <label style={{'fontWeight':'600','fontSize':'15px'}}>Zip Code*</label>
                          <input type="text" name="zip" placeholder="Zip Code"onChange={ this.handleInputChange } className="form-control" value={ this.state.zip }/>
                          <div style={{'fontSize':'14px','color':'#416e7a'}}>{this.state.zipErrors}</div>

                      </div>
                         <div className="form-group">
                      <input name="status"  className="form-control" type="hidden" onChange={ this.handleInputChange } value={ this.state.status }/>                                      
                        </div>  
                      <div class="col-12 mb-30">
                      
                       <button class="place-order btn btn-lg btn-round">Add Address</button>
                      </div>
                    </div>
                  </div>
               </div>
            </div>
          </form>
                                     </div>
                                  </div>
                              </div>
                          </div>


    
                         </div>
                    </div> 
                </div>           
            </div>
        </div>
    <Footer/>
</div>


    )
}
}


const mapDispatchToProps = dispatch => {
  return {
    onAddAddress: post => {

      dispatch(createaddtoAddress(post));
       
    }

  };
};

;

export default connect(
  null,
  mapDispatchToProps
)(NewAddress);




