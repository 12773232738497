import React, { Component } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import {Link,withRouter,Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import { checkCoupon,deleteCoupon } from '../actions/cart';
import {Currency} from '../utils/currency';

const initialState =  {
    coupon:'',
    couponError:'',
  };
  
class Cartcoupon extends Component {
	
	constructor(props) {
		super(props); 
	}	
	state = initialState;	
	handleInputChange = event => {
		const isCheckbox = event.target.type === "checkbox";
		this.setState({
			[event.target.name]: isCheckbox ? event.target.checked: event.target.value
		});
	};  
	
	validatecoupon = () => {
		let couponError = "";
		if (!this.state.coupon) {
			couponError = "Coupon cannot be blank";
		}
		if (couponError) {
			this.setState({couponError});
			return false;
		}
		return true;
	};
  
	handleSubmitCoupon = e => {
		
		e.preventDefault();
		const isValid = this.validatecoupon();
		if (isValid) {
			this.props.Couponcheck(this.state);
			this.setState(initialState);
			//var pagename = this.props.location.pathname;		
			//this.props.history.push({pathname: pagename})
		}
	};
	
	deletetoCoupon(uniqueid) {
		this.props.onDeletetocoupon(uniqueid);
		//alert(this.props.location.pathname);
		// var pagename = this.props.location.pathname;
		// alert(pagename);			
		// this.props.history.push({pathname: pagename});
    }

    render(){
    const {cartproduct} = this.props;
    const uniqueid = this.props.cartproduct[0].uniqueid;
        return (
			<div className="col-12 mx-auto text-center" style={{'padding':'35px 35px 0px 35px'}}> 
                <div className="discount-coupon">
					<h4 style={{'marginBottom':'20px'}}>Discount Coupon Code</h4>
					<form action="/#" onSubmit={ this.handleSubmitCoupon }>
						<div className="row">
<div className="col-md-6 col-12" style={{'height':'44px','marginBottom':'25px'}}>
								<input style={{'background':'#ffffff'}} type="text" onChange={ this.handleInputChange } value={ this.state.coupon }  name="coupon" placeholder="Coupon Code"/>
								<div style={{'fontSize':'14px','color':'red'}}>{this.state.couponError}</div>
							</div>
							<div className="col-md-6 col-12" style={{'height':'44px'}}>
								<button className="descount-sticker_btn" style={{'marginTop':'0px'}}>Apply Code</button>
							</div>
						</div>
					</form>
				</div>
				<div className="discount-coupon">
					{cartproduct[0].coupon_id ==0 ? '':cartproduct[0].coupon_type==='percentage' ?<h4 style={{'marginBottom':'10px'}}>								
					Coupon Applied ({cartproduct[0].coupon_code})<span> {Currency} {parseFloat(cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * 1), 0)*cartproduct[0].coupon_value/100).toFixed(2)}</span></h4>
					:
					<h4 style={{'marginBottom':'10px'}}>Coupon Applied ({cartproduct[0].coupon_code})<span>{Currency} {parseFloat(cartproduct[0].coupon_value).toFixed(2)}</span></h4>
					}
				</div>	
				{cartproduct[0].coupon_id==0?'':	
				  <span onClick={() => this.deletetoCoupon(uniqueid)} style={{'cursor': 'pointer'}}> <i class="fa fa-trash-o" > &nbsp; <strong>Remove Coupon</strong></i></span>}
        </div>	   
		)
    }
}

const mapDispatchToProps = dispatch => {
  return {
    Couponcheck: post => {
     dispatch(checkCoupon(post));
    },
     onDeletetocoupon: uniqueid => {
     dispatch(deleteCoupon(uniqueid));
    },
  };
};

const mapStateToProps = state => {
  return {
    createcoupon: state.createcoupon,  
    coupondelete:state.coupondelete

  };
};


export default withRouter(connect(
mapStateToProps,
mapDispatchToProps
)(Cartcoupon));







                
                                                  
                               
