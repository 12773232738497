import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Category from '../components/Category';
import { connect } from 'react-redux';
import { fetchAllCategoryProduct } from '../actions/categoryproducts';

class CategoryContainer extends Component {

componentDidMount() {
window.scrollTo(0, 0);
let slug = window.location.href.split("/").pop(); 
this.props.dispatch(fetchAllCategoryProduct(slug));



}

componentDidUpdate(prevProps) {

let slug = window.location.href.split("/").pop(); 

if (slug!== prevProps.match.params.mslug) {
this.props.dispatch(fetchAllCategoryProduct(slug));
}

}





render(){


const {categorydetail,product}  = this.props;

if (!categorydetail) return <div id="main-wrapper">
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row" style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '110px'}}><img src="/images/loader.gif" alt="loading" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>

Loading in Progress...
</p>
</div>
</div>
</div>

</div>;


return (

<div id="main-wrapper">
<Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">


<div class="container">

<div class="row">
<div class="col-lg-12 order-lg-2 order-1">

<div class="row">
<div class="col-12">
<div class="shop-banner-title" style={{'textAlign':'center'}}>
<h2 class="leafbg" style={{'fontWeight':'600','color':'#db9c28'}}>{categorydetail.name} Products</h2>
</div>
</div>
</div>
<div class="row">
<div class="col-12">
<div id="list" class="tab-pane fade active show">

  
  {product.map((post, index) => {
       
      
        return (
    
       <Category post={ post } index={index} key={ post.id } />
    
          );
    
       
      
      })}




</div>



</div>
</div>

</div>
</div>
</div>      

</div>

<Footer/>
</div>



)
}
}



const mapStateToProps = state => {
return {
categorydetail: state.categorypro.Category,
product: state.categorypro.productInfo
};
};






export default connect(
mapStateToProps,
null
)(CategoryContainer);