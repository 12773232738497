import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllOHistory } from '../actions/orderdetail';
import { connect } from 'react-redux';
import Orderdetail from '../components/Orderdetail';
import Accountsidebar from '../components/Accountsidebar';
import Footer from '../components/Footer';

 class OrderdetailContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const ordernumber = this.props.match.params.ordernumber;
    this.props.dispatch(fetchAllOHistory(ordernumber));
}
  render(){
    const {orderdetail}  = this.props; 



    if (orderdetail[0]=='f') {
      return<div id="main-wrapper">
        <Header/>
          <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                        <div class="row">  
                          <Accountsidebar/>   
                          <div class="col-lg-9 col-12 ordditls">
                           <div className="myaccount-table text-center detailsmob" style={{'borderLeft':'1px solid #eeeeee'}}>
                         <br/><br/><h2>Oops Something went wrong.<br/>Please try again later.</h2><br/>
                              </div>
                            </div>                           
                        </div>    
                    </div>                    
                </div> 
            </div>           
        </div>
      <Footer/>
    </div>
    }


      return (
        <div id="main-wrapper">
        <Header/>
        
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
      <div class="container">
        <div class="row">
                    <div class="col-12">
                        <div class="row">  
                          <Accountsidebar/>   
                          <div class="col-lg-9 col-12 ordditls">
                           <div className=" myaccount-table text-center detailsmob" style={{'borderLeft':'1px solid #eeeeee'}}>

                            <Orderdetail orderdetail={orderdetail}/>
                 
                         </div>
                            </div>                           
                        </div>    
                    </div>                    
                </div> 
            </div>           
        </div>
    

          <Footer/>
        </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    orderdetail: state.orderdetail
    
  };
};


export default connect(
  mapStateToProps,
  null
)(OrderdetailContainer);