import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import parse from 'html-react-parser';
import {trimString} from '../utils/trimString';
import {dateFormat} from '../utils/dateFormat';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


export default ({ post: { id,image,slug,title,description,date } }) => {

return (
   <div className="blog col-lg-4 col-md-6 col-sm-6">
                        <div className="blog-inner">
                            <div className="media">

<Link to ={'/blog-detail/' +slug} className="image1">
<LazyLoadImage src={IMG_BASE_URL+'blog/'+image} effect="blur" alt=""/></Link>
     </div>

                            <div className="content">
                             <ul className="meta">
                                    <li><i className="fa fa-calendar"></i>

                                    <span className="date-time" style={{'marginLeft':'10px'}}>
                                    {dateFormat(date)}


                                    </span>

                                    </li>
                                </ul>
                                <h3 className="title"><Link to ={'/blog-detail/' +slug}>{title}</Link></h3>
                                
                                <p>{parse(trimString(description,120))} </p>

                            <Link to ={'/blog-detail/' +slug} className="readmore">Read more</Link>
                            </div>
                        </div>
                    </div>
    






)
    }



