import { ADD_CONTACTUS,CONTACT_SUBMIT,BASE_URL,LOGOUT} from './types';
import { setAlert } from './alert';
import axios from 'axios';
const addapiUrl = BASE_URL+'contactus';

export const createContact = ({name,lastname,email,subject,message,captchaToken}) => {
	return (dispatch) => {
		dispatch(contactSubmit());
		return axios.post(addapiUrl, {name,lastname,email,subject,message,captchaToken})
		.then(response => {			
			if(response.data.logstatus === 'failed'){			 
			   dispatch(setAlert('Something went wrong, Please try again!', 'danger',));
			}else{
				dispatch(createContactSuccess(response.data));
				dispatch(setAlert('Thank you for contacting us. We will get back to you very soon!', 'success'));
			}			
		})
		.catch(error => {
				if(error.response.status === 401){
					dispatch({ type: LOGOUT });
				}
				throw(error);
		});
	};
};

export const createContactSuccess =  (data) => {
  return {
    type: ADD_CONTACTUS,
    payload: {
     name: data.name,
     lastname: data.lastname,
     email: data.email,
     subject: data.subject,
     message: data.message,
	 captchaToken: data.captchaToken,
   }
  }
};

function contactSubmit() {
    return {
        type: CONTACT_SUBMIT
    }
}