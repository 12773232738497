import { FETCH_ORDERSTATUS,STATUS_CHANGE } from '../actions/types';

export default function orderstatusReducer(state = [], action) {


  switch (action.type) {
    case FETCH_ORDERSTATUS:
      return action.orderstatus;


    case STATUS_CHANGE:
      return [...state];

    default:
      return state;
  }
}
