import { connect } from 'react-redux';
import { createContact } from '../actions/createContactus';
import Contactus from '../components/Contactus';



const mapDispatchToProps = dispatch => {
  return {
    onAddContact: post => {
     dispatch(createContact(post));
       
    }

  };
};

const mapStateToProps = state => {
  return {
    createcontact: state.createcontact
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contactus);




