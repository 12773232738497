import { RESET,RESET_SUBMIT,BASE_URL,LOGOUT} from './types';
import { setAlert } from './alert';
import axios from 'axios';

const encodedata = window.location.href.split("/").pop(); 

const addapiUrl = BASE_URL+'resetpassword/'+encodedata;

export const reset = ({new_password}) => {
  return (dispatch) => {
    dispatch(resetSubmit());
    return axios.post(addapiUrl, {new_password})
      .then(response => {
		  if(response.data.logstatus ==='failed'){
			   dispatch(setAlert('Password has not been updated successfully', 'danger'));
		  }else{
			    dispatch(resetSuccess(response.data));
				dispatch(setAlert('Password has been reset successfully.', 'success','successreset'));
		  }
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

export const resetSuccess =  (data) => {
  return {
    type: RESET,
    payload: { 
		new_password: data.new_password,
   }
  }
};



function resetSubmit() {
    return {
        type: RESET_SUBMIT
    }
}


