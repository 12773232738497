import { RESET,RESET_SUBMIT } from '../actions/types';

const initialState = {
  loading: '',
};

export default function resetReducer(state =initialState, action) {
 const { payload } = action;

  switch (action.type) {
  	case RESET_SUBMIT: 
            return {
                ...state,
                loading: true
            }
    case RESET:
    return {
        ...state,
        ...payload,
        loading: false
      };

 default:
      return state;
  }
}
