import { FETCH_TESTIMONIAL,BASE_URL,LOGOUT } from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'testimonial';

export const fetchTPosts = (fetchtestimonial) => {
  return {
    type: FETCH_TESTIMONIAL,
    fetchtestimonial
  }
};

export const fetchAllTPosts = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
      .then(response => {
	    if(response.data.logstatus =='failed'){
		 
		}else{
			dispatch(fetchTPosts(response.data))
		}
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};