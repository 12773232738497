import { SIGNUP,SIGNUP_SUBMIT,SIGNUP_FAILED,AUTH_ERROR,
  LOGIN,
  LOGOUT,LOGIN_SUBMIT,
LOGIN_FAIL,AUTH_CHECK,SET_CURRENT_USER } from '../actions/types';

const initialState = {
  loading: '',
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  user: {}
};

export default function signupReducer(state =initialState, action) {
 const {payload } = action;

  switch (action.type) {
  	case SIGNUP_SUBMIT: 
            return {
                ...state,
                loading: true
            }
    case LOGIN_SUBMIT: 
            return {
                ...state,
                loading: true
            }
    case SIGNUP_FAILED: 
            return {
                ...state,
                loading: false
            }
    case SIGNUP:
    return {
        ...state,
        ...payload,
        loading: false
      }
    case LOGIN:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };

    case SET_CURRENT_USER:
      return {
         ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };



    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      }; 



 default:
      return state;
  }
}
