import { FETCH_PRODUCT_BEGIN ,FETCH_PRODUCT_SUCCESS,FETCH_PRODUCT_FAILURE,BASE_URL,LOGOUT} from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'allproducts';



export const fetchAllProductsBegin = () => ({
  type: FETCH_PRODUCT_BEGIN
});

export const fetchAllProductsSuccess = (fetchproduct) => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: { fetchproduct } 
  }
};


export const fetchAllProductsFailure = error => ({
  type: FETCH_PRODUCT_FAILURE,
  payload: { error }
})



export const fetchAllProducts = () => {
  return (dispatch) => {
    dispatch(fetchAllProductsBegin());
    return axios.get(apiUrl)
    .then(response => {
      if(response.data.logstatus ==='failed'){
      }else{
         dispatch(fetchAllProductsSuccess(response.data));
      } 
    })
      .catch(error => {
        if(error.response.status === 401){
          dispatch(fetchAllProductsFailure(error));
      dispatch({ type: LOGOUT });
    }
        throw(error);
      });
  };
};
