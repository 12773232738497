import React, {Component} from 'react';
import Header from '../components/Header';
import Checkout from '../components/Checkout';
import Footer from '../components/Footer';

export default class CheckoutContainer extends Component {
    render(){
        return (

        <div id="main-wrapper">
        <Header/>
        <Checkout />
        <Footer/>
        </div>

     )
    }
}