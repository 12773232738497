import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTPosts } from '../actions/review';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import Review from '../components/Review';
import Accountsidebar from '../components/Accountsidebar';
import Footer from '../components/Footer';

class ReviewContainer extends Component {

	

    componentDidMount() {
        const id=this.props.auth.user.id
        this.props.dispatch(fetchAllTPosts());
    }

    render(){
        const {review}  = this.props;
	
        return (
			<div id="main-wrapper">
				<Header/>
				 <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                
                                <div className="page-banner text-center">
                                    <h1>My Account</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Reviews</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
					<div>            
					<div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
						<div class="container">
							<div class="row">						
						<div class="col-12">
							<div class="row">							   
							   <Accountsidebar/>					   
						   
                            <div class="col-lg-9 col-12">
                                <div class="tab-content" id="myaccountContent">                                   
                                    <div class="tab-pane fade show active" id="orders" role="tabpanel">
									 {review==''?<center>
                                        <br/>
                                        <br/>
                                        <h4>No Product Review Found for this Account</h4>
                                        <br/>
                                        <br/>

                                        <Link to ='/shop' >
                                            <a href="/shop" class="btn">CONTINUE SHOPPING</a><br/>
                                        </Link>
                                       <br/>
                                       <br/>
                                    </center>:
                                        <div class="myaccount-contentab">
                                            <h3 >My Reviews</h3> 
												{review.map(post => {
														return (                                                                
															<Review post={ post }  key={ post.id } />
															
														);
													})} 

                                        </div>
									 }
                                    </div>
                                </div>
                            </div>                           
                        </div>    
                    </div>                    
                </div> 
            </div>           
        </div>
        
        
        
             
            </div>
				<Footer/>
			</div>
        )
    }
}

const mapStateToProps = state => {
  return {
    review: state.review,
    auth: state.auth
  };
};


export default connect(
  mapStateToProps,
  null
)(ReviewContainer);





