import { FETCH_BLOG,BASE_URL,LOGOUT } from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'allblog';

export const fetchbloghome = (fetchbloghome) => {
	return {
		type: FETCH_BLOG,
		fetchbloghome
	}
};

export const fetchbloghomepost = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
      .then(response => {
			if(response.data.logstatus =='failed'){			  
			}else{
				  dispatch(fetchbloghome(response.data))
			}			
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};