import React, {Component} from 'react';
import Alert from '../layout/Alert';
import Header from '../components/Header';
import Footer from '../components/Footer';

	const initialState =  {
		email:'',
		emailError:'',	
	};
  
export default class Forgotpassword extends Component {
	
	constructor(props) {
        super(props); 
		this.handleInputChange = this.handleInputChange.bind(this);
    }
	state = initialState;
	
	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
		  [name]: value
		});
	}
	
	
	validate = () => {			
		let emailError = "";	
		if (!this.state.email) {
			emailError = "Email cannot be blank";
		}
		if (emailError) {
			this.setState({emailError});
			return false;
		}
		return true;
	};
  
	handleSubmit = e => {
		e.preventDefault();
		const isValid = this.validate();
		if (isValid) {	
			this.props.onforgot(this.state);
			this.setState(initialState);
		}
	};

	
    render(){
		const { loading } = this.props.forgot;
    return (
	<div>
		<Header/>
			<div className="login-register-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
			   <div className="container">
				  <div className="row">
					 <div className="col-md-12 col-sm-12">
						<h1 className="tt-title-subpages noborder" style={{'textAlign':'center'}}>FORGOT PASSWORD</h1>
					 </div>
				
					 <div className="col-md-6 col-sm-6" style={{'margin':'0px auto'}}>
						<div className="customer-login-register">
						   <div className="form-login-title" style={{'display':'none'}}>
							  <h2>Login</h2>
						   </div>
						   <div className="login-form">
						    <Alert />	
								<form  method="post" onSubmit={ this.handleSubmit }>
									<div className="form-group">
										<label for="loginInputEmail">E-MAIL *</label>
										<input name="email" placeholder="Email" onChange={ this.handleInputChange } value={ this.state.email } className="form-control" type="text" required=""/>
									<div style={{ fontSize:14,color: "red"}}>{this.state.emailError}</div></div>								 
									 <div className="login-submit" style={{'textAlign':'center'}}>
										 <button class="btn"  disabled={loading}>{loading && <span>Sending...</span>}
											{!loading && <span>Submit</span>}</button>							
									 </div>
								</form>
						   </div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
			<Footer/>
	</div>
	   )
    }
}