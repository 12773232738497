import React, {Component} from 'react';

class ProductShare extends Component {
    render(){
		const productUrl = window.location.host + '/product/'+ this.props.producturl;		
		//console.log(productUrl);
		
        return (
			<div className="product-meta single-product-sharing prodshare">
				<h3>Share With Your Friend</h3>
				<ul>
					<li><a href={'https://www.facebook.com/sharer/sharer.php?u='+productUrl} target={"_blank"}><i className="fa fa-facebook"></i></a></li>  
					<li><a href={'https://twitter.com/intent/tweet?text='+productUrl} target={"_blank"}><i className="fa fa-twitter"></i></a></li>
					<li><a href={'https://www.instagram.com/?url='+productUrl} target={"_blank"}><i className="fa fa-instagram"></i></a></li>
				</ul>
			</div>

        )
    }
}

export default ProductShare;