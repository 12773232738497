import { connect } from 'react-redux';
import { reset } from '../actions/reset';
import Resetpassword from '../components/Resetpassword';

const mapDispatchToProps = dispatch => {
  return {
    onreset: post => {
		dispatch(reset(post));  
    }
  };
};
const mapStateToProps = state => {
  return {
    reset: state.reset
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resetpassword);




