import React, {Component} from 'react';
import { connect } from 'react-redux';
import { change } from '../actions/changepassword';
import Changepassword from '../components/Changepassword';

const mapDispatchToProps = (dispatch, ownProps) => {
 



  return {
    onchange: (post) => {
      dispatch(change(post));  
    }
  };
};
const mapStateToProps = state => {
  return {
    changepass: state.changepass,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Changepassword);
