import { ADDTOCART,ADDTOCART_SUBMIT,BASE_URL,FETCH_CARTPRODUCT,DELETE_CART } from '../actions/types';
const initialState = {
  loading: '',
};

export default function cartproductReducer(state =initialState, action) {
const { type, payload } = action;
  switch (action.type) {
   
    	case ADDTOCART_SUBMIT: 
            return {
                ...state,
                loading: true

            }
    case ADDTOCART:
    return {
        ...state,
        ...payload,
        loading: false
      };

  case FETCH_CARTPRODUCT:
      
       return {
         ...state,
        loading: false,
        cartproduct:payload
      };
 case DELETE_CART:
   console.log(payload.id);
    return {
         ...state,
        loading: false,
        cartproduct:state.cartproduct.filter(post => post.id != payload.id)
      };
      
      

    default:
      return state;
  }
}






