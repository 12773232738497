import { FETCH_BLOGDETAIL } from '../actions/types';

export default function blogdetailReducer(state = [], action) {
  switch (action.type) {
    case FETCH_BLOGDETAIL:
      return action.fetchblogdetail;
    default:
      return state;
  }
}
