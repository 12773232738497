import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllOrder } from '../actions/orderstatus';
import { connect } from 'react-redux';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import Orderreturn from '../components/Orderreturn';
import Ordercancel from '../components/Ordercancel';
import Accountsidebar from '../components/Accountsidebar';
import { IMG_BASE_URL } from '../actions/types';
import {Currency} from '../utils/currency';

 class OrderreturnContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  const id =this.props.match.params.id;
  this.props.dispatch(fetchAllOrder(id));
}


componentDidUpdate(prevProps) {


let id =this.props.match.params.id;


if (id!== prevProps.match.params.id) {
this.props.dispatch(fetchAllOrder(id));
}

}

 
  render(){
    const {orderstatus}  = this.props;
    if (orderstatus==='') {
return  <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" alt="loading" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;
    }

   if(orderstatus==='failed') {
      return  <div id="main-wrapper"><Header/>
      <div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
      <div className="container">
      <center><i className="fa fa-history fa-5x" style={{'textAlign':'center', 'marginBottom': '80px','marginTop': '20px'}}>
                </i>
                <br/>
                  <h3>You are not authorised user for this order</h3>
                <br/>
                <Link exact to="/shop" >CONTINUE SHOPPING</Link>
                  
                <br/>
              </center>
            </div>
          </div>
       <Footer/>
    </div>;
    }
      return (
        <div id="main-wrapper">
        <Header/>
         <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                            <h1>My Order</h1>
                            <ul className="page-breadcrumb">
                                <li><Link exact to="/" >Home</Link>


  

                                </li>
                                {orderstatus.map(order => (   <div>
  {order.enumstatus=== 'Order Confirmed' || order.enumstatus==='Cancelled'||order.enumstatus==='Processing' ?<li>Order Cancel</li>
              : order.enumstatus=== 'Shipped'||order.enumstatus=== 'Returned' || order.enumstatus=== 'Complete' ?
                   <li>Order Return</li>
              :''} </div>
))}
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
       
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
              <div class="row">
                <div class="col-12">
                     <div class="row">
                                     <Accountsidebar/>
                            
                                     <div class="col-lg-9 col-12">
                                            {orderstatus.map(item => (
                            <div class="col-12">
                                <div className="col-md-3 col-sm-6 col-12 orconfprod1 cancel-order">
                                  <a href="/#" target="_blank"> 
                                   <img src={IMG_BASE_URL+'products/'+item.feature_image} alt=""/>
                                  </a>
                                </div>
                                <div className="col-md-5 col-sm-6 col-12 orconfprod2" style={{'line-height':'18px'}}>
                                      <p></p>
                                      <h4>
                                      <a href="/#" target="_blank"> 
                                      {item.tof_product.name}
                                      </a></h4>
                                      <p></p>
                                      <p><span><strong>Qty : </strong> {item.quantities}</span></p>
                                      <p><span><strong>Price : </strong> {Currency} {parseFloat(item.product_price).toFixed(2)}
                                    </span></p>
                                      {item.sizes!=null?<p><span><strong>Size:</strong> {item.sizes}</span></p>:''}
                                      <p><span><strong>Status : </strong>{item.enumstatus}</span></p>
                                 </div>
                                 
                            </div>
                        ))} 
                        {orderstatus.map(item => (
<div>
              {item.enumstatus=== 'Order Confirmed'|| item.enumstatus=== 'Cancelled' ||item.enumstatus==='Processing' ?<Ordercancel orderstatus={orderstatus} id={this.props.match.params.id} />
              : item.enumstatus=== 'Shipped'||item.enumstatus=== 'Complete' || item.enumstatus=== 'Returned' ?
                   <Orderreturn orderstatus={orderstatus} id={this.props.match.params.id} />
              :''}</div>
                      ))}

                                   </div> 
                                        
                                    </div>
                      </div> 
                    </div>           
        </div>
    </div>

          <Footer/>
        </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    orderstatus: state.orderstatus
    
  };
};


export default connect(
  mapStateToProps,
)(OrderreturnContainer);