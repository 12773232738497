import { SHOW_CART_POPUP, REMOVE_CART_POPUP } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_CART_POPUP:
      return [...state, payload];
    case REMOVE_CART_POPUP:
      return state.filter(alert => alert.id !== payload);
    default:
      return state;
  }
}
