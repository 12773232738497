import { REVIEW,BASE_URL,LOGOUT } from './types';
import axios from 'axios';
import { setAlert } from './alert';
const apiUrl = BASE_URL+'getallreview/';


export const fetchTPosts = (review) => {
  return {
    type: REVIEW,
    review
  }
};

export const fetchAllTPosts = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
      .then(response => {
			if(response.data.logstatus ==='failed'){			  
			   dispatch(setAlert('No Review Found.', 'danger'));
			}else{
			  dispatch(fetchTPosts(response.data));		
			}
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};