import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { fetchAllProducts } from '../actions/productdetail';
import { fetchRelatedpro } from '../actions/relatedproducts';
import Products from '../components/Products';
import { connect } from 'react-redux';
import ProductDetail from '../components/Product';
import Productingredients from '../components/Productingredients';
import Reviewsubmit from '../containers/ReviewSubmitContainer';
import { IMG_BASE_URL } from '../actions/types';
import { fetchAllproreview } from '../actions/reviewsubmit';
import { fetchAllTPosts } from '../actions/wishlist';
import parse from 'html-react-parser';


class ProductContainer extends Component {

	componentDidMount() {
        window.scrollTo(0, 0);
        let slug = this.props.match.params.slug; 
        this.props.dispatch(fetchAllProducts(slug));
        this.props.dispatch(fetchRelatedpro(slug));
        this.props.dispatch(fetchAllproreview(slug));
        this.props.dispatch(fetchAllTPosts());
    }


    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        let slug = this.props.match.params.slug;  
        if (slug!== prevProps.match.params.slug) {
            this.props.dispatch(fetchAllProducts(slug));
            this.props.dispatch(fetchRelatedpro(slug));
            this.props.dispatch(fetchAllproreview(slug));
        }
    }



render(){

const {ingredient}  = this.props.productdetail;
const {product}  = this.props.productdetail;
const {relatedproducts}  = this.props;
const {reviewsubmit}  = this.props;
const {wishlist}  = this.props;







if (!product) return <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;
return (

	<div id="main-wrapper">
    <Header/>
	<ProductDetail product = {product} ingredient={ingredient} reviewsubmit={reviewsubmit} wishlist={wishlist}
/>
    <br/>
    <br/>

<Productingredients product = {product[0]} />
<div style={{'clear' : 'both', 'height' : '5px'}}></div>
<div className="product-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-65 pb-lg-45 pb-md-35 pb-sm-25 pb-xs-15 ingredient-sec">
<div className="container">

<div className="row">
 <div className="col">
  <div className="section-title detail-ingred pt-lg-40">
 <h2 style={{'fontWeight':'600','color':'#193a43','fontSize':'30px'}}>Product Details Description</h2>
</div>
 </div>
</div>

<div className="row">
<div className="col-lg-6 col-md-6 col-sm-6 col-12">    
<img src={IMG_BASE_URL+'products/'+product[0].open_label_image} alt="Product Images"/>
</div>
<div className="col-lg-6 col-md-6 col-sm-6 col-12" style={{'fontSize':'17px','color':'#333333','paddingTop':'25px'}}>    
{parse(product[0].description )}

</div>

</div>







</div>

<div className="underline-product">
<div className="container">


<div className="row">
<div className="col">
<div className="section-title reviewmb">
<h2 style={{'fontWeight':'600','color':'#002619','fontSize':'30px','paddingBottom':'30px'}}>Ratings & Reviews</h2>
</div>
</div>
</div>

<div className="row">

<div className="col-md-12">

<Reviewsubmit slug={this.props.match.params.slug}/>
</div>

</div>

<div className="row">
<div className="col">
<div className="section-title">
<h2 style={{'fontWeight':'600','color':'#002619','fontSize':'30px','paddingBottom':'30px'}}>You May Also Like</h2>
</div>
</div>
</div>
         
<div className="row tf-element-carousel" >

{relatedproducts.map(post => {
return (
<Products post={post}  key={ post.id }/> 
);
})} 
</div>
</div>
</div>
</div>

<div class="container">
<div className="row mt-30">
<div style={{'background':'#cae4de', 'padding':'15px 0px 0px 20px','width':'100%'}}>
<div className="col-lg-3 col-md-6 col-6" style={{'display':'inline-block'}}>
<div className="single-product" style={{'height':'auto', 'background':'transparent','boxShadow':'none','border':'0px'}}>
<div className="product-img shop-product-list" style={{'background':'transparent'}}>
<a className="prodimgsrc prodimgpaymentsrc" href="/product/red-onion-and-amla-oil">
<img class="" src="/images/freeshipping1.png" alt="Free Shipping" title="Free Shipping" /></a>
</div>
<div className="product-content">
<h3 style={{'borderTop':'none','height':'30px','padding':'0px 6px 0px 6px'}}><span style={{'color':'#000000'}}>Flexible Shipping</span></h3>
<p style={{'marginTop':'0px'}}>Free Shipping for order over ₹1000</p>
</div>
</div>
</div>
<div className="col-lg-3 col-md-6 col-6" style={{'display':'inline-block'}}>
<div className="single-product" style={{'height':'auto', 'background':'transparent','boxShadow':'none','border':'0px'}}>
<div className="product-img shop-product-list" style={{'background':'transparent'}}>
<a className="prodimgsrc prodimgpaymentsrc" href="#">
<img src="/images/return.png" alt="return" title="return" /></a>
</div>
<div className="product-content">
<h3 style={{'borderTop':'none','height':'30px','padding':'0px 6px 0px 6px'}}><span style={{'color':'#000000'}}>Return</span></h3>
<p style={{'marginTop':'0px'}}>Within 30 day for an exchange</p>
</div>
</div>
</div>
<div className="col-lg-3 col-md-6 col-6" style={{'display':'inline-block'}}>
<div className="single-product" style={{'height':'auto', 'background':'transparent','boxShadow':'none','border':'0px'}}>
<div className="product-img shop-product-list" style={{'background':'transparent'}}>
<a className="prodimgsrc prodimgpaymentsrc" href="#">
<img src="/images/onlinesupport.png" alt="Online Support" title="Online Support" /></a>
</div>
<div className="product-content">
<h3 style={{'borderTop':'none','height':'30px','padding':'0px 6px 0px 6px'}}><span style={{'color':'#000000'}}>Online Support</span></h3>
<p style={{'marginTop':'0px'}}>24 hours a day 7 days a week</p>
</div>
</div>
</div>
<div className="col-lg-3 col-md-6 col-6" style={{'display':'inline-block'}}>
<div className="single-product" style={{'height':'auto', 'background':'transparent','boxShadow':'none','border':'0px'}}>
<div className="product-img shop-product-list" style={{'background':'transparent'}}>
<a className="prodimgsrc prodimgpaymentsrc" href="#">
<img src="/images/flexiblepayment.png" alt="Flexible Payment"  title="Flexible Payment" /></a>
</div>
<div className="product-content">
<h3 style={{'borderTop':'none','height':'30px','padding':'0px 6px 0px 6px'}}><span style={{'color':'#000000'}}>Flexible Payment</span></h3>
<p style={{'marginTop':'0px'}}>Pay with multiple Credit Cards</p>
</div>
</div>
</div>
</div>
</div>
</div>

    
<Footer/>
</div>



        )
    }
}

const mapStateToProps = state => {
    return {
        productdetail: state.productdetail,
        relatedproducts:state.relatedproducts,
        reviewsubmit:state.reviewsubmit,
        wishlist: state.wishlist.wishlist,
        auth: state.auth
    };
};

export default connect(
  mapStateToProps,
  null
)(ProductContainer);





