import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import {trimString} from '../utils/trimString';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


export default ({ post: {name,description, id,image,slug } }) => {

  

return (

<div>





<Link to ={'/ingredient-product/' +slug}>

<div className="product-img shop-product-list ingredient">
 <LazyLoadImage src={IMG_BASE_URL+'ingredients/'+image} effect="blur" alt="Ingredients" title="Ingredients" /> 


<span className="descount-sticker" style={{'display':'none'}}>-10%</span>
<span className="sticker" style={{'display':'none'}}>New</span>
</div>
<div className="product-content">
<h3 style={{'margin':'0 0 5px 0','fontSize':'16px','color':'#193a43','borderTop':'none'}}>{name}</h3>
<div className="rattingbtn">
<p style={{'display':'none'}}>{trimString(description,90)}</p> 
</div>
<p className="price">

<Link to ={'/ingredient-product/' +slug} className="descount-sticker_btn">Related Products</Link>

</p>
</div>
</Link>
</div>





)
    }














