import { FORGOT,FORGOT_SUBMIT,FORGOT_FAILED } from '../actions/types';

const initialState = {
  loading: '',
};

export default function forgotReducer(state =initialState, action) {
 const { payload } = action;

  switch (action.type) {
  	case FORGOT_SUBMIT: 
            return {
                ...state,
                loading: true
            }

 case FORGOT_FAILED:
    return {
        ...state,
        ...payload,
        loading: false
      };

            
    case FORGOT:
    return {
        ...state,
        ...payload,
        loading: false
      };

 default:
      return state;
  }
}
