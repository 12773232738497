import { connect } from 'react-redux';
import { createSubscribe } from '../actions/subscribe';
import subscribe from '../components/Subscribe';


const mapDispatchToProps = dispatch => {
  return {
    onAddSubscribe: post => {
     
      dispatch(createSubscribe(post));
       
    }

  };
};

const mapStateToProps = state => {
  return {
    subscribe: state.subscribe
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(subscribe);




