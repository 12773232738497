import { FORGOT,FORGOT_SUBMIT,BASE_URL,FORGOT_FAILED,LOGOUT} from './types';
import { setAlert } from './alert';
import axios from 'axios';

const addapiUrl = BASE_URL+'sendresetpasswordlink';


export const forgot = ({email}) => {
  return (dispatch) => {
    dispatch(signupSubmit());
    return axios.post(addapiUrl, {email})
      .then(response => {
      dispatch(forgotFailed());
      if(response.data.logstatus =='failed'){
			   dispatch(setAlert('Sorry but we could not find a customer with that email address.', 'danger'));
		  }else{
			    dispatch(forgotSuccess(response.data));
				dispatch(setAlert('Thank you, an email has been sent to you.', 'success'));
		  }
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

export const forgotSuccess =  (data) => {
  return {
    type: FORGOT,
    payload: { 
		email: data.email,
   }
  }
};



function signupSubmit() {
    return {
        type: FORGOT_SUBMIT
    }
}

function forgotFailed() {
    return {
        type: FORGOT_FAILED
    }
}

