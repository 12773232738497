import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Ourstory from '../components/Ourstory';


export default class OurstoryContainer extends Component {


	componentDidMount() {
window.scrollTo(0, 0);




}



    render(){
        return (

        <div id="main-wrapper">
        <Header/>
        <Ourstory/>
        <Footer/>
        </div>



        )
    }
}