import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTSearch } from '../actions/search';
import { connect } from 'react-redux';
import Product from '../components/Products';
import Footer from '../components/Footer';
import RelatedPro from '../containers/RelatedProductContainer';

class SearchContainer extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
		const searchkey = this.props.match.params.key; 
        this.props.dispatch(fetchAllTSearch(searchkey));
	}

    render(){
        const {loading,search}  = this.props;
       
    

if (loading) {
      return  <div id="main-wrapper">
<Header/><div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" alt="loading" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
    
    Loading in Progress...
</p>
</div>
</div>
</div>
<Footer/>
</div>;
    }

    return (
     <div id="main-wrapper">
<Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">

                <div className="row">
                  
                    <div className="col">
                        <div className="section-title mb-20 mb-xs-20 text-center">
                            <h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28'}}>Search Products</h2>
                        </div>
                    </div>
                   
                </div>
            {search === '' ? <div ><h1 className="searchicon" style={{'textAlign':'center', 'marginBottom': '80px','marginTop': '10px'}}>No Results for Your Search</h1>
           

            <div class="row">
            <div class="col-12">
            <div class="shop-banner-title">
            <h2 class="leafbg" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>We Think You'll <i class="fa fa-heart-o font30"></i></h2>
            </div></div>
            </div>
            <RelatedPro/>
            </div> :                    

                <div className="row">
                    <div className="col-12">                        
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="products">
                                <div className="row" >
                                  {search.map(post => {
                                        return (
                                             <Product post={ post }  key={ post.id } />
                                         );
                                    })} 
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </div>
        </div>
    <Footer/>
</div>
    );


    }
}


const mapStateToProps = state => ({
  loading: state.search.loading,
  search: state.search.fetchsearch
});


export default connect(
  mapStateToProps,
  null
)(SearchContainer);