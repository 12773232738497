import { FETCH_INGREDIENT } from '../actions/types';

export default function ingredientReducer(state = [], action) {
  switch (action.type) {
    case FETCH_INGREDIENT:
      return action.ingredient;
    default:
      return state;
  }
}
