import { FETCH_ORDERHISTORY_BEGIN,FETCH_ORDERHISTORY_SUCCESS,FETCH_ORDERHISTORY_FAILURE,BASE_URL,LOGOUT} from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'orderhistory/';


export const fetchOrdersBegin = () => ({
  type: FETCH_ORDERHISTORY_BEGIN
});


// export const fetchOHistory = (orderhistory) => {
//   return {
//     type: FETCH_ORDERHISTORY,
//     orderhistory
//   }
// };

export const fetchOrdersSuccess = orderhistory => ({
  type: FETCH_ORDERHISTORY_SUCCESS,
  payload: { orderhistory }
});


export const fetchOrdersFailure = error => ({
  type: FETCH_ORDERHISTORY_FAILURE,
  payload: { error }
});




export const fetchAllOHistory = () => {
  return (dispatch) => {
    dispatch(fetchOrdersBegin());
    return axios.get(apiUrl)
      .then(response => {

       if(response.data.logstatus!=='failed'){

        dispatch(fetchOrdersSuccess(response.data))
       }
       else
       {
       dispatch(fetchOrdersSuccess());

       }
      
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};
