import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import parse from 'html-react-parser';
import {trimString} from '../utils/trimString';

export default ({ firstblog }) => {

 if (!firstblog) return <div id="main-wrapper">
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row" style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '110px'}}><img src="/images/loader.gif" alt="loading" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
    
    Loading in Progress...
</p>
</div>
</div>
</div>

</div>;


const imgUrl=IMG_BASE_URL+'blog/'+firstblog.image;


return (
 <div className="page-banner-section section bg-gray blog-banner-sectionmn desktop-view">
        <div className="blog-banner-section" style={{  

  backgroundImage: `url(${ imgUrl })`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="blog-banner-bottom-frame">
                       <Link to ={'/blog-detail/' +firstblog.slug} > <h3>{firstblog.title}</h3></Link>

                         <Link to ={'/blog-detail/' +firstblog.slug} ><p>{parse(trimString(firstblog.description,120))} </p></Link>
                        <p><Link to ={'/blog-detail/' +firstblog.slug} className="descount-sticker_btn">Read more</Link>
                        </p>
                        </div>
                       
                        
                    </div>
                </div>
            </div>
            </div>
        </div>






)




    }



