import { CHANGE_PASSWORD,CHANGE_SUBMIT,BASE_URL,CHANGE_FAILED,LOGOUT} from './types';
import { setAlert } from './alert';
import axios from 'axios';

const encodedata = window.location.href.split("/").pop(); 


const addapiUrl = BASE_URL+'changepassword/';

export const change = ({new_password,password}) => {
  return (dispatch) => {
    dispatch(changeSubmit());
    return axios.post(addapiUrl, {new_password,password})
      .then(response => {
		  if(response.data.logstatus =='failed'){
		  	    dispatch(changeFailed());
                dispatch(setAlert('Please enter correct Old Password', 'danger','change'));
		  }else{
			    dispatch(changeSuccess(response.data));
				dispatch(setAlert('Password has been updated successfully.', 'success','change'));
		  }
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

export const changeSuccess =  (data) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { 
		new_password: data.new_password,
    password:data.password,
   }
  }
};



function changeSubmit() {
    return {
        type: CHANGE_SUBMIT
    }
}
function changeFailed() {
    return {
        type: CHANGE_FAILED
    }
}
