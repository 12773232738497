import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { fetchAllProducts } from '../actions/product';
import { connect } from 'react-redux';
import Products from '../components/Products';


class ShopContainer extends Component {

	componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchAllProducts());
}


render(){

const {products,loading}  = this.props;

if (loading) return <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;

    
return (

<div id="main-wrapper">
<Header/>
    <div class="shop-section section pt-60 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
            <div class="container">       


<div class="row">
                            <div class="col-12">
                                <div class="shop-banner-title shoplists">
<h2 class="leafbg producimg shopmargintop" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>SHOP</h2>
                                </div>
                            </div>
                        </div>


<div class="row">    
{products && products.map(post => {
return (

<Products post={post}  key={ post.id } />
);
})}
</div>

</div>
</div>



        <Footer/>
        </div>



        )
    }
}



const mapStateToProps = state => {
  return {
        products: state.products.items,
        loading: state.products.loading
  };
};


export default connect(
  mapStateToProps,
  null
)(ShopContainer);