export const dateFormat =function dateFormat(date) {
	const DATE_OPTIONS = {year: 'numeric', month: 'long', day: 'numeric' };
	var today = (new Date(date)).toLocaleDateString('en-US', DATE_OPTIONS);
	return today;  
}







