import { FETCH_SEARCH,FETCH_SEARCH_REQ } from '../actions/types';


const initialState = {
  fetchsearch: [],
  loading: '',
  
};


export default function fetchsearchReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH_REQ:
      
      return {
        ...state,
        loading: true,
        
      };

    case FETCH_SEARCH:

      
      return {

        fetchsearch:action.fetchsearch,
        loading: false,
      } 
     


    default:
      return state;
  }
}
