import { ADDTOCART,ADDTOCART_SUBMIT,BASE_URL,FETCH_CARTPRODUCT,DELETE_CART,QUANTITYCHANGE,COUPON_ADD,DELETE_COUPON,LOGOUT} from './types';
import { showPopup } from './cartpopup';
import axios from 'axios';
import { setAlert } from './alert';
import {getCookieValue,setCookie} from '../utils/cookie';
const addapiUrl = BASE_URL+'addtocart';
var uniqueid = getCookieValue('cartid');
if(!uniqueid)
{
	setCookie('cartid',Math.random().toString().replace('0.', '')); 
}

uniqueid = getCookieValue('cartid');

export const createaddtoCart = ({id,title,quantity,price,shipping,feature_image,slug,sizes}) => {	
	return (dispatch) => {
		dispatch(contactSubmit());
		return axios.post(addapiUrl, {id,title,uniqueid,quantity,price,shipping,feature_image,slug,sizes})
		.then(response => {
		    dispatch(createaddtocartsuccess(response.data))
			dispatch(showPopup());
		})
		.catch(error => {
        if(error.response.status === 401){
			dispatch({ type: LOGOUT });
		  }
        throw(error);
      });
	};
};


export const createaddtocartsuccess =  (post) => {
  return {
    type: ADDTOCART,
    payload: {
     id: post.id,
     title: post.title,
     quantity: post.quantity,
     coupon_id: post.coupon_id,
     price: post.price,
     shipping: post.shipping,
     sizes: post.sizes,
     feature_image: post.feature_image,
   }
  }
};


function contactSubmit() {
    return {
        type: ADDTOCART_SUBMIT
    }
}


/*fetch Cart*/

const fetchurl = BASE_URL+'cartproduct/';
export const fetchCartproduct = (cartproduct) => {
  return {
    type: FETCH_CARTPRODUCT,
    payload:cartproduct
  }
};




export const fetchAllCartpro = () => {
  return (dispatch) => {
return axios.post(fetchurl,{uniqueid})
      .then(response => {
        dispatch(fetchCartproduct(response.data));
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};



/*Quanity change of Cart Product*/
const quantitychangeURL = BASE_URL+'cartquantitychange';
export const quantitychangeCartSuccess = quandata => {
  return {
    type: QUANTITYCHANGE,
    payload: {
    id: quandata[0],
    flag:quandata[1],
    }
  }
}

export const quantitychangeCart = quandata => {
  var id  = quandata[0];
  var flag = quandata[1];
  return (dispatch) => {
    return axios.post(`${quantitychangeURL}/${id}/${flag}`)
      .then(response => {
		dispatch(quantitychangeCartSuccess(response.data))
		dispatch(fetchAllCartpro());
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};



/// *** CHECK COUPON START  **/////

const couponURL = BASE_URL+'checkcoupon';
export const checkCoupon =  ({coupon}) => {
  return (dispatch) => {
    return axios.post(couponURL, {coupon,uniqueid})
    .then(response => {
      if(response.data.logstatus ==='1'){ 
        dispatch(createcouponSuccess(response.data));
        dispatch(fetchAllCartpro());    
        dispatch(setAlert('Coupon code applied successfully.', 'success','couponalert'));
      }
      if(response.data.logstatus ==='2'){  
        dispatch(createcouponSuccess(response.data));
        dispatch(fetchAllCartpro());
        dispatch(setAlert('Coupon code does not exist.', 'danger','couponalert'));
      }
      if(response.data.logstatus ==='3'){  
        dispatch(createcouponSuccess(response.data))
        dispatch(setAlert('Coupon code already applied.', 'success','couponalert'));
      }



    })
    .catch(error => {
      throw(error);
    });
  };
};

export const createcouponSuccess =  (post) => {
  return {
    type: COUPON_ADD,
    payload: {
    coupon: post.coupon,
   }
  }
};
/// *** CHECK COUPON END **/////



//// **** DELETE  COUPON **** ///
const deletecouponURL = BASE_URL+'coupondelete';
export const deleteCouponSuccess = uniqueid => {
  return {
    type: DELETE_COUPON,
    payload: {
      uniqueid
    }
  }
}

export const deleteCoupon = uniqueid => {
  return (dispatch) => {
    return axios.put(`${deletecouponURL}/${uniqueid}`)
    .then(response => {
		if(response.data.logstatus ==='failed'){			 
			dispatch(fetchAllCartpro());
		}else{
			dispatch(deleteCouponSuccess(response.data));
			dispatch(fetchAllCartpro());
      dispatch(setAlert('Coupon code has been removed.', 'success','couponalert'));
		}  
    })
    .catch(error => {
      if(error.response.status === 401){
      dispatch({ type: LOGOUT });
    }
      throw(error);
    });
  };
};

///// **** END DELETE  COUPON **** /////



/*delete Cart*/
const deleteURL = BASE_URL+'carts';

export const deleteCartSuccess = id => {
  return {
    type: DELETE_CART,
    payload: {
      id
    }
  }
}

export const deleteCart = id => {
	return (dispatch) => {    
    return axios.delete(`${deleteURL}/${id}`)
		.then(response => {		
			if(response.data.logstatus ==='failed'){			 
			   dispatch(setAlert('Something went wrong, Please try again!', 'danger',));
			}else{
				dispatch(deleteCartSuccess(response.data))
			}
		})
		.catch(error => {
			if(error.response.status === 401){
				dispatch({ type: LOGOUT });
			}
			throw(error);
		});
	};
};
