import { connect } from 'react-redux';
import { signup } from '../actions/auth';
import Register from '../components/Register';



const mapDispatchToProps = dispatch => {
  return {
    onSignup: post => {
     dispatch(signup(post));
       
    }
  };
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);




