import { FETCH_CATEGORY_PRO,BASE_URL,LOGOUT} from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'category/';


export const fetchCategoryProduct = (categorypro) => {
  return {
    type: FETCH_CATEGORY_PRO,
    categorypro
  }
};

export const fetchAllCategoryProduct = (slug) => {
	return (dispatch) => {
	return axios.get(apiUrl+slug)
		.then(response => {		  
			if(response.data.logstatus ==='failed'){			  
			 
			}else{
			    dispatch(fetchCategoryProduct(response.data))
			}
		})
		.catch(error => {
			if(error.response.status === 401){
				dispatch({ type: LOGOUT });
			}
			throw(error);
		});
	};
};