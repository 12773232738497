import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllOHistory } from '../actions/orderdetail';
import { connect } from 'react-redux';
import Orderdetail from '../components/Orderdetail';
import Accountsidebar from '../components/Accountsidebar';
import Footer from '../components/Footer';

 class OrderdetailContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const ordernumber = this.props.match.params.ordernumber;
    this.props.dispatch(fetchAllOHistory(ordernumber));




}
  render(){
    const {orderdetail}  = this.props; 
    const orderstatus = this.props.orderdetail[0]?this.props.orderdetail[0].order_status:"";


    if (orderdetail[0]=='f') {
      return<div id="main-wrapper">
        <Header/>
          <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                        <div class="row">  
                          <Accountsidebar/>   
                          <div class="col-lg-9 col-12 ordditls">
                           <div className="myaccount-table text-center detailsmob" style={{'borderLeft':'1px solid #eeeeee'}}>
                         <br/><br/><h2>Oops Something went wrong.<br/>Please try again later.</h2><br/>
                              </div>
                            </div>                           
                        </div>    
                    </div>                    
                </div> 
            </div>           
        </div>
      <Footer/>
    </div>
    }


      return (
        <div id="main-wrapper">
        <Header/>
        
        <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
             <div class="container">
                <div class="row"> 

            {orderstatus == 'Order Confirmed'?

                  <div className="col-md-12 border border-secondry succsesmsg">
                        <center>
                          <p style={{'padding-top':'10px','padding-bottom':'10px'}}>
                          <img src="https://www.theorganicforest.co.uk/assets/images/payment_img/verified.png" alt="Success" height="50" width="50" class="loading" data-was-processed="true"/>
                          </p>
                        </center>                        
                        <center>
                          <p> 
                          <span style={{'line-height':'18px','font-family':'Arial','font-size':'18px','color':'#139b3b'}}> 
                            Thank You! Your order has been placed Successfully
                          </span>
                          </p>
                        </center>                        
                    </div>
              : orderstatus == 'Failed'?
                    <div className="col-md-12 border border-secondry" style={{'backgroundColor':'#fff','border':'1px solid'}}>
                                            
                        <center>
                          <p> 
                          <span style={{'line-height':'18px','font-family':'Arial','font-size':'18px','color':'#139b3b','padding':'40px'}}> <br/>
                            Sorry! Your order has been Failed, Please try again!
                          </span><br/>
                          </p>
                        </center>                        
                    </div>:<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
                      <p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
                      Loading in Progress...
                      </p>
                      </div>
  }
                  </div>
                    <br/>
                        <div class="row">  
                          <Accountsidebar/>   
                          <div class="col-md-9 col-12">
       
                           <div className=" myaccount-table text-center" style={{'borderLeft':'1px solid #eeeeee'}}>

                            <Orderdetail orderdetail={orderdetail}/>
                 
                         </div>
                            </div>                           
                        </div>    
                                    
                </div> 
                     
        </div>
    

          <Footer/>
        </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    orderdetail: state.orderdetail
    
  };
};


export default connect(
  mapStateToProps,
  null
)(OrderdetailContainer);