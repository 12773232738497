import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTAccountinfo } from '../actions/accountinfo';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import AccountInfo from '../components/AccountInfo';
import Footer from '../components/Footer';
import Accountsidebar from '../components/Accountsidebar';


class AccountInfoContainer extends Component {
 
    componentDidMount() {
        window.scrollTo(0, 0);
	    this.props.dispatch(fetchAllTAccountinfo());

}

    render(){

const {accountinfo}= this.props;

if (!accountinfo.name) {
return  <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;
    }

        return (
            <div id="main-wrapper">
                <Header/>
                 <div className="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div className="page-banner text-center">
                       <h1><a href="/dashboard">Dashboard</a></h1>
                            <ul className="page-breadcrumb">
                                <li><Link exact to="/" >Home</Link></li>
                                <li>My Info</li>
                            </ul>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
                    <div>
                    
                    <div class="my-account-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
                        <div class="container">
                            <div class="row">                    
                                <div class="col-12">
                                    <div class="row">
                                     <Accountsidebar/>
                            
                                     <div class="col-lg-9 col-12">
                                        <AccountInfo accountinfo={accountinfo} />
                                   </div>                     
                                        
                                    </div>    
                                </div>                    
                            </div> 
                        </div>           
                        </div>
                    </div>
                
                <Footer/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        accountinfo: state.accountinfo,
    };
};
export default connect(
  mapStateToProps,
  null
)(AccountInfoContainer);
