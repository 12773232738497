import React from 'react';



export default class Ourmission extends React.Component {
render(){
return (
<div className="product-section section pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 largeicons" style={{'background':'#f8f8f8', 'padding':'30px 0px','marginBottom':'60px'}}>
<div className="col-12 pdphilo0 philoparent">                       
<div className="col-md-12  "> 
<div className="section-title mb-20 mb-xs-20 text-center">
<h2 className="leafbg stamppd">Your Safety Is Our Top Most Priority</h2>
</div>
</div>
<div className="container"> 

<div className="row stamppadding">
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-1.jpg" alt="Cruelty Free" title="Cruelty Free"/></p>
<p className="home_ourmission_text">Cruelty Free<br />& Safe</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-2.jpg" alt="Dermatologically" title="Dermatologically"/></p>
<p className="home_ourmission_text">Dermatologically<br />Tested</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-3.jpg" alt="Non GMO" title="Non GMO"/></p>
<p className="home_ourmission_text">Non GMO<br />Ingredients</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-4.jpg" alt="Toxin Free" title="Toxin Free"/></p>
<p className="home_ourmission_text">Toxin Free<br />Certified</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-5.jpg" alt="Anti Pollution" title="Anti Pollution"/></p>
<p className="home_ourmission_text">Anti Pollution<br />Certified</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-6.jpg" alt="Bpa Free" title="Bpa Free"/></p>
<p className="home_ourmission_text">Bpa Free<br />& Safe</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-7.jpg" alt="Plant Trees" title="Plant Trees"/></p>
<p style={{'marginBottom':'1rem','color':'#000000','lineHeight':'20px','fontSize':'14px','fontWeight':'500'}}>Plant Trees Eco<br />Conscious</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-8.jpg" alt="For All Skin" title="For All Skin"/></p>
<p className="home_ourmission_text">For All Skin<br />Type</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-9.jpg" alt="100% Vegan" title="100% Vegan"/></p>
<p className="home_ourmission_text">100% Vegan<br />& Organic</p>
</div>
<div className="stamp-icons wow zoomIn" data-animate="fadeInLeftBig" data-delay="-2000">
<p style={{'marginBottom':'0rem'}}><img src="/images/icon-10.jpg" alt="Organic Actives" title="Organic Actives"/></p>
<p className="home_ourmission_text">Organic Actives<br />Certified</p>
</div>

</div>



</div>
</div>
</div>  
)
}
}



