import {ADDTOWISHLIST,ADDTOWISHLIST_SUBMIT,BASE_URL,WISHLIST,DELETE_WISHLIST } from '../actions/types';
const initialState = {
  loading: '',
};

export default function wishlistReducer(state =initialState, action) {
const { type, payload } = action;
  switch (action.type) {
      case ADDTOWISHLIST_SUBMIT: 
            return {
                ...state,
                loading: true

            }
      case ADDTOWISHLIST:
      return {
          ...state,
          ...payload,
          loading: false
        };
     
 case WISHLIST:
      
       return {
         ...state,
        loading: false,
        wishlist:payload
      };
console.log(payload);
  case DELETE_WISHLIST:
   console.log(payload.id);
    return {
         ...state,
        loading: false,
          wishlist:state.wishlist.filter(post => post.wishlist_id != payload.wishlist_id.wishlist_id)
      };


    default:
        return state;
  }
}






