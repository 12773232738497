import React, {Component} from 'react';
import { fetchAllRProducts } from '../actions/relatedproduct';
import { connect } from 'react-redux';
import Product from '../components/Products';

class RelatedProContainer extends Component {


componentDidMount() {
window.scrollTo(0, 0);

this.props.dispatch(fetchAllRProducts());

}

render(){
const {relatedpro}  = this.props;

return (
<div className="row tf-element-carousel" >
{relatedpro.map(post => {
return (
<Product post={ post }  key={ post.id } />
);
})} 

</div>


)
}
}
const mapStateToProps = state => {
return {
relatedpro: state.relatedpro
};
};

export default connect(
mapStateToProps,
null
)(RelatedProContainer);