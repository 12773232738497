import React, {Component} from 'react';

export default class Privacypolicy extends Component {
render(){
return (

<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
<div className="container">

<div class="row">
<div class="col">
<div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28','marginTop':'45px'}}>PRIVACY POLICY</h2>

</div></div></div>
<div className="row">
<div className="col-12">
<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
This document is an electronic record in terms of the Information Technology Act, 2000 and rules made thereunder and as the same may be amended from time to time. Being a system generated electronic record, it does not require any physical or digital signature.
Greetings from <strong style={{'color':'#003628'}}>www.theorganicforest.in</strong>. The Website is owned by <strong style={{'color':'#003628'}}>TORFO wellness private limited</strong>, a company incorporated under the Companies Act, 2013 having <strong style={{'color':'#003628'}}>CIN U74999MH2017PTC296663</strong>, and having its registered address at 306 , SSR Corporate Park, Mathura Road , Faridabad , Haryana ("The Organic Forest" as “we” or “our”).</p>
<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
We respect the privacy of our users and the confidentiality of the information provided by you when using the Website. Accordingly, we have developed this Privacy Policy to demonstrate our commitment to protecting the same.</p>
<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
This Privacy Policy describes the type of information we collect, purpose, usage, storage and handling of such information, and disclosure thereof. We encourage you to read this Privacy Policy very carefully when (i) accessing the Website, and/or (ii) availing any products offered on or through the Website, from any computer, computer device or mobile.
By using the Website, you hereby expressly acknowledge, agree and accept the practices described in this Privacy Policy and agree to be bound by the terms and conditions thereof. If you do not agree, please do not use or access the Website.
</p>       
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>1. What information do we collect?</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> When you use the Website, we collect, store and share your personal and non-personal information which is provided by you from time to time. This allows us to provide you with products, services, offers and features for a customized and personalized Website experience.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> Your personal information helps us to collect information that can directly identify you such as your name, surname, email address, phone number, credit card/debit card and other payment instrument details. We also collect your non-personal information that does not directly identify you. By using the Website, you are authorizing us to collect, parse, process, disclose, disseminate and retain such information and making this available to other users and/or third parties but only as envisaged herein.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>c.</strong> We may offer you with a facility to browse the Website on an anonymous basis without revealing your personal information. However, once you provide us your personal information, you shall no longer be anonymous.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>d.</strong> In certain areas of the Website, we shall indicate which fields are mandatory and which are optional. You always have the option not to provide information in the optional fields. You may also be provided with a facility not to avail any particular service or feature on the Website.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>e.</strong> We use various tools and technologies to collect your personal and non-personal information from the device from which you access the Website and about your activities taking place under your account when you use the Website. Such non-personal information could include your IP address, device ID and type, your browser type and language, operating system used, access times, your device geographic location and the referring website address. We may use this information for our internal research purposes to better understand and serve our users.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>f.</strong> When you visit the Website, we may assign your device one or more cookies. A "cookie" is a small piece of data sent from a website and stored by a web server on a user's browser while the user is browsing that website. We place cookies to facilitate access to the Website and to personalize your overall user experience. Through the use of a cookie, we may also automatically collect information about your activity on the Website. The cookies do not contain any of your personally identifiable information.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>g.</strong> Additionally, we may allow our partner websites and service providers, advertising companies and ad networks, and other third parties to display advertisements on the Website. These third parties may use tracking technologies, such as cookies or web beacons, to collect information about users who view or interact with their advertisements. You are always free to decline cookies if your browser permits, however, in such a scenario, you may not be able to use certain Website features. We may also use web beacons and other similar technologies to track your use of the Website.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>h.</strong> If you are permitted by the Website and if you choose to post any messages on the Website or leave any feedback or comment, we will collect such information you have provided and may retain this information as necessary to resolve disputes, provide customer support, troubleshoot problems or to improve Website performance as permitted by applicable laws.
<strong>i.</strong> We may use your contact information to send you offers from time to time or to provide you with any information about any products or services or any new feature of the Website.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>2. How we use the information we collect?</strong>
</p>

<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> Verify your eligibility;<br/>
<strong>b.</strong> Deliver and improve the products / services offered through the Website;<br/>
<strong>c.</strong> Manage your account and provide you with customer support;<br/>
<strong>d.</strong> Perform research and analysis about your use of, or interest in, products, services, or features offered on the Website;<br/>
<strong>e.</strong> Communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you or ordered by you from the Website;<br/>
<strong>f.</strong> Develop, display, and track content and advertising tailored to your interests;<br/>
<strong>g.</strong> Provide you with a customized / personalized Website user experience;<br/>
<strong>h.</strong> Perform functions or services as otherwise described to you at the time of collection;<br/>
<strong>i.</strong> To resolve disputes and troubleshoot problems, if any;<br/>
<strong>j.</strong> To collect payment towards any products/services availed by you;<br/>
<strong>k.</strong> Inform you about any offers, products, services, features and/or updates;<br/>
<strong>l.</strong> Enforce or exercise any rights in our Terms of Use;<br/>
<strong>m.</strong> Generally to undertake and manage our business.<br/>
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>3. With whom we share your information?</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> We do not share your personal information with others except as indicated in this Privacy Policy. We may share your personal information with our affiliates, partners and service providers who may render certain services on our behalf but strictly for the purpose of rendering such services. These services may include fulfilling orders, providing customer service and marketing assistance, performing business and sales analysis, ad tracking and analytics, member screenings, supporting our Website design and functionality, and supporting promotions and other features offered through the Website. These service providers are however not permitted to share or use such information for any other purpose.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> We may also disclose your personal information <br/>
</p>
<p style={{'paddingLeft':'18px','lineHeight':'20px'}}>
<strong>(i)</strong> For complying with applicable laws, requests or orders from law enforcement agencies, appropriate authorities (such as without limitation, court officials, expert professional investigative agencies and the like) or for any legal process or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. <br/>
<strong>(ii)</strong> For enforcing our Terms of Use.<br/>
<strong>(iii</strong>) For protecting or defending ours, any Website user’s or any third party's rights or property. <br/> 
<strong>(iv)</strong> For supporting any fraud/legal investigation/verification checks. <br/>
<strong>(v)</strong> In connection with a corporate transaction, including but not limited to sale of our business, merger, consolidation, re-organization, amalgamation, restructuring of business or in the unlikely event of bankruptcy.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>c.</strong> We may use and share your non-personal information we collect under any of the above circumstances, including with third parties to develop and deliver targeted advertising on the Website and on websites and/or applications of third parties, and to undertake analysis thereof. We may combine non-personal information we collect with additional non-personal information collected from other sources. We also may share aggregated, personal or non-personal information in hashed, non-human readable form, with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis or other business purposes.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>d.</strong> We may also share your personal information with our other partners, service providers and affiliates to help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of our services.
</p>



<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>4. Links to Third Party Websites</strong></p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
The Website may, from time to time, contain links to as well as from third party websites, including our partner websites, advertisers or affiliates. If you follow such link to any of these websites, please note that these websites have their own privacy policies and terms of use and that we do not accept any liability or responsibility for their said policies. Please check these third party policies before you provide any information to them.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>5. How do we protect your personal information? </strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
We adopt reasonable security practices and procedures to help safeguard your personal information under our control from unauthorized access. However, you acknowledge that no Internet transmission or system or server can be 100% secure. Therefore, although we take all reasonable steps to secure your personal information, we do not promise or guarantee the same, and you should not expect that your personal information, chats, or other communications while using the Website will always remain secure and safeguarded by us. You should always exercise caution while providing, sharing or disclosing your personal information using the Website. We request you to take steps to keep your personal information safe and to always log out of the Website after use. You are solely liable and responsible for any information you provide and/or share using the Website.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>6. Changes to the Privacy Policy</strong></p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
Our Privacy Policy is subject to change at any time without notice. When we post changes to this Privacy Policy, we will revise the "last updated" date. We recommend that you check the Website from time to time to keep yourself updated of any changes in this Privacy Policy or any of our other Website policies. </p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>7. Contact us </strong>
</p>
<p class="prod-title ptag"  style={{'textAlign':'left','paddingLeft':'10px','lineHeight':'20px'}}>
You can reach us by email at <strong style={{'color':'#003628'}}>care@theorganicforest.in</strong> any questions or comments regarding this Privacy Policy.
</p>
<p></p>
</div>
</div>
</div>
</div>


)
}
}