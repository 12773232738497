import React, {Component} from 'react';
import { Link,NavLink,withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import { fetchAllCartpro } from '../actions/cart';
import {Currency} from '../utils/currency';
import { IMG_BASE_URL } from '../actions/types';
import { deleteCart } from '../actions/cart';
import CartDropdownSub from '../components/CartDropdownSub';



class CartSubContainer extends React.Component {


 deletetoCart(post) {
      this.props.onDeletetocart(post);
    }



componentDidMount() {
      
         window.scrollTo(0, 0);
        this.props.dispatch(fetchAllCartpro());
  


    }


render(){
  const {cartproduct}  = this.props;


//if (!cartproduct) return <div></div>;


return (
<div >
           <CartDropdownSub cartproduct={cartproduct}/>


                </div>


 )
    }
}





const mapStateToProps = state => {
  return {
    cartproduct: state.cartproduct.cartproduct,
    
  };
};


export default connect(
  mapStateToProps,
  null
)(CartSubContainer);