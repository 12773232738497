import { connect } from 'react-redux';
import { forgot } from '../actions/forgot';
import Forgot from '../components/Forgotpassword';

const mapDispatchToProps = dispatch => {
  return {
    onforgot: post => {
     dispatch(forgot(post));
       
    }
  };
};
const mapStateToProps = state => {
  return {
    forgot: state.forgot
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot);







