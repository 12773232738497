import uuid from 'uuid';
import { SHOW_CART_POPUP, REMOVE_CART_POPUP } from './types';

export const showPopup = (msg, alertType,divid, timeout = 4000) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SHOW_CART_POPUP,
    payload: { msg, alertType,divid, id }
  });

  setTimeout(() => dispatch({ type: REMOVE_CART_POPUP, payload: id }), timeout);
};
