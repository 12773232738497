import React, {Component} from 'react';

export default class Ourstory extends Component {
    render(){
        return (
		<div>
        <div>

        <div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50 ">
          
            <div className="container">

            <div class="row"><div class="col"><div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28'}}>OUR STORY</h2>

</div></div></div>


             <div className="row">
                          
                           
          
                            <div className="col-lg-12 col-12">
                                <div >
                                    
                                    <div className="tab-pane11" >
                                        <div className="myaccount-contentabb">
                                            <h3 style={{'color': '#404041', 'textAlign': 'center'}}><b>A melange of 'natural' and 'new-wave' of healthy living, starting with your wellness products</b></h3>
                                            <p>The Organic Forest is a proud outcome of our resolve and passion of healthy lifestyle for our loved ones.  The learnings along this road of health, healing and a holistic lifestyle were truly revelatory for both us and those around us, because what started out as a journey of working towards simple well-being, eventually became a passion much larger than what we thought it would be and we took it upon ourselves to ensure to make a difference in this world laden with pollutants that's threatening our very existence.</p>
                                            <p>The manifestation of all this was our new focus on switching from bandaid fixes to long term solutions for healthy life by providing the most natural and greenest products created in India. While we talk of all organs and parts of our body, the single largest living and breathing organ is our skin which also happens to be relatively ignored. While the world is abound with infinite number of skincare products most of them fail to address the real need of the skin that can only be sated by honest organic products.</p>
                                            <p>The bedrock of philosophy behind Organic Forest is inspired by the solutions provided by home and natural remedies that have existed over thousands of years and find their place in vedas.</p>
                                            <p>Our products are not only very friendly and inert for one's skin but also are completely free from any chemicals or heavy metals. These toxins not only spoil the skin irreversibly but also fail to biodegrade thereby leaving a lasting damage on mother earth.</p> 
                                            <p>All our products, as the name suggests, come from the abundant natural environment that the Earth offers, making them effective, without side-effects, and sustainable. Most importantly, it allows them to provide the best for you!</p>

                                        </div>
                                    </div>
                                  
                               </div>
                            </div>
                            
                        </div>  




            </div>           
        </div>
        </div>
        </div>
        )
    }
}