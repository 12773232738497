import React, {Component} from 'react';
import Header from '../components/Header';
import { fetchAllTPosts } from '../actions/ingredient';
import { connect } from 'react-redux';
import Ingredients from '../components/Ingredients';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";


class IngredientsContainer extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchAllTPosts());

}



componentDidUpdate(prevProps) {

let path=this.props.match.path.replace("/","");


}






    render(){
 const {ingredient}  = this.props;
    return (

<div id="main-wrapper">
<Helmet>
<meta charSet="utf-8" />
<title>Our Ingredients | The Organic Forest </title>
<meta name="description" content="The Organic Forest products are Certified Vegan & Plant-Based, 100% Vegetarian, like Organic Argan Oil, Aloe Vera, TeaTree, Apple Cider Vinegar that are a superfood for your hair." />
<meta name="Keywords" content="Our Ingredients"/>
</Helmet>

<Header/>
<div>
<div className="page-banner-section section bg-gray ingredients-section" style={{'padding':'10px 0 20px'}}>
<div className="container">
<div className="row">
<div className="col">
<div className="about-us-banner-bottom-frame-ing">
<h3>Our Ingredients</h3>
<h4 style={{'color': '#193a43', 'textAlign': 'center','marginBottom': '20px'}}><strong>Vegan friendly formulas generally tend to use plant-based ingredients.</strong></h4>
  {/*<p>The Organic Forest is a proud outcome of our resolve and passion of healthy lifestyle for our loved ones.  The learnings along this road of health, healing and a holistic lifestyle were truly revelatory for both us and those around us, because what started out as a journey of working towards simple well-being, eventually became a passion much larger than what we thought it would be and we took it upon ourselves to ensure to make a difference in this world laden with pollutants that’s threatening our very existence.</p>
  <p><a href ='/our-story' class="descount-sticker_btn">Read more</a></p>*/}
</div>
</div>
</div>
</div>
</div> 
<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50 ouringredientspage">
<div className="container">
<div className="row">
<div className="col-lg-12 order-lg-2 order-1">
<div className="row">
<div className="col-12">
<div className="shop-banner-title">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#193a43','textAlign':'center'}}>{/*Our */}Ingredients</h2>
</div>
</div>
</div>

<div className="row">
<div className="col-12">
<div className="shop-product">
<div id="myTabContent-2" className="tab-content">
<div id="grid" className="tab-pane fade active show">
<div className="product-grid-view">
<div className="row">

 {ingredient.map(post => {
    return (
    <div className="col-lg-3 col-md-6 col-sm-6 ingri-section">
      <div className="single-product mb-30 text-center "  style={{'height':'auto','border':'none'}}>
    <Ingredients post={ post }  key={ post.id } />
      </div>
    </div>
          );
    })}


</div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>      

</div>

</div>
</div>


<Footer/>
</div>



        )
    }
}

const mapStateToProps = state => {
  return {
    ingredient: state.ingredient
  };
};


export default connect(
  mapStateToProps,
  null
)(IngredientsContainer);