import {ADDTOWISHLIST,ADDTOWISHLIST_SUBMIT,WISHLIST,BASE_URL,DELETE_WISHLIST,LOGOUT } from './types';
import axios from 'axios';
import { setAlert } from './alert';


const addapiUrl = BASE_URL+'addtowishlist';

export const createaddtoWishlist = ({id}) => { 
  return (dispatch) => {
    dispatch(contactSubmit());
    return axios.post(addapiUrl, {id})
    .then(response => {
      dispatch(createaddtowishlistsuccess(response.data));

        if(response.data.wishlist_id){
          dispatch(createaddtowishlistsuccess(response.data));
          dispatch(fetchAllTPosts(response.data.user_id));
            dispatch(setAlert('Added to your Wishlist.', 'success','wishlist'+response.data.product_id));
        }
        else{
       
        dispatch(setAlert('Removed from your Wishlist.', 'success','wishlist'+response.data.product_id));
        }
    })
    .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};



export const createaddtowishlistsuccess =  (post) => {
  return {
    type: ADDTOWISHLIST,
    payload: {
     id: post.id,
   
   }
  }
};


function contactSubmit() {
    return {
        type: ADDTOWISHLIST_SUBMIT
    }
}

const fetchurl = BASE_URL+'wishlist/';


export const fetchTPosts = (wishlist) => {
  return {
    type: WISHLIST,
    payload:wishlist
  }
};

export const fetchAllTPosts = () => {
  return (dispatch) => {
    return axios.get(fetchurl)
      .then(response => {      
			if(response.data.logstatus ==='failed'){
			  
			}else{
				dispatch(fetchTPosts(response.data));
			}
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

/*delete Wishlist*/
const deleteURL = BASE_URL+'wishlistdelete';
export const deleteWishlistSuccess = wishlist_id => {
  return {
    type: DELETE_WISHLIST,
    payload: {
      wishlist_id
    }
  }
}

export const deleteWishlist = wishlist_id => {
  return (dispatch) => {    
    return axios.put(`${deleteURL}/${wishlist_id}`)
      .then(response => {
			if(response.data.logstatus ==='failed'){			 
				dispatch(setAlert('Something went wrong. Please try again later.', 'danger','wishlist'+response.data.product_id));
			}else{
			    dispatch(deleteWishlistSuccess(response.data))
				dispatch(setAlert('Removed from your Wishlist.', 'success','wishlist'+response.data.product_id));
			}
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

