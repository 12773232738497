import { FETCH_ACCOUNTINFO,ACCOUNT_UPDATE,INFO_SUBMIT,BASE_URL} from '../actions/types';

export default function accountinfoReducer(state = [], action) {
const { type, payload } = action;

  switch (action.type) {

    case INFO_SUBMIT: 
            return {
                ...state,
                loading: true

            }
	    case ACCOUNT_UPDATE:
	    return {
	        ...state,
	       fetchaccountinfo:payload
	      };
        case FETCH_ACCOUNTINFO:
        return action.fetchaccountinfo;
        default:
      return state;
  }
}
