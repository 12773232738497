import { FETCH_SOLUTIONS,BASE_URL,LOGOUT } from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'allcategory';


export const fetchSolutions = (fetchsolutions) => {
  return {
    type: FETCH_SOLUTIONS,
    fetchsolutions
  }
};

export const fetchAllSolutions = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
      .then(response => {
        console.log(response.data);
        dispatch(fetchSolutions(response.data))
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};