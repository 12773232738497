import { FETCH_INGREDIENTPRO,BASE_URL,LOGOUT } from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'ingredientpro/';

export const fetchIProducts = (fetchingredientrelated) => {
  return {
    type: FETCH_INGREDIENTPRO,
    fetchingredientrelated
  }
};

export const fetchAllIRelated = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
      .then(response => {      
			if(response.data.logstatus ==='failed'){
			 
			}else{
			  dispatch(fetchIProducts(response.data))
			}		
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};