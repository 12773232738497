export const BASE_URL1 = 'http://localhost:4005/';

export const SITE_URL = 'http://localhost:3001/';

export const BASE_URL = 'https://www.theorganicforest.in:4006/';

export const SITE_URL1  = 'https://www.theorganicforest.co.uk/';


export const IMG_BASE_URL1 = 'http://localhost/organicforestteamadmin/assets/images/';

export const IMG_BASE_URL = 'https://www.theorganicforest.in/organicforestteamadmin/assets/images/';


export const CCAENUE_URL = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
export const MERCHANT_ID = '237942';
export const ACCESSCode = 'AVNH88GK70AQ87HNQA';
export const WORKINGKEY = '198A0BE337F4EAF8B94E4E114AA61CE1';
export const COUNTRY = 'India';
export const FETCH_TESTIMONIAL = 'FETCH_TESTIMONIAL';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const FETCH_INGREDIENT = 'FETCH_INGREDIENT';
export const CONTACT_SUBMIT = 'CONTACT_SUBMIT';
export const ADD_CONTACTUS = 'ADD_CONTACTUS';
export const FETCH_INGREDIENTPRO = 'FETCH_INGREDIENTPRO';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_BEGIN = 'FETCH_PRODUCT_BEGIN';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const BESTS_FETCH_PRODUCT = 'BESTS_FETCH_PRODUCT';
export const DEALS_FETCH_PRODUCT = 'DEALS_FETCH_PRODUCT';
export const NEW_FETCH_PRODUCT = 'NEW_FETCH_PRODUCT';
export const FETCH_PRODUCTDETAIL = 'FETCH_PRODUCTDETAIL';
export const FETCH_SEARCH = 'FETCH_SEARCH';
export const FETCH_RELATEDPRO = 'FETCH_RELATEDPRO';
export const ADD_SUBSCRIBE = 'ADD_SUBSCRIBE';
export const SUBSCRIBE_SUBMIT = 'SUBSCRIBE_SUBMIT';
export const FETCH_SEARCH_REQ = 'FETCH_SEARCH_REQ';
export const FETCH_TAG = 'FETCH_TAG'; 
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUBMIT = 'SIGNUP_SUBMIT';
export const FETCH_CATEGORY_PROD = 'FETCH_CATEGORY_PROD';
export const FETCH_CATEGORY_REQ = 'FETCH_CATEGORY_REQ'; 
export const FETCH_SOLUTIONS = 'FETCH_SOLUTIONS';
export const FETCH_BLOG = 'FETCH_BLOG';
export const FETCH_BLOGDETAIL = 'FETCH_BLOGDETAIL';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const FETCH_CATEGORY_PRO = 'FETCH_CATEGORY_PRO';
export const FETCH_SUB_CATEGORY_PRO = 'FETCH_SUB_CATEGORY_PRO';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';
export const LOGIN = 'LOGIN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_CHECK = 'AUTH_CHECK';
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const REVIEW = 'REVIEW';
export const WISHLIST = 'WISHLIST';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_SUBMIT = 'CHANGE_SUBMIT';
export const CHANGE_FAILED = 'CHANGE_FAILED';
export const FETCH_ORDERHISTORY_BEGIN = 'FETCH_ORDERHISTORY_BEGIN'; 
export const FETCH_ORDERHISTORY_SUCCESS = 'FETCH_ORDERHISTORY_SUCCESS'; 
export const FETCH_ORDERHISTORY_FAILURE = 'FETCH_ORDERHISTORY_FAILURE'; 
export const ADDTOCART = 'ADDTOCART';
export const ADDTOCART_SUBMIT = 'ADDTOCART_SUBMIT';
export const ADDTOWISHLIST = 'ADDTOWISHLIST';
export const ADDTOWISHLIST_SUBMIT = 'ADDTOWISHLIST_SUBMIT';
export const QUANTITYPLUS = 'QUANTITYPLUS';
export const QUANTITYMINUS = 'QUANTITYMINUS';
export const QUANTITYPLUS_SUBMIT = 'QUANTITYPLUS_SUBMIT';
export const FETCH_CARTPRODUCT = 'FETCH_CARTPRODUCT';
export const DELETE_CART = 'DELETE_CART';
export const PLACE_ORDER = 'PLACE_ORDER';
export const FETCH_ORDERDETAIL = 'FETCH_ORDERDETAIL';
export const FETCH_ACCOUNTINFO = 'FETCH_ACCOUNTINFO'; 
export const DELETE_WISHLIST = 'DELETE_WISHLIST';
export const FETCH_BLOGCOMMENT = 'FETCH_BLOGCOMMENT';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADDBLOGCOMMENT_SUBMIT = 'ADDBLOGCOMMENT_SUBMIT';
export const QUANTITYCHANGE = 'QUANTITYCHANGE';
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';
export const FETCH_PROREVIEW = 'FETCH_PROREVIEW';
export const ADD_REVIEW = 'ADD_REVIEW';
export const REVIEWCOMMENT_SUBMIT = 'REVIEWCOMMENT_SUBMIT';
export const RELATED_PRODUCTS = 'RELATED_PRODUCTS';
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
export const INFO_SUBMIT = 'INFO_SUBMIT';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const ADDTOADDRESS = 'ADDTOADDRESS';
export const ADDTOADDRESS_SUBMIT = 'ADDTOADDRESS_SUBMIT';
export const FETCH_ADDRESS = 'FETCH_ADDRESS';
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
export const SHOW_CART_POPUP = 'SHOW_CART_POPUP';
export const REMOVE_CART_POPUP = 'REMOVE_CART_POPUP';
export const FORGOT = 'FORGOT';
export const FORGOT_SUBMIT = 'FORGOT_SUBMIT';
export const FORGOT_FAILED = 'FORGOT_FAILED';
export const RESET = 'RESET';
export const RESET_SUBMIT = 'RESET_SUBMIT';
export const FETCH_ORDERSTATUS = 'FETCH_ORDERSTATUS';
export const STATUS_CHANGE = 'STATUS_CHANGE';
export const COUPON_ADD = 'COUPON_ADD';
export const DELETE_COUPON = 'DELETE_COUPON';
export const FETCH_PRODUCTREVIEW = 'FETCH_PRODUCTREVIEW';


////{"msg":{"name":"TokenExpiredError","message":"jwt expired","expiredAt":"2019-12-27T20:25:25.000Z"}}