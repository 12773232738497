import { BASE_URL,FETCH_PRODUCTREVIEW} from '../actions/types';
const initialState = {
  loading: '',
};

export default function allreviewsReducer(state = [], action) {
const { type, payload } = action;

  switch (action.type) {

     case FETCH_PRODUCTREVIEW:      
      return action.allreview
    default:
      return state;
  }
}
