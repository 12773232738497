import React, { Component } from "react";
import { startplaceorder } from "../actions/order";
import { connect } from "react-redux";
import Checkoutcart from "../components/Checkoutcart";
import { withRouter, Redirect } from "react-router-dom";
import { createaddtoAddress } from "../actions/address";
import {
  MERCHANT_ID,
  CCAENUE_URL,
  COUNTRY,
  ACCESSCode,
  WORKINGKEY,
  BASE_URL,
} from "../actions/types";
import { getCookieValue, setCookie } from "../utils/cookie";
import axios from "axios";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  state = {
    name: "",
    phone: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    nameErrors: "",
    phoneErrors: "",
    addressErrors: "",
    cityErrors: "",
    zipErrors: "",
    stateErrors: "",
    status: 1,
    show_address: false,
    add_in_billing: true,
    ship_id: false,
    bill_id: true,
    pmethod: "razorpay",
    ordernumber: "",
    method: "POST",
    delivery_name: "",
    delivery_address: "",
    delivery_city: "",
    delivery_state: "",
    delivery_state: "",
    delivery_zip: "",
    delivery_country: "",
    delivery_tel: "",
    uniqueid: getCookieValue("cartid"),
    razorpay: "",
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    this.setState({ show_address: true });
    let selectedpmethod = document.querySelector(
      "input[name=payment-method]:checked"
    ).value;
  }



  validate = (e) => {
    let nameErrors = "";
    let phoneErrors = "";
    let emailErrors = "";
    let addressErrors = "";
    let cityErrors = "";
    let zipErrors = "";
    let stateErrors = "";
  
   
    
    var zipRegex = /^\d{6}$/;
    var phoneRegex = /^\d{10}$/;
    

    if (!this.state.name) {
      nameErrors = "Name cannot be blank";
    }
   
    if (!this.state.phone) {
    phoneErrors = "Phone Number cannot be blank";
    } 
    else if (!phoneRegex.test(this.state.phone)) {
     phoneErrors = "Please enter valid Phone Number";
    } 

    if (!this.state.address) {
      addressErrors = "Address cannot be blank";
    }
    if (!this.state.city) {
      cityErrors = "City cannot be blank";
    }
    if (!this.state.zip) {
      zipErrors = "Postcode cannot be blank";
    } else if (!zipRegex.test(this.state.zip)) {
      zipErrors = "Please enter valid Postcode";
    }
    if (!this.state.state) {
      stateErrors = "State cannot be blank";
    }

    if (
      nameErrors ||
      phoneErrors ||
      addressErrors ||
      cityErrors ||
      zipErrors ||
      stateErrors
    ) {
      this.setState({
        nameErrors,
        phoneErrors,
        addressErrors,
        cityErrors,
        zipErrors,
        stateErrors,
      });
      return false;
      e.preventDefault();
    }
    return true;
  };

  handleChange = (e) => {
    let addressid = e.target.value;
    this.setState({
      name: this.props.address ? this.props.address[addressid].name : "",
      phone: this.props.address ? this.props.address[addressid].phone : "",
      address: this.props.address ? this.props.address[addressid].address : "",
      zip: this.props.address ? this.props.address[addressid].zip : "",
      state: this.props.address ? this.props.address[addressid].state : "",
      city: this.props.address ? this.props.address[addressid].city : "",
    });
  };

  handleSubmit = (e) => {
    const isValid = this.validate();
    if (isValid) {
      if (this.state.method == "POST") {
        e.preventDefault();
        this.props.placeorder(this.state);
      } else {
        e.preventDefault();
        this.setState({
          action: "#",
          method: null,
        });
        //this.props.onAddAddress(this.state);

        this.props.placeorder(this.state);
      }
    } else {
      e.preventDefault();
      this.setState({
        action: "#",
        method: null,
      });
    }
  };
  async displayRazorpay() {
    const { amount, id, currency, ordernumber } = this.props.order;

    const options = {
      key: "rzp_live_8v3dGMOV8ZMfU5",
      currency: currency,
      amount: amount,
      order_id: id,
      name: "The Organic Forest",
      description: "Thank you for nothing. Please give us some money",
      image: "/images/logo.png",
      handler: async function (response) {
        var values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          transactionid: response.razorpay_payment_id,
          transactionamount: amount,
          ordernumber: ordernumber,
        };
        axios
          .post(BASE_URL + "payment", values)
          .then((res) => {
            window.location.href = "/order-confirm/" + ordernumber;
          })
          .catch((e) => console.log(e));
      },
      prefill: {
        name: this.state.name,
        email: this.props.auth.user.email,
        contact: this.state.phone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  componentDidMount() {
    if (
      this.props.location.state != undefined &&
      this.props.location.state.detail &&
      this.props.location.state.detail.bill_id
    ) {
      let billingasshipping = this.props.location.state.detail;
      this.setState({
        name: billingasshipping.name,
        phone: billingasshipping.phone,
        address: billingasshipping.address,
        city: billingasshipping.city,
        zip: billingasshipping.zip,
        state: billingasshipping.state,
      });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousState.delivery_address == "") {
      this.setState({
        delivery_name: previousProps.location.state.detail.name,
        delivery_address: previousProps.location.state.detail.address,
        delivery_city: previousProps.location.state.detail.city,
        delivery_state: previousProps.location.state.detail.state,
        delivery_zip: previousProps.location.state.detail.zip,
        delivery_tel: previousProps.location.state.detail.phone,
      });
    }
    if (this.props.order.loading !== previousProps.order.loading) {
      this.displayRazorpay();
    }
  }

  handleOptionChange = (e) => {
    if (e.target.value == "razorpay") {
      this.setState({
        //action: CCAENUE_URL,
        method: "POST",
        pmethod: "razorpay",
      });
    } else {
      this.setState({
        action: "#",
        method: null,
        pmethod: "cash",
      });
    }
  };

  render() {
    const { cartproduct, address, order, auth } = this.props;
  
    if (cartproduct == "") {
      return <Redirect to="/cart" />;
    }

    if (this.props.location.state == undefined) {
      return <Redirect to="/cart" />;
    }

    let selcartid = this.props.cartproduct
      ? this.props.cartproduct[0].bill_id
      : 0;

    return (
      <div>
        <div class="checkout-progress-indicator">
          <div class="step inactive">
            <span class="step-content">
              <span class="step-number" style={{ marginRight: "5px" }}>
                <img src="/images/shipping.png" />
              </span>
              Shipping
            </span>
          </div>
          <div class="step active step-1">
            <span class="step-content">
              <span class="step-number" style={{ marginRight: "5px" }}>
                <img src="/images/billing.png" />
              </span>
              Billing &amp; Payment
            </span>
          </div>
          <div class="step inactive">
            <span class="step-content">
              <span class="step-number" style={{ marginRight: "5px" }}>
                <img src="/images/confirm.png" />
              </span>
              Order Confirmation
            </span>
          </div>
        </div>

        <div class="checkout-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="row row-40">
                  <div class="col-lg-7 col-12">
                    <form
                      onSubmit={(e) => this.handleSubmit(e, order)}
                      className="checkout-form"
                      method={this.state.method}
                      name="redirect"
                      action={this.state.action}
                      ref={(form) => (this.form = form)}
                    >
                      <div id="shipping-form1">
                        <h4 class="checkout-title">Billing Address</h4>

                        {this.props.address != "" && this.props.address && (
                          <div class="row">
                            <div class="col-12 mb-5">
                              <select
                                class="nice-select"
                                onChange={this.handleChange}
                              >
                                {this.props.address.map((post, index) => {
                                  return (
                                    <option
                                      key={post.id}
                                      value={index}
                                      selected={selcartid == post.id}
                                    >
                                      ({post.city}) {post.name} {post.address}{" "}
                                      {post.zip}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}

                        <div class="row">
                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="name_id"
                              name="name"
                              placeholder="Full Name"
                              onChange={this.handleInputChange}
                              value={this.state.name}
                            />
                            <div className='warning-msg'>
                              {this.state.nameErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="phone_id"
                              maxlength="20"
                              name="phone"
                              placeholder="Phone Number"
                              onChange={this.handleInputChange}
                              value={this.state.phone}
                            />
                            <div className='warning-msg'>
                              {this.state.phoneErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="address_id"
                              name="address"
                              placeholder="Address"
                              onChange={this.handleInputChange}
                              value={this.state.address}
                            />
                           <div className='warning-msg'>
                              {this.state.addressErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="city_id"
                              name="city"
                              placeholder="City"
                              onChange={this.handleInputChange}
                              value={this.state.city}
                            />
                           <div className='warning-msg'>
                              {this.state.cityErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="zip_id"
                              name="zip"
                              maxlength="16"
                              title="Enter 6 digit Postal Code"
                              placeholder="Postal Code"
                              onChange={this.handleInputChange}
                              value={this.state.zip}
                            />
                            <div className='warning-msg'>
                              {this.state.zipErrors}
                            </div>
                          </div>
                          <div class="col-12 mb-5">
                            <input
                              type="text"
                              id="state"
                              name="state"
                              maxlength="15"
                              minlength="3"
                              placeholder="State"
                              onChange={this.handleInputChange}
                              value={this.state.state}
                              autoComplete="off"
                            />
                           <div className='warning-msg'>
                              {this.state.stateErrors}
                            </div>
                          </div>

                          <div class="col-12 mb-5">
                            {this.state.show_address && (
                              <div class="single-method1">
                                <input
                                  type="checkbox"
                                  id="add_address"
                                  name="status"
                                  checked={this.state.status}
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  for="status"
                                  style={{
                                    color: "#000000",
                                    display: "inline-block",
                                    fontWeight: "400",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Add to Address Book
                                </label>
                              </div>
                            )}
                          </div>

                          <div class="col-10 mb-5">
                            <div class="row">
                              <div class="col-12 mb-30">
                                <h4 class="checkout-title">Payment Method</h4>
                                <div class="checkout-payment-method">
                             <div class="single-method">
                                    <input
                                      type="radio"
                                      id="payment_cash"
                                      name="payment-method"
                                      value="cash"
                                      onChange={this.handleOptionChange}
                                      checked={this.state.pmethod == "cash"}
                                    />
                                    <label for="payment_cash">
                                      Cash on Delivery (COD)
                                    </label>
                                    <p data-method="cash">
                                      Please send a Check to Store name with
                                      Store Street, Store Town, Store State,
                                      Store Postcode, Store Country.
                                    </p>
                                  </div>

                                  <div class="single-method">
                                    <input
                                      type="radio"
                                      id="payment_razorpay"
                                      name="payment-method"
                                      value="razorpay"
                                      onChange={this.handleOptionChange}
                                      checked={this.state.pmethod == "razorpay"}
                                    />
                                    <label for="payment_razorpay">
                                    Make payments via cards
                                    </label>
                                    <p data-method="razorpay">
                                      Please send a Check to Store name with
                                      Store Street, Store Town, Store State,
                                      Store Postcode, Store Country.
                                    </p>
                                  </div>
                                </div>
                                <input
                                  type="hidden"
                                  name="access_code"
                                  id="access_code"
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="encRequest"
                                  id="encRequest"
                                  value=""
                                />

                                <button
                                  class="place-order btn btn-lg btn-round descount-sticker_btn"
                                  style={{ marginTop: "25px" }}
                                >
                                  Place order
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <Checkoutcart cartproduct={cartproduct} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    placeorder: (post) => {
      dispatch(startplaceorder(post));
    },
    onAddAddress: (post) => {
      dispatch(createaddtoAddress(post));
    },
  };
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  order: state.order,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Billing)
);
