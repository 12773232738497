import { FETCH_BLOGDETAIL,BASE_URL,LOGOUT} from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'blog/';


export const fetchblogdetail = (fetchblogdetail) => {
  return {
    type: FETCH_BLOGDETAIL,
    fetchblogdetail
  }
};

export const fetchAllblogdetail = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
		.then(response => { 
			if(response.data.logstatus ==='failed'){ 
			}else{
			  dispatch(fetchblogdetail(response.data));
			}
		})
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};