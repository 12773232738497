import React, {Component} from 'react';

export default class Checkout extends Component {
    render(){
        return (			
			<div>
				<div class="page-banner-section section bg-gray" style={{'padding': '10px 0 20px'}}>
					<div class="container">
						<div class="row">
							<div class="col">                        
								<div class="page-banner text-center">
									<h1>Checkout</h1>
									<ul class="page-breadcrumb">
										<li><a href="index.html">Home</a></li>
										<li>Checkout</li>
									</ul>
								</div>                        
							</div>
						</div>
					</div>
				</div>
					  
				<div class="checkout-progress-indicator">
					<div class="step step-1 inactive">
						<span class="step-content">
						<span class="step-number">1.</span>
						Shipping
						</span>
					</div>
					<div class="step step-2 active">
						<span class="step-content">
						<span class="step-number">2.</span>
						Billing &amp; Payment
						</span>
					</div>
					<div class="step step-3 inactive">
						<span class="step-content">
						<span class="step-number">3.</span>
						Order Confirmation
						</span>
					</div>
				</div>

			<div class="checkout-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
            <div class="container">
                <div class="row">
                    <div class="col-12">    
                            
                        <form action="#" class="checkout-form">
                        <div class="row row-40">
                            <div class="col-lg-7">
                              
                                <div id="billing-form" class="mb-10">
                                    <h4 class="checkout-title">Billing Address</h4>
                                    <div class="row">
                                        <div class="col-md-6 col-12 mb-20">
                                            <label>First Name*</label>
                                            <input type="text" placeholder="First Name"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Last Name*</label>
                                            <input type="text" placeholder="Last Name"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Email Address*</label>
                                            <input type="email" placeholder="Email Address"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Phone no*</label>
                                            <input type="text" placeholder="Phone number"/>
                                        </div>

                                        <div class="col-12 mb-20">
                                            <label>Company Name</label>
                                            <input type="text" placeholder="Company Name"/>
                                        </div>

                                        <div class="col-12 mb-20">
                                            <label>Address*</label>
                                            <input type="text" placeholder="Address line 1"/>
                                            <input type="text" placeholder="Address line 2"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Country*</label>
                                            <select class="nice-select">
                                                    <option>Bangladesh</option>
                                                    <option>China</option>
                                                    <option>country</option>
                                                    <option>India</option>
                                                    <option>Japan</option>
                                            </select>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Town/City*</label>
                                            <input type="text" placeholder="Town/City"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>State*</label>
                                            <input type="text" placeholder="State"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Zip Code*</label>
                                            <input type="text" placeholder="Zip Code"/>
                                        </div>

                                        <div class="col-12 mb-20">
                                            <div class="check-box">
                                                <input type="checkbox" id="create_account"/>
                                                <label for="create_account">Create an Acount?</label>
                                            </div>
                                            <div class="check-box">
                                                <input type="checkbox" id="shiping_address" data-shipping/>
                                                <label for="shiping_address">Ship to Different Address</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div id="shipping-form">
                                    <h4 class="checkout-title">Shipping Address</h4>
                                    <div class="row">
                                        <div class="col-md-6 col-12 mb-20">
                                            <label>First Name*</label>
                                            <input type="text" placeholder="First Name"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Last Name*</label>
                                            <input type="text" placeholder="Last Name"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Email Address*</label>
                                            <input type="email" placeholder="Email Address"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Phone no*</label>
                                            <input type="text" placeholder="Phone number"/>
                                        </div>

                                        <div class="col-12 mb-20">
                                            <label>Company Name</label>
                                            <input type="text" placeholder="Company Name"/>
                                        </div>

                                        <div class="col-12 mb-20">
                                            <label>Address*</label>
                                            <input type="text" placeholder="Address line 1"/>
                                            <input type="text" placeholder="Address line 2"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Country*</label>
                                            <select class="nice-select">
                                                    <option>Bangladesh</option>
                                                    <option>China</option>
                                                    <option>country</option>
                                                    <option>India</option>
                                                    <option>Japan</option>
                                            </select>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Town/City*</label>
                                            <input type="text" placeholder="Town/City"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>State*</label>
                                            <input type="text" placeholder="State"/>
                                        </div>

                                        <div class="col-md-6 col-12 mb-20">
                                            <label>Zip Code*</label>
                                            <input type="text" placeholder="Zip Code"/>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-5">
                                <div class="row">                                  
                                    <div class="col-12 mb-60">
                                        <h4 class="checkout-title">Cart Total</h4>
                                        <div class="checkout-cart-total">
                                            <h4>Product <span>Total</span></h4>
                                            <ul>
                                                <li>
												<div style={{'margin-bottom':'20px'}}>
												<span style={{'width':'130px','height':'100px','float':'left','margin-right':'15px'}}><a href="single-product.html" tabindex="0">
													<img src="assets/images/protien-shampoo.png" alt=""/>
												</a></span>
												<span style={{'float':'left','margin-right':'15px','padding-top':'40px'}}>Teritory Quentily X 01</span> 
												<span style={{'padding-top':'40px'}}>$35.00</span></div></li>
												<div style={{'clear':'both','height':'10px','border-top': '1px dashed #b3dca4'}}></div>
												<li>
												<div>
												<span style={{'width':'130px','height':'100px','float':'left','margin-right':'15px'}}><a href="single-product.html" tabindex="0">
													<img src="assets/images/protien-shampoo.png" alt=""/>
												</a></span>
												<span style={{'float':'left','margin-right':'15px','padding-top':'40px'}}>Adurite Silocone X 02</span> 
												<span style={{'padding-top':'40px'}}>$59.00</span></div></li>
								                <div style={{'clear':'both','height':'10px','border-top': '1px dashed #b3dca4'}}></div>
                                                <li>
												<div>
												<span style={{'width':'130px','height':'100px','float':'left','margin-right':'15px'}}><a href="single-product.html" tabindex="0">
													<img src="assets/images/protien-shampoo.png" alt=""/>
												</a></span>
												<span style={{'float':'left','margin-right':'15px','padding-top':'40px'}}>Baizidale Momone X 01</span> 
												<span style={{'padding-top':'40px'}}>$78.00</span></div></li>
								                <div style={{'clear':'both','height':'10px','border-top':' 1px dashed #b3dca4'}}></div>
                                                <li>
												<div>
												<span style={{'width':'130px','height':'100px','float':'left','margin-right':'15px'}}><a href="single-product.html" tabindex="0">
													<img src="assets/images/protien-shampoo.png" alt=""/>
												</a></span>
												<span style={{'float':'left','margin-right':'15px','padding-top':'40px'}}>Makorone Cicile X 01</span> 
												<span style={{'padding-top':'40px'}}>$65.00</span></div></li>
								                <div style={{'clear':'both','height':'2px'}}></div>
                                                
                                            </ul>
                                            <p>Sub Total <span>$296.00</span></p>
                                            <p>Shipping Fee <span>$00.00</span></p>
                                            <h4>Grand Total <span>$296.00</span></h4>
                                        </div>
                                    </div>
                               
                                    <div class="col-12 mb-30">

                                        <h4 class="checkout-title">Payment Method</h4>

                                        <div class="checkout-payment-method">

                                            <div class="single-method">
                                                <input type="radio" id="payment_check" name="payment-method" value="check"/>
                                                <label for="payment_check">Check Payment</label>
                                                <p data-method="check">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                            </div>

                                            <div class="single-method">
                                                <input type="radio" id="payment_bank" name="payment-method" value="bank"/>
                                                <label for="payment_bank">Direct Bank Transfer</label>
                                                <p data-method="bank">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                            </div>

                                            <div class="single-method">
                                                <input type="radio" id="payment_cash" name="payment-method" value="cash"/>
                                                <label for="payment_cash">Cash on Delivery</label>
                                                <p data-method="cash">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                            </div>

                                            <div class="single-method">
                                                <input type="radio" id="payment_paypal" name="payment-method" value="paypal"/>
                                                <label for="payment_paypal">Paypal</label>
                                                <p data-method="paypal">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                            </div>

                                            <div class="single-method">
                                                <input type="radio" id="payment_payoneer" name="payment-method" value="payoneer"/>
                                                <label for="payment_payoneer">Payoneer</label>
                                                <p data-method="payoneer">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                            </div>

                                            <div class="single-method">
                                                <input type="checkbox" id="accept_terms"/>
                                                <label for="accept_terms">I’ve read and accept the terms & conditions</label>
                                            </div>

                                        </div>

                                        <button class="place-order btn btn-lg btn-round">Place order</button>

                                    </div>

                                </div>
                            </div>

                        </div>
                        </form> 
                        
                    </div>
                </div>            
            </div>
        </div>
			
			</div>
        )
    }
}