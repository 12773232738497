import React, {Component} from 'react';

export default class Faq extends Component {
    render(){
        return (

<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
            <div className="container">

            <div class="row"><div class="col"><div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28'}}>FAQs</h2>

</div></div></div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="faq-content">
                            <div className="faq-desc">
                                <h3>Below are frequently asked questions, you may find the answer for yourself</h3>
                            </div>
                        </div>
                        <div className="faq-accordion">
                            <div id="accordion">
                              <div className="card actives">
                                <div className="card-header" id="headingOne">
                                  <h5 className="mb-0">
                                    <a className="" data-toggle="collapse"  href="/#" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                      Are the Products Safe and Effective? 
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                  <div className="card-body">
Yes, Products are super safe and all the ingredients first verified and then considered to be part of the product. We have verified these ingredients through EWG rating & we can say with confidence that most of our ingredients have EWG Rating 1 and none of the ingredients have rating more than 2.

                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingTwo">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                      What is the shelf life of the product?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                  <div className="card-body">
                                  Shelf life of products is 2 yrs.
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingThree">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

                                    Are there any preservatives in the product?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                  <div className="card-body">
Yes, we have preservative in the products and it is derived from Coconut & Vegetable. We Don’t use Paraben & Other toxic preservative in the formulation.

                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingFour">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
What do you mean by Vegan?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                  <div className="card-body">
Vegan means Product made of only plant derived ingredients and No animal derived ingredient is used in the product.
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingFive">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                  What do you mean by Cruelty Free?

                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                  <div className="card-body">
No animal testing is done to check the quality of products and being vegan promoter, We don’t encourage for the animal testing.

                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingSix">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
 Are you using any synthetic fragrances?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                  <div className="card-body">
No, we are using oil based fragrances in our products.


                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingSeven">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
What do you mean by BPA free packaging?
                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                  <div className="card-body">
Our packaging doesn’t have Bisphenol which is toxic chemical, can cause hormonal imbalance and cancer.                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingEight">
                                  <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="/#" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
Are your ingredients Organic?                                    </a>
                                  </h5>
                                </div>
                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                  <div className="card-body">
Yes, We have organic ingredients in the products and also, most of our ingredients are eccocert & cosmos approved.                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        )
    }
}