import {BASE_URL,FETCH_BLOGCOMMENT,ADD_COMMENT,ADDBLOGCOMMENT_SUBMIT,LOGOUT} from './types';
import axios from 'axios';
import { setAlert } from './alert';

///// BLOG COMMENT API ////

const apiUrl = BASE_URL+'blogcommentfetch/'
export const fetchTblogcomment = (blogcomment) => {
  return {
    type: FETCH_BLOGCOMMENT,   
      blogcomment
  }
};

export const fetchAllblogcomment = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
    .then(response => {
		if(response.data.logstatus ==='failed'){
		}else{
		 dispatch(fetchTblogcomment(response.data))
		}
    })
    .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};




//// Blog comment submit ///


const apiUrlblog = BASE_URL+'blogcommentsubmit/';

export const createComment = ({name,email,comment,slug}) => {
  return (dispatch) => {
    return axios.post(apiUrlblog+slug, {name,email,comment,slug})
      .then(response => {      
			if(response.data.logstatus ==='failed'){
			   dispatch(setAlert('Blog comment has not been Added successfully.', 'danger'));
			}else{
				dispatch(createCommentSuccess(response.data))
				dispatch(setAlert('Blog Comment Added Successfully', 'success'));
			}
      })
      .catch(error => {
        if(error.response.status === 401){
			dispatch({ type: LOGOUT });
		}
        throw(error);
      });
  };
};




export const createCommentSuccess =  (post) => {
  return {
    type: ADD_COMMENT,
    payload: {
      name: post.name,
      email:post.email,
      comment: post.comment,
      slug:post.slug
    }
  }
};

