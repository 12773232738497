import React, {Component} from 'react';
import { fetchAllTPosts } from '../actions/fetchTestimonial';
import Carousel from 'react-bootstrap/Carousel'
import { connect } from 'react-redux';
import OurSolutionsCarousel from '../components/OurSolutionsCarousel';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


class OurSolutionsCarouselContainer extends Component {


componentDidMount() {

  this.props.dispatch(fetchAllTPosts());

}


render(){


const {fetchtestimonial}  = this.props;


const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed:6000,
  autoplaySpeed: 6000,
  cssEase: "linear",
  pauseOnHover:true,
  draggable:true,

};



return (
<div>





<div className="product-section section mt-5 pt-md-65 pt-sm-60 pb-20 pb-lg-40 pb-md-40 pb-sm-30 expertviewbg" id="cktestimonial">
<div className="container ckdata">
<div className="row">

<div className="text-center col-lg-4 col-md-6 col-12"> 
<div className="launcbanner-v1 " > 
<h4>Wholesome Vegan Hair Love</h4>
<p>
<Link to ={'/product/coconut-oil-100-ml'} >
  <LazyLoadImage effect="blur" src="/images/product-launch.jpg" alt="Vegan Hair Love" title="Vegan Hair Love"/>
</Link>
</p>
<p>
<Link to ={'/product/coconut-oil-100-ml'} className="descount-sticker_btn" style={{'whiteSpace':'nowrap','marginBottom':'12px'}}>
Discover Now
</Link>
  
  </p>
</div>
</div>

<div className="text-center col-lg-4 col-md-6 col-12" >
<div className="wellness-box " >  
<h4>Holistic Wellness in a Box</h4>
<p><LazyLoadImage effect="blur" src="/images/wellness-box.jpg" alt="Wellness in a Box" title="Wellness in a Box"/></p>

<p><a class="descount-sticker_btn" style={{'whiteSpace':'nowrap','marginBottom':'12px',visibility:'hidden'}}>Discover Now</a></p>

</div>
</div>

<div className="text-center col-lg-4 col-md-6 col-12" > 
<div className="hairrebirth-v1" >  
<h4>Ayurvedic Hair Rebirth</h4>

<p>
<Link to ={'/product/amla-bhringraj-and-shikakai-shampoo-for-nourished-hair'} >

<LazyLoadImage effect="blur" src="/images/hair-rebirth.jpg" alt="" title=""/>
</Link>
</p>


<p>
  

<Link to ={'/product/amla-bhringraj-and-shikakai-shampoo-for-nourished-hair'} className="descount-sticker_btn" style={{'whiteSpace':'nowrap','marginBottom':'12px'}}>
Discover Now
</Link>


  
  
  </p>
</div>
</div>



</div>
</div>
</div>




</div>
);
}
}


const mapStateToProps = state => {
return {
fetchtestimonial: state.fetchtestimonial
};
};


export default connect(
mapStateToProps,
null
)(OurSolutionsCarouselContainer);