import { FETCH_ORDERHISTORY_BEGIN,FETCH_ORDERHISTORY_SUCCESS,FETCH_ORDERHISTORY_FAILURE} from '../actions/types';

const initialState = {
  orderhistory: [],
  loading: false,
  error: null
};


export default function orderhistoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERHISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

     case FETCH_ORDERHISTORY_SUCCESS:
    
      return {
        ...state,
        loading: false,
        orderhistory: action.payload.orderhistory?action.payload.orderhistory:[]
      };

      case FETCH_ORDERHISTORY_FAILURE:
  
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        orderhistory: []
      };

      
    default:
      return state;
  }
}
