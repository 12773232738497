import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Bloglist from '../components/Bloglist';
import Firstblog from '../components/firstblog';
import { fetchbloghomepost } from '../actions/blog';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";


class BlogContainer extends Component {


	componentDidMount() {
  
    this.props.dispatch(fetchbloghomepost());

}







    render(){
const {blog,firstblog}  = this.props;



        return (

        <div id="main-wrapper">
        <Helmet>
        <meta charSet="utf-8" />
        <title>The Organic Forest | Blog pages</title>
        <meta name="description" content="Benefits of vegan lifestyle & its impact on sutainability of environment" />
        <meta name="Keywords" content="Blog Pages"/>
        </Helmet>
        <Header/>


<Firstblog firstblog={ firstblog }   />
       
<div className="blog-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-65 pb-lg-45 pb-md-35 pb-sm-25 pb-xs-15 desktop-view">
<div className="container">
<div className="row  ">
{blog.slice(1).map((post, index) => {

return (
    
       <Bloglist post={ post }  key={ post.id } />
  );
    
})}
</div>
</div>
</div>

<div className="blog-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-65 pb-lg-45 pb-md-35 pb-sm-25 pb-xs-15 mobile-view">
<div className="container">
<div className="row  ">
{blog.map((post, index) => {

return (
    
       <Bloglist post={ post }  key={ post.id } />
  );
    
})}
</div>
</div>
</div>


<Footer/>
</div>

     )
    }
}




const mapStateToProps = state => {
  return {
    blog: state.blog,
    firstblog: state.blog[0]
  };
};


export default connect(
  mapStateToProps,
  null
)(BlogContainer);