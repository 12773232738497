import { FETCH_RELATEDPRO } from '../actions/types';

export default function fetchrelatedproReducer(state = [], action) {
  switch (action.type) {
    case FETCH_RELATEDPRO:
      return action.fetchrelatedpro;
    default:
      return state;
  }
}
