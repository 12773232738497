import React, {Component} from 'react';
import Header from '../components/Header';
import Philosophy from '../components/Philosophymore';
import Footer from '../components/Footer';

export default class PhilosophyContainer extends Component {
  
	componentDidMount() {
    window.scrollTo(0, 0);
}



    render(){
        return (

        <div id="main-wrapper">
        <Header/>
        <Philosophy />
        <Footer/>
        </div>

     )
    }
}