import React, {Component} from 'react';
import {connect} from 'react-redux';
import { createComment } from '../actions/blogcomment';
import Alert from '../layout/Alert';

const initialState =  {
    name: '',
    email:'',
    comment:'',
    nameError:'',
	emailError:'',
	commentError:'',
  };

 class Blogcommentsubmit extends Component {

  state = initialState;
  constructor(props) {
    super(props);
    this.state = {
     slug:this.props.slug
    };
  }
	handleInputChange = event => {
		const isCheckbox = event.target.type === "checkbox";
		this.setState({
		  [event.target.name]: isCheckbox
			? event.target.checked
			: event.target.value
		});
	};

  validate = () => {
    let nameError = "";
    let emailError = "";
    let commentError = "";
	
	if (!this.state.comment) {
       commentError = "Comment cannot be blank";
    }
	
    if (!this.state.name) {
       nameError = "Name cannot be blank";
    }
   
	
     if (!this.state.email) {
      emailError = "Email cannot be blank";
    }
        if(this.state.email){
       let lastAtPos = this.state.email.lastIndexOf('@');
       let lastDotPos = this.state.email.lastIndexOf('.');

       if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
       emailError = "Please enter a valid email address (Ex: maxdoe@domain.com).";
      }
      }
   
   if (nameError || emailError || emailError || commentError) {
      this.setState({nameError,commentError,emailError});
      return false;
    }
    return true;
  };

 handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.props.onAddComment(this.state);
      this.setState(initialState);
    }
 };


    render(){


  return (
        <div>
          <Alert />
         <form onSubmit={ this.handleSubmit }>
            <p className="comment-note"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>
            <div className="row">
               <div className="col-md-12">
                    <div className="single-input mb-20">
                        <label>Comment*</label>
                        <textarea name="comment" placeholder="Message" cols="30" rows="5" onChange={ this.handleInputChange }  value={ this.state.comment }></textarea>
						 <div style={{ fontSize:14,color: "red"}}>
							{this.state.commentError}
						</div>
					</div>
                </div>
                <div className="col-md-6">
                    <div className="single-input mb-20">
                        <label>Name*</label>
                        <input name="name"  onChange={ this.handleInputChange } onBlur={this.validate} value={ this.state.name } type="text"/>
                        <div style={{ fontSize:14,color: "red"}}>
							{this.state.nameError}
						</div>
					</div>
                </div>
                <div className="col-md-6">
                    <div className="single-input mb-20">
                        <label>Email*</label>
                        <input  name="email" onChange={ this.handleInputChange } value={ this.state.email } type="text"/>
						 <div style={{ fontSize:14,color: "red"}}>
							{this.state.emailError}
						</div>
					</div>
                </div>
               
                <div className="col-md-12">
                    <div className="single-input">
                        <button class="btn descount-sticker_btn">Post Comment</button>
                    </div>
                </div>	
        </div>
    </form>
    </div>

    )
}
}


const mapDispatchToProps = dispatch => {
  return {
    onAddComment: post => {     
      dispatch(createComment(post));       
    }
  };
};



export default connect(
  null,
  mapDispatchToProps
)(Blogcommentsubmit);