import { FETCH_SEARCH,BASE_URL,FETCH_SEARCH_REQ,LOGOUT } from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'search/';

export const fetchTSearch = (fetchsearch) => {
  return {
    type: FETCH_SEARCH,
    fetchsearch:fetchsearch
  }
};

export const fetchAllTSearch = (searchkey) => {
  return (dispatch) => {
    dispatch(fetchStarted());
    return axios.get(apiUrl+searchkey)
      .then(response => {     
			if(response.data.logstatus ==='failed'){
			}else{
				dispatch(fetchTSearch(response.data))
			}	
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};


function fetchStarted() {
    return {
        type: FETCH_SEARCH_REQ
    }
}


