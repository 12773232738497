import { FETCH_TAG } from '../actions/types';

export default function tagReducer(state = [], action) {
  switch (action.type) {
    case FETCH_TAG:
      return action.tag;
    default:
      return state;
  }
}
