import { FETCH_TAG,BASE_URL,LOGOUT } from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'tag/';


export const fetchTPosts = (tag) => {
  return {
    type: FETCH_TAG,
    tag
  }
};

export const fetchAllTPosts = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
      .then(response => {
        dispatch(fetchTPosts(response.data))
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};