import React, {Component} from 'react';
import { Link,NavLink,withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import {Currency} from '../utils/currency';
import { IMG_BASE_URL } from '../actions/types';
import { deleteCart } from '../actions/cart';

class CartDropdownSub extends React.Component {


 deletetoCart(post) {
      this.props.onDeletetocart(post);
    }





render(){
  const {cartproduct}  = this.props;


if (!cartproduct) return <div><div style={{'height':'250px','overflow-y':'auto'}}>
<ul class="cart-items"></ul>
<div className="cart-total" style={{'textAlign':'center','marginTop':'-50px','paddingBottom':'70px'}}>
                       Your Cart is Empty.
                
                    </div><div className="">
                      
                        <NavLink className="descount-sticker_btn" style={{'width':'100%'}} exact to="/cart" >View Cart</NavLink>
                        <NavLink className="descount-sticker_btn" style={{'width':'100%'}} exact to="/shipping" >Checkout</NavLink>
                      
                    </div></div>
              </div>;


return (
<div>
 <div style={{'height':'250px','overflow-y':'auto'}}>
            <ul className="cart-items">

           { cartproduct.map((post) => 

                        <li className="single-cart-item">
                            <div className="cart-img">
                                <Link to ={'/product/' +post.slug} className="prodimgsrc" ><img src={IMG_BASE_URL+'products/'+post.feature_image} alt=""/></Link>
                            </div>
                            <div className="cart-content">
                                <Link to ={'/product/' +post.slug} className="prodimgsrc" ><h5 className="product-name">{post.title}</h5></Link>
                                <span className="product-quantity">{post.quantity} × </span>
                                <span className="product-price">{Currency}{post.tof_product.Price}</span>
                            </div>
                            <div className="cart-item-remove">


                                  <i className="fa fa-trash" style={{cursor: 'pointer'}} onClick={() => this.deletetoCart(post.id)} ></i>
                            </div>
                        </li>
                      )} 
                    </ul>
                    </div>
{/*{cartproduct!='' && 
                    <div className="cart-total">
                        <h5>Subtotal :<span className="float-right">{Currency}{cartproduct.reduce((a, c) => (a +c.tof_product.Price * c.quantity), 0)}</span></h5>
                        <h5>Shipping Cost:<span className="float-right">{Currency}{cartproduct.reduce((a, c) => (a + c.shipping * c.quantity), 0)}</span></h5>
                        <h5>Grand Total: <span className="float-right">{Currency}{cartproduct.reduce((a, c) => (a + c.tof_product.Price * c.quantity + c.shipping * c.quantity), 0)}</span></h5>
                
                    </div>
                    }*/}
          {cartproduct=='' && 
                    <div className="cart-total" style={{'textAlign':'center','marginTop':'-200px','paddingBottom':'70px'}}>
                       Your Cart is Empty.
                
                    </div>
                    }          
                    <div className="">
                      
                        <NavLink className="descount-sticker_btn" style={{'width':'100%'}} exact to="/cart" >View Cart</NavLink>
                        <NavLink className="descount-sticker_btn" style={{'width':'100%'}} exact to="/shipping" >Checkout</NavLink>
                      
                    </div>
              


                </div>


 )
    }
}






const mapDispatchToProps = dispatch => ({
  onDeletetocart: post =>  dispatch(deleteCart(post)),
  
})





export default connect(
  null,
  mapDispatchToProps
)(CartDropdownSub);