import { ADD_SUBSCRIBE,BASE_URL,SUBSCRIBE_SUBMIT,SUBSCRIBE_FAILED,LOGOUT} from './types';
import { setAlert } from './alert';

import axios from 'axios';

const addapiUrl = BASE_URL+'subscription';

export const createSubscribe = ({email,rtoken}) => {
	return (dispatch) => {
		dispatch(subscribeSubmit());
		return axios.post(addapiUrl, {email,rtoken})
		.then(response => {
			if(response.data.logstatus ==='failed'){
			   dispatch(subscribeFailed()); 
			   dispatch(setAlert('You are already subscribed.', 'danger','subscription'));
			}
			else if(response.data.logstatus ==='failed2'){
			   dispatch(subscribeFailed()); 
			   dispatch(setAlert('ReCaptcha Error, Please try again.', 'danger','subscription'));
			}
			else{
			  dispatch(createSubscribeSuccess(response.data));
			  dispatch(setAlert('Thank You For Subscribing!', 'success','subscription'));
			}
		})
		.catch(error => {
			if(error.response.status === 401){
				dispatch({ type: LOGOUT });
			}
			throw(error);
		});
	};
};

export const createSubscribeSuccess =  (data) => {
  return {
    type: ADD_SUBSCRIBE,
    payload: {
      email:data.email,
      rtoken:data.rtoken,    
    }
  }
};

function subscribeSubmit() {
    return {
        type: SUBSCRIBE_SUBMIT
    }
}

function subscribeFailed() {
    return {
        type: SUBSCRIBE_FAILED
    }
}
