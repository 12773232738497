import React from 'react';
import { IMG_BASE_URL } from '../actions/types';
import parse from 'html-react-parser';

export default ({ blogdetail: { title, description,image, slug, id }}) => {


 if (!description) return <div id="main-wrapper">
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row" style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '110px'}}><img src="/images/loader.gif" alt="loading" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}} />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
    
    Loading in Progress...
</p>
</div>
</div>
</div>

</div>;

const imgUrl=IMG_BASE_URL+'blog/'+image;

return (

     <div className="blog-section section  pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 ">
            <div className="container">
                <div className="row">
               
                    <div className="col-lg-12 mb-sm-40 mb-xs-30">
                        <div className="blog_area">
                            <article className="blog_single blog-details">
                                <header className="entry-header">
                                   
                                    <h1 className="entry-title">
                                      {title}
                                    </h1>
                                  
                                   
                                </header>
                                
                  <div className="post-thumbnail img-full desktop-view" style={{  

  backgroundImage: `url(${ imgUrl })`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height:'550px'
}}>

</div>

                  <div className="post-thumbnail img-full mobile-view" style={{  

  backgroundImage: `url(${ imgUrl })`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height:'220px'
}}>

</div>

                                {/*<div className="post-thumbnail img-full">
                                    <img src={imgUrl} alt=""/>
                                </div>*/}


                                <div className="postinfo-wrapper">
                                    <div className="post-info">
                                        <div className="entry-summary blog-post-description">
                                        

    <p>{parse(description)}</p>
                                                                                        {/*<div className="social-sharing">
                                                <div className="widget widget_socialsharing_widget">
                                                    <h3 className="widget-title">Share this post</h3>
                                                    <ul className="blog-social-icons">
                                                        <li>
                                                            <a target="_blank" title="Facebook" href="/#" className="facebook social-icon">
                                                                <i className="fa fa-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" title="twitter" href="/#" className="twitter social-icon">
                                                                <i className="fa fa-twitter"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" title="pinterest" href="/#" className="pinterest social-icon">
                                                                <i className="fa fa-pinterest"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" title="linkedin" href="/#" className="linkedin social-icon">
                                                                <i className="fa fa-linkedin"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>*/}







                                        </div>
                                    </div>
                                </div>
                            </article>
                           
                        </div>
                       
                      
                    </div>
                </div>
            </div>
        </div>






)
    }



