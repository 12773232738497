import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { createaddtoCart } from '../actions/cart';
import {connect} from 'react-redux';


class Addtocart extends Component {



addtoCart(post) {

  
    post.quantity=post.quantity;
    this.props.onAddtocart(post);


  }




 render(){

const post= this.props.produtdata;


  
return (
 <>
 {/*<div></div>*/}
<a href={null} class="descount-sticker_btn" onClick={() => this.addtoCart(post)} style={{'whiteSpace':'nowrap'}}>Add to Cart</a>
</>
 )
    }
}



const mapDispatchToProps = dispatch => {
  return {
    onAddtocart: post => {
     dispatch(createaddtoCart(post));
    }
  };
};

const mapStateToProps = state => {
  return {
    cart: state.cart
    
  };
};


export default connect(
mapStateToProps,
mapDispatchToProps
)(Addtocart);

