import { ADDTOADDRESS,ADDTOADDRESS_SUBMIT,BASE_URL,FETCH_ADDRESS,DELETE_ADDRESS,ADDRESS_UPDATE,LOGOUT} from './types';
import { setAlert } from './alert';
import axios from 'axios';


const addapiUrl = BASE_URL+'addaddress/';

export const createaddtoAddress = (post) => {
	
	return (dispatch) => {
		dispatch(addressSubmit());
		return axios.post(addapiUrl, post)
		.then(response => {
     dispatch(createaddresssuccess(response.data));
     if(response.data.logstatus==='success')
     {
      dispatch(setAlert('Add to Address Book successfully.', 'success'));

     }
     else if(response.data.logstatus==='failed')
     {
     dispatch(setAlert('Address is already added in Our Record.', 'success'));
      }


		  
		})
		.catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
	};
};


export const createaddresssuccess =  (post) => {
  return {
    type: ADDTOADDRESS,
    payload:post
  }
};


function addressSubmit() {
    return {
        type: ADDTOADDRESS_SUBMIT
    }
}


/*fetch Address*/

const fetchurl = BASE_URL+'getuseraddress/';
export const fetchAddress = (address) => {
  return {
    type: FETCH_ADDRESS,
    payload:address
  }
};




export const fetchAlladdress = () => {
  return (dispatch) => {



return axios.get(fetchurl)
      .then(response => {
        console.log(response.data);
        dispatch(fetchAddress(response.data));

      })
     .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};


/*delete Address*/

const deleteURL = BASE_URL+'deleteuseraddress';


export const deleteAddressSuccess = id => {
  return {
    type: DELETE_ADDRESS,
    payload: {
      id
    }
  }
}

export const deleteAddress = id => {

  return (dispatch) => {

    
    return axios.put(`${deleteURL}/${id}`)
      .then(response => {
        dispatch(deleteAddressSuccess(response.data))
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

const apiUrl = BASE_URL+'updateuseraddress';

export const updateAddress = ({name,address,email,phone,city,zip,state}) => { 
  return (dispatch) => {
    dispatch(addressSubmit());
    return axios.post(apiUrl, {name,address,email,phone,city,zip,state})
    .then(response => {
        dispatch(updateaddresssuccess(response.data))
      dispatch(setAlert('Account Updated successfully.', 'success'));
    })
    .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};
export const updateaddresssuccess =  (post) => {
  return {
    type: ADDRESS_UPDATE,
    payload: {
     name: post.name,
     address: post.address,
     phone: post.phone,
     city: post.city,
     email: post.email,
     zip:post.zip,
     state: post.state,
   }
  }
};
