import React from 'react';
import SlickSlider from "react-slick";
import {NavLink } from 'react-router-dom'

export default class Slider extends React.Component {
 
 render(){
  const settings = {
      dots: true,
      infinite: true,
      //cssEase: 'ease-out',
      //cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
      //touchThreshold: 100,
      speed:1000,
      autoplaySpeed:4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      fade:true,
      pauseOnHover:false,
      draggable:true,

    };

return (

<div className="hero-section section position-relative slidersection " >
<SlickSlider {...settings} className="tabaki" style={{'margin':'0px !important'}}>



<NavLink exact to="/shop" className="sliderimagelink">
<div style={{'margin': '0px !important'}}>
<div className="hero-item bg-image" style={{'marginLeft':'-10px !important'}}>
<img src="/images/hero/home-banner-final.png" className="sldimage desktopslide" alt="Product Slider" title="Product Slider" />
<img src="/images/hero/home-bannermob.jpg?v=1" className="sldimage mobileslide" alt="Product Slider" title="Product Slider" />
</div>
</div>
</NavLink>






</SlickSlider>

</div>
)
}
}



