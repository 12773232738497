import React, {Component} from 'react';
import Oursolution from '../components/Oursolution';
import {fetchAllNewProducts } from '../actions/newproduct';
import { connect } from 'react-redux';
import Products from '../components/Products';
import Carousel from 'react-bootstrap/Carousel'

class NewLauchContainer extends Component {

componentDidMount() {
window.scrollTo(0, 0);
this.props.dispatch(fetchAllNewProducts());
}


render(){

const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
      

const {newproducts}  = this.props;








return (

<div id="main-wrapper">
<div className="product-section section pt-30 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45  pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec newlauchhome" >
<div className="container">
<div className="row">
<div className="col">
<div className="section-title mb-2 mb-xs-20 text-center feature-item-txt product-feature">
<h2 className="leafbg" style={{'fontWeight':'600','color':'#002317'}}>Featured Products</h2>
</div>
</div>
</div>
</div>
</div>

<div className="product-section section pt-30 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45  pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 launches desktop-view">
<div className="container">
<div className="row">
{newproducts.map(post => {
return (
<Products post={post}  key={ post.id }/>
);
})}
</div>
</div>
</div>

<div className="product-section section pt-30 pt-lg-40 pt-md-65 pt-sm-60  pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 launches mobile-view mobile-tablet">
<div className="container">
<div className="row">
{newproducts.slice(0,3).map(post => {
return (
<Products post={post}  key={ post.id }/>
);
})}
</div>
</div>
</div>



</div>



)
}
}





const mapStateToProps = (state) => ({
   newproducts:state.newproducts,
    
    });




export default connect(
mapStateToProps,
null
)(NewLauchContainer);