import React from 'react';
import {NavLink } from 'react-router-dom'
import { IMG_BASE_URL } from '../actions/types';


export default ({product}) => {


const image_array = product?product.ingredients_images.split(','):'';
var image_array_length;
if(product && product.ingredients_images)
{
  image_array_length = product?product.ingredients_images.split(',').length :'';

}





return (

<div className="container">
  
  <div className="row" style={{'background':'#f8f8f8','padding':'30px 0px'}}>
  <div className="col">
  <div className="section-title mb-2 mb-xs-20 text-center">
<h2 className="leafbg1" style={{'fontWeight':'600','color':'#002619'}}>Key Ingredients</h2>
</div>

<p className="black-font" style={{'fontWeight':'500','fontSize':'18px','textAlign':'center'}}>Every Ingredient we use is Vegan, Natural and Free of Chemicals. Here are a few:</p>

 </div>

 </div>

 <div className="row" style={{'background':'#f8f8f8'}}>
 {/* <div className="col-md-3 col-6 ingredient-img"><img src={IMG_BASE_URL+'Ingredients/'+image_array[0]} alt=""/></div> */}
 {product && product.ingredients_images && image_array.map(function(item, i){

  if(image_array_length===1)
  {
    return (<div className="col-md-4 offset-md-4 ingredient-img-1 text-center mb-2" ><img src={IMG_BASE_URL+'Ingredients/'+item} className="wow zoomIn" alt="Ingredients" title="Ingredients" /></div>);

  }
  else if(image_array_length===2)
  {
  return (<div className="col-md-3 offset-md-2 ingredient-img-2  text-center"><img src={IMG_BASE_URL+'Ingredients/'+item} className="wow zoomIn" alt="Ingredients" title="Ingredients" /></div>);

  }
  else if(image_array_length===3)
  {
  return (<div className="col-md-3 ingredient-img text-center mx-auto "><img src={IMG_BASE_URL+'Ingredients/'+item} className="wow zoomIn" alt="Ingredients" title="Ingredients" /></div>);

  }
  else
  {
return (<div className="col-md-3 col-6 ingredient-img"><img src={IMG_BASE_URL+'Ingredients/'+item} className="wow zoomIn" alt="Ingredients" title="Ingredients" /></div>);
  }
  
 
})}
          
</div>

 <div className="col" style={{'background':'#f8f8f8'}}>
                <div className="single-product-description">
                <p style={{'textAlign':'center'}}><NavLink exact to="/ingredients" className="descount-sticker_btn">Read More...</NavLink></p>
            </div>  
            </div> 

                  <div className="row" style={{'background':'#f8f8f8','padding':'30px 0px'}}>
                  <div className="col">
                  <div className="mb-2 mb-xs-20  black-font mt-4">
<h4 className="black-font" style={{'fontWeight':'600','fontSize':'20px','textAlign':'center'}}> Complete List Of Ingredients</h4>
<p style={{'fontSize':'17px','textAlign':'center'}}> {product.ingredients_label}</p>
</div>
                  </div>
                  </div>
</div>
)
    }














