import React from 'react';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import parse from 'html-react-parser';
import Addtocart from '../components/Addtocart';

export default ({ post,index }) => {
   const categorydata={id:post.id,feature_image:post.feature_image,quantity:1,price:post.price,title:post.title,shipping:post.shipping,sizes:post.sizes,slug:post.slug};
const finalindex=index+1;
let className = 'col-lg-4 col-md-6 col-sm-12 prodimgtop';
if (finalindex%2 === 0)
{
className += ' divswiperight';
}
return (
<div>	
<div class="product-list-view productlistitem">
<div class="product-list-item mb-40">
<div class="row align-items-center">
<div style={{'display':'block', 'marginBottom':'15px'}}>
	<div class={className} >
		<div class="single-product">
			<div class="product-img mb-0 mb-xs-25">
				<Link to ={'/product/' +post.slug} >
					<img src={IMG_BASE_URL+'products/'+post.feature_image}  alt=""/>
				</Link>
				{/*<span class="descount-sticker">-10%</span>
				<span class="sticker">New</span>*/}
			</div>
		</div>
	</div>

	<div class="col-lg-8 col-md-6 col-sm-12 divswipeleft" >
		<div class="product-content-shop-list">
			<div class="product-content cat-txt-cen">
				<Link to ={'/product/' +post.slug} >
					<h3>{post.title}</h3>
				</Link>
				<h4 class="price"><span class="price new-price">Rs. {post.current_price}  </span></h4>
			 
			  <p>{parse(post.short_description.substr(0, 500)) } </p>
				<div class="ratting" style={{'marginBottom':'20px'}}>
					<img src="/images/icons/h1_1.png" alt="icon1.png" class="add-compare" style={{'width':'68px','height':'69px'}}/>
					<img src="/images/icons/h2_1.png" alt="icon2.png" class="add-compare" style={{'width':'68px','height':'69px'}}/>
					<img src="/images/icons/h3_1.png" alt="icon3.png" class="add-compare" style={{'width':'68px','height':'69px'}}/>
					<img src="/images/icons/h4_1.png" alt="icon4.png" class="add-compare" style={{'width':'68px','height':'69px'}}/>
					<img src="/images/icons/h5_1.png" alt="icon5.png" class="add-compare" style={{'width':'68px','height':'69px'}}/>
					<img src="/images/icons/h6_1.png" alt="icon6.png" class="add-compare" style={{'width':'68px','height':'69px'}}/>
				</div>
				<div class="price">
					<Addtocart produtdata={ categorydata} />
				</div>
			</div>
		</div>
	</div>
</div>
</div>
</div>
<div style={{'clear':'both', 'height':'15px'}}></div>
</div>
</div>
)
}



