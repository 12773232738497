import React, {Component} from 'react';
import Header from '../components/Header';
import Shipping from '../components/Shipping';
import Footer from '../components/Footer';
import { fetchAllCartpro } from '../actions/cart';
import { fetchAlladdress } from '../actions/address';
import {connect } from 'react-redux'

class ShippingContainer extends Component {


	componentDidMount() {

this.props.dispatch(fetchAlladdress());
this.props.dispatch(fetchAllCartpro());


}

  componentDidUpdate(prevProps) {
        
        let slug = this.props.match.path; 
  
        if (slug!== prevProps.match.path) {
       this.props.dispatch(fetchAllCartpro());
          
        }
    }






    render(){


  const {cartproduct,address}  = this.props;

if (!cartproduct || !address) return <div id="main-wrapper"><Header/>
<div className="product-section section pt-100 pt-lg-40 pt-md-65 pt-sm-60 pt-xs-45 pb-20 pb-lg-40 pb-md-40 pb-sm-30 pb-xs-20 oursolutionsec">
<div className="container">
<div className="row loaderclass" ><img src="/images/loader.gif" style={{'width' : '200px', 'height' : '200px', 'margin' : '0px auto'}}  alt="loading" />
<p style={{'width' : '100%', 'verticalAlign' : 'middle', 'textAlign' : 'center', 'padding' : '0px'}}>
Loading in Progress...
</p>
</div>
</div>
</div><Footer/>
</div>;


        return (

        <div id="main-wrapper">
        <Header/>
		<div class="container">
<div class="row">
<div class="col">
<div class="section-title section-title-shipping mb-2 mb-xs-20 text-center feature-item-txt">
<h2 class="leafbg" style={{'fontWeight':'600','color':'#002317'}}>Checkout</h2>
</div>
</div>
</div>
</div>
        <Shipping cartproduct={cartproduct} address={address} />
        <Footer/>
        </div>

     )
    }
}




const mapStateToProps = state => {
return {
cartproduct: state.cartproduct.cartproduct,
address: state.address.address

};
};


export default connect(
  mapStateToProps,
  null
)(ShippingContainer);