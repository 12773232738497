import React, {Component} from 'react';
import Header from '../components/Header';
import Blogdetail from '../components/Blogdetail';
import { fetchAllblogdetail } from '../actions/blogdetail';
import BlogComment from '../containers/BlogCommentContainer';
import Footer from '../components/Footer';
import { connect } from 'react-redux';


class BlogDetailContainer extends Component {


componentDidMount() {
        window.scrollTo(0, 0);
		   let slug = this.props.match.params.slug;
	     this.props.dispatch(fetchAllblogdetail(slug));
}




    render(){

	const {blogdetail}  = this.props;

	

        return (

        <div id="main-wrapper">
        <Header/>

        <Blogdetail blogdetail={blogdetail}/>
        <BlogComment slug={this.props.match.params.slug}/>


        <Footer/>
        </div>

     )
    }
}



const mapStateToProps = state => {
  return {
    blogdetail: state.blogdetail
  };
};






export default connect(
  mapStateToProps,
  null
)(BlogDetailContainer);