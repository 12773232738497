import React, {Component} from 'react';
import { connect } from 'react-redux';
import Pagination from "../components/Pagination";
import { fetchAllproductreview } from '../actions/allreviews';
import { fetchAllproreview } from '../actions/reviewsubmit';
import { withRouter} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import { IMG_BASE_URL } from '../actions/types';
import {dateFormat} from '../utils/dateFormat';



 class AllReviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalRecords: "",
      totalPages: "",
      pageLimit: "",
      currentPage: "",
    };
  }

    componentDidMount() {
	  window.scrollTo(0, 0);
	    var slug = this.props.match.params.slug;
	    this.props.dispatch(fetchAllproductreview(slug));
	    this.props.dispatch(fetchAllproreview(slug));
	}

  componentDidUpdate(prevProps) {
  		var slug = this.props.match.params.slug;

    	if (slug!== prevProps.match.params.slug) {
	    this.props.dispatch(fetchAllproductreview(slug));
    }
    }


    showProducts = allreviews => {
    var result = null;
    if (allreviews) {
      result = allreviews.map((review, index) => {
        return <ul>
       <li>
            <div className="product-comment">
                <div className="product-comment-content">
                    <div class="ratingfill"  style={{'textAlign': 'left'}}>
                    <i className={"fa fa-leaf " + (review.rating < 1 ? 'unselctleaf' : '')}></i>
                    <i className={"fa fa-leaf " + (review.rating < 2 ? 'unselctleaf' : '')}></i>
                    <i className={"fa fa-leaf " + (review.rating < 3 ? 'unselctleaf' : '')}></i>
                    <i className={"fa fa-leaf " + (review.rating < 4 ? 'unselctleaf' : '')}></i>
                    <i className={"fa fa-leaf " + (review.rating < 5 ? 'unselctleaf' : '')}></i>
                     <span className="stock available" title="Availability" style={{'color':'#3F5736','text-transform': 'uppercase','font-size:':'16px !important','font-weight':'bold'}}>  <span> &nbsp; {review.headline}</span></span>
                </div>
                <p className="meta" style={{'textAlign': 'left'}}>
                    <strong>{review.name}</strong> - <span>{dateFormat(review.review_date)}</span>
                        <div className="description">
                            {review.review}
                        </div>
                    </p>
                </div>
            </div>
        </li>
      </ul>;
      });
    }
    return result;
  };

 

  onChangePage = data => {
   
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
     if(data.page){
      this.props.history.push({pathname: '/allreviews/'+this.props.reviewsubmit[0].tof_product.slug+'?page='+data.page})
    }
  };

    
  render() {
    const {reviewsubmit}  = this.props;

    const {allreviews}  = this.props;


    var {
      totalPages,
      currentPage,
      pageLimit,
    } = this.state;

   



    var rowsPerPage = [];
    rowsPerPage = allreviews.results;
    if (!allreviews) return <div>Loading...</div>;

        return (
        <div>

<Header/>
        <div className="single-product-section section pt-100 pt-lg-30 pt-md-70 pt-sm-60 pt-xs-50 pb-30 pb-lg-30 pb-md-70 pb-sm-30 pb-xs-20" id="ckproductid">
            <div className="container">
			 {reviewsubmit.length<1?<center>
                    <br/>
                    <br/>
                        <h4>No Product Review Found for this Product</h4>
                    <br/>
                    <br/>
                    <Link to ='/shop' >
                        <a href="/shop" class="btn">CONTINUE SHOPPING</a><br/>
                    </Link>
                   <br/>
                   <br/>
                </center>: 
                <div className="row">
                    <div className="col-md-5">
                       <div className="single-product mb-30"> 
                            <div className="product-img shop-product-list">

                               <Link to ={'/product/' +this.props.reviewsubmit[0].tof_product.slug} className="prodimgsrc" >
                                <img src={IMG_BASE_URL+'products/'+this.props.reviewsubmit[0].tof_product.feature_image} alt=""/>
                                </Link>
                            </div>
                       </div>
                    </div>


                    <div className="col-md-7">
                       <div className="product-details-content" style={{'textAlign':'center'}}>
                            <div className="product-nav" style={{'display':'none'}}>
                                <a href="/#"><i className="fa fa-angle-left"></i></a>
                                <a href="/#"><i className="fa fa-angle-right"></i></a>
                            </div>

                    {this.props.reviewsubmit.length>0?<h2>All Reviews for {this.props.reviewsubmit[0].tof_product.title}</h2>:''} 
                       {this.props.reviewsubmit.length==0 ? '':this.props.reviewsubmit[0].tof_product.stock >'0' ?<div className="stock available" title="Availability">Availability: <span>In stock</span></div>:
                    <div className="stock available" title="Availability">Availability: <span>Out of Stock</span></div>}               
                                                          
                    <div className="single-product-reviews">
                        <i className={"fa fa-leaf " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 1 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-leaf " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 2 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-leaf " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 2.5 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-leaf " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 3.5? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-leaf " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 4.5 ? 'unselctleaf' : '')}></i>
                    </div>
                 
                <div className="row" id="ingredients">
                <div className="col-md-12">

                    <h3>{allreviews.count} Reviews </h3> 
                    <div className="product-review-tab">
                        {this.showProducts(rowsPerPage)}
                    </div> 


        <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8">
                <div className="section product_list_mng">
                    <div className="container-fluid">
                        <div className="box_pagination">
                            <div className="row"> 
                              <div className="col-xs-12 text-center">
                                    <Pagination totalRecords={allreviews.count}
                                     pageLimit={pageLimit || 10} initialPage={1}
                                      pagesToShow={5} onChangePage={this.onChangePage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>  

            </div>

        </div>   
		</div>
	 }
	</div>
</div>
<Footer/>
</div> 
        )
    }
}



const mapStateToProps = state => {
    return {
        allreviews: state.allreviews,
        reviewsubmit: state.reviewsubmit,

    };
}

export default withRouter(connect(
  mapStateToProps,
)(AllReviews));

