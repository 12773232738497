import React, {Component} from 'react';
//import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../layout/Alert';

const initialState =  {
    email:'',
    emailError:'',
    rtoken:'',
  };



export default class NewSubscribe extends Component {
    

  
 constructor(props) {
    super(props);
    this.state = {
    email:'',
    emailError:'',
    rtoken:'',
    };
 
    //this._reCaptchaRef = React.createRef();
  }

  handleInputChange = event => {
     const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };

   validate = () => {
    let emailError = "";
  
    if (!this.state.email) {
       emailError = "Email cannot be blank.";

    }

   if(this.state.email){
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
       emailError = "Email is not valid.";
      }
    }



    
    if (emailError) {
      this.setState({emailError });
      return false;
    }

    return true;
  };



handleSubmit = e => {
    e.preventDefault();
    const isValid = this.validate();
   // this._reCaptchaRef.current.execute();


    if (isValid) {

      
     
      this.props.onAddSubscribe(this.state);
      this.setState(initialState);
    }
};

componentDidMount() {
//this._reCaptchaRef.current.execute();

} 


redoCaptcha() {
  this._reCaptchaRef.current.reset();
  this._reCaptchaRef.current.execute();
}


handleChange = (value) => {


if (this._reCaptchaRef) {
 this.setState({rtoken:value});
 this._reCaptchaRef.current.reset();

      }



  
  };



render(){


const { loading } = this.props.subscribe;


return (
         <div>
           <Alert  msgdivid="subscription"/>
            <div className="section-title mb-20 mb-xs-20 text-center">
            {/*<h2 className="" style={{'fontWeight':'600','color':'#000000', 'fontSize':'30px'}}>Come as you are</h2>*/}
            <h2 className="" style={{'fontWeight':'700','color':'#000000', 'fontSize':'30px','marginTop':'70px'}}>Save 15% on your Order</h2>
         </div>

       
             <div className="newsletter-content text-center">
             {/*<p className="footertext">Enter your email elow to be the first to know aout new collections and product launches. <br />Get the latest updates and more.</p>*/}             
             <p className="footertext">Subscribe to our (NON-SPAMMY) newsletter to receive all our Exclusive Offers and Discounts!</p>
                        <div className="subscription-form ">
                        
                                            <form  id="mc-form2" className="mc-form" onSubmit={ this.handleSubmit }>
              <div className="form-group col-md-10 offset-md-1">
              <input type="text" placeholder="Enter your email address" style={{'width':'75%'}} name="email"
              onChange={ this.handleInputChange }
              onBlur={this.validate}
              value={ this.state.email } className="form-control subscribeform headsearchsub" />
             

              <button type="submit" className="descount-sticker_btn" style={{'padding':'11px 20px','float':'right','marginTop':'-49px','marginRight':'4px','color':'#ffffff !important'}}><span style={{'color':'#ffffff !important'}}>SIGN UP</span></button>
              <div className='warning-msg' style={{'textAlign':'left'}}>
                {this.state.emailError}
              </div>
              </div>
          
          <div className="form-group ">
            
          </div>

       



         {/*  <ReCAPTCHA
  ref={this._reCaptchaRef} size="invisible"
    sitekey="6LfuGNkZAAAAADta57Vcyk4T7Hl_l-mwpQKaPco4"
    onChange={this.handleChange}
    onExpired={this.redoCaptcha}
  />*/}


                                            </form> 
            </div>
                                    
        </div>
    </div>
        
        )
    }
}



                                    
                                            
                                    