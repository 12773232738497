import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Oursolution from './OursolutionsContainer';

export default class SolutionsContainer extends Component {
    render(){
        return (

        <div id="main-wrapper">
        <Header/>
        <Oursolution/>
        <Footer/>
        </div>



        )
    }
}