import { DEALS_FETCH_PRODUCT,BASE_URL,LOGOUT} from './types';
import axios from 'axios';
const apiUrl = BASE_URL+'dealspro';

export const fetchTPosts = (fetchproduct) => {
  return {
    type: DEALS_FETCH_PRODUCT,
    fetchproduct
  }
};

export const fetchAllDealsProducts = () => {
  return (dispatch) => {
    return axios.get(apiUrl)
		.then(response => {
			if(response.data.logstatus ==='failed'){
			}else{
				dispatch(fetchTPosts(response.data));
			}	
		})
      .catch(error => {
        if(error.response.status === 401){
			dispatch({ type: LOGOUT });
		}
        throw(error);
      });
  };
};