import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CartSubContainer from '../containers/CartSubContainer';
const CartPopup = ({ cartpopup }) =>


  cartpopup !== null &&
  cartpopup.length > 0 &&
  cartpopup.map(alert => {
      return  <div key={alert.id} className="header-cart-dropdown carvisible">

      <div>
     <CartSubContainer/>
					</div>
    </div>
    return <div ></div>
})

CartPopup.propTypes = {
  cartpopup: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  cartpopup: state.cartpopup
});

export default connect(mapStateToProps)(CartPopup);




		   

				
			
              




