import React, {Component} from 'react';
import Alert from '../layout/Alert';
import Header from '../components/Header';
import Footer from '../components/Footer';

const initialState =  {
name:'',
lastname:'',
email:'',
phone:'',
password:'',
password_confirmation:'',	
nameError:'',
lastError:'',
emailError:'',
phoneError:'',
passwordError:'',
password_confirmationError:'',
whatsapp_preference:true,
email_preference:true,
sms_preference:true	
};

const passwordRegex = /[!@#$%^&*(),.?~":{}|<>]/g;


export default class Login extends Component {

constructor(props) {
super(props); 
this.handleInputChange = this.handleInputChange.bind(this);
}
state = initialState;

handleInputChange(event) {
const target = event.target;
const value = target.type === 'checkbox' ? target.checked : target.value;
const name = target.name;
this.setState({
[name]: value
});
}


validateUKPhoneNumber(phoneNumber) {
    // Remove all spaces and special characters from the phone number
    phoneNumber = phoneNumber.replace(/\s|\(|\)/g, '');

    // Check if the phone number contains only digits after removing spaces and special characters
    if (/^\d+$/.test(phoneNumber)) {
        // Check if the length of the phone number is between 9 and 14 digits inclusively
        if (phoneNumber.length >= 9 && phoneNumber.length <= 14) {
            return true; // Valid UK phone number
        } else {
            return false; // Invalid UK phone number
        }
    } else {
        // Check if the phone number with spaces in specific positions
        var regex = /^(\+44\s?\d{4}\s?\d{6}|\(0\d{4}\)\s?\d{6}|\(0\d{3}\)\s?\d{3}\s?\d{4}|\(0\d{2}\)\s?\d{4}\s?\d{4})$/;
        return regex.test(phoneNumber);
    }
}



validate = () => {
let nameError = "";
let lastError = "";	
let emailError = "";				
let phoneError = "";
let passwordError = "";
let password_confirmationError = "";
if (!this.state.name) {
nameError = "Name cannot be blank.";
}
if (!this.state.lastname) {
lastError = "Lastname cannot be blank.";
} 


 if (!this.state.phone) {
 phoneError = "Phone number cannot be blank.";
 }
 else if (!this.validateUKPhoneNumber(this.state.phone) && this.state.phone !== "") 
 {
  phoneError = "Please enter valid Phone Number";
 }

if (!this.state.email) {
emailError = "Email cannot be blank.";
}
if(this.state.email){
let lastAtPos = this.state.email.lastIndexOf('@');
let lastDotPos = this.state.email.lastIndexOf('.');

if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
emailError = "Please enter a valid email address (Ex: maxdoe@domain.com).";
}
}

if (!this.state.password) {
passwordError = "Password cannot be blank.";
}
else if (this.state.password.length < 8) {
passwordError = "Password must be 8 characters long.";
} 


if (!this.state.password_confirmation) {
password_confirmationError = "Confirm Password cannot be blank.";
}

if(this.state.password_confirmation !='' && this.state.password !=''){
if (this.state.password_confirmation != this.state.password) {
password_confirmationError = "Password and Confirm Password are not same.";
}
}


if (nameError || lastError || emailError || phoneError || passwordError ||password_confirmationError) {
this.setState({nameError,lastError,emailError,phoneError,passwordError,password_confirmationError});
return false;
}
return true;
};

handleSubmit = e => {
e.preventDefault();
const isValid = this.validate();
if (isValid) {	
this.props.onSignup(this.state);
window.scrollTo(0, 0);
this.setState(initialState);
}
};


render(){
const { loading } = this.props.auth;
return (
<div id="main-wrapper">
<Header/>
<div className="login-register-section section pt-100 pt-lg-40 pt-md-70 pt-sm-60 pt-xs-50 pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
<div className="container">
<div className="col-md-12 col-sm-12">
<h1 className="tt-title-subpages noborder" style={{'textAlign':'center','marginBottom':'20px'}}>CREATE AN ACCOUNT</h1>
</div>
<div style={{'clear':'both','height':'1px'}}></div>

<div className="tt-login-form">
<div className="row justify-content-center">
<div className="col-md-8 col-lg-6"> 	
<Alert />			
<div className="form-default">
<form  method="post" onSubmit={ this.handleSubmit }>

<div id="resp" className="col-md-6 col-md-offset-3">
</div>

<div style={{'clear':'both','height':'2px'}}></div>

<div className="form-group">
<label for="loginInputName">FIRST NAME *</label>
<input type="text" name="name" className="form-control" onChange={ this.handleInputChange } value={ this.state.name } required="" placeholder="Enter First Name"/>
<div className='warning-msg'>{this.state.nameError}</div>
</div>


<div className="form-group">
<label for="loginInputName">LAST NAME *</label>
<input type="text" name="lastname" className="form-control" onChange={ this.handleInputChange } value={ this.state.lastname } required="" placeholder="Enter Last Name"/>
<div className='warning-msg'>{this.state.lastError}</div>
</div>

<div className="form-group">
<label for="loginLastName">PHONE NUMBER *</label>
<input name="phone" placeholder="Phone Number"  className="form-control "maxlength="20" onChange={ this.handleInputChange } value={this.state.phone} type="text" required=""/>
<div className='warning-msg'>{this.state.phoneError}</div>
</div>

<div className="form-group">
<label for="loginInputEmail">E-MAIL *</label>
<input name="email" placeholder="Email" onChange={ this.handleInputChange } value={ this.state.email } className="form-control" type="email" required=""/>
<div className='warning-msg'>{this.state.emailError}</div>                                       
</div>

<div className="form-group">
<label for="loginInputPassword">PASSWORD *</label>
<input name="password" placeholder="Password" className="form-control" onChange={ this.handleInputChange } value={ this.state.password } type="password"/>
<div className='warning-msg'>{this.state.passwordError}</div> 
<div className='warning-msg'>(Password has to be at least 8 characters).</div>
</div>

<div className="form-group">
<label for="loginInputPassword">CONFIRM PASSWORD *</label>
<input name="password_confirmation" placeholder="Confirm Password" className="form-control" type="password" onChange={ this.handleInputChange } value={ this.state.password_confirmation }/>                                      
<div className='warning-msg'>{this.state.password_confirmationError}</div> 
</div>  
<label for="loginInputPassword">Stay Connected for latest updates:</label>
<div className="form-group">

<input type="checkbox" name="email_preference" onChange={ this.handleInputChange } checked={this.state.email_preference} className="regist_check" />
<label for="status">Email</label>
<br />

<input type="checkbox" name="sms_preference" onChange={ this.handleInputChange } checked={this.state.sms_preference} className="regist_check" />
<label for="status">SMS</label>
<br />

<input type="checkbox" name="whatsapp_preference" onChange={ this.handleInputChange } checked={this.state.whatsapp_preference} className="regist_check" />
<label for="status">Whatsapp</label>
<br />
</div>

<div className="row">
<div className="col-auto">
<div className="form-group">                                            


<button class="btn"  disabled={loading}>{loading && <span>Sending...</span>}
{!loading && <span>REGISTER</span>}</button>


</div>
</div>
<div className="col-auto align-self-center">
<div className="form-group">
<ul className="additional-links">
<li>or <a href="https://www.theorganicforest.co.uk">Return to Store</a></li>
</ul>
</div>
</div>
</div>

</form>
</div>

</div>
</div>
</div>
</div>

</div>  
<Footer/>
</div>  
)
}
}