import { FETCH_TESTIMONIAL } from '../actions/types';

export default function fetchtestimonialReducer(state = [], action) {
  switch (action.type) {
    case FETCH_TESTIMONIAL:
      return action.fetchtestimonial;
    default:
      return state;
  }
}
