import React, {Component} from 'react';
import SlickSlider from "react-slick";
import { IMG_BASE_URL } from '../actions/types';
import parse from 'html-react-parser';
import Addtowishlist from '../components/Addtowishlist';
import ProductShare from '../components/ProductShare';
import {Currency} from '../utils/currency';
import Addtocart from '../components/Addtocart';
import Alert from '../layout/Alert';
import Products from '../components/Products';
import ProductSlider from '../components/ProductSlider';
import { connect } from 'react-redux';
import { deleteWishlist } from '../actions/wishlist';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';

class ProductDetail extends Component {

constructor(props) {
    super(props);
    this.state = {
        nav1: null,
        nav2: null,
        img: '0% 0%',
        clicks:1,
        wishlisticon:'fa fa-heart fa-2x'
    };
  }

  deletetoWishlist(post) {
    this.props.onDeletetowishlist(post);
  }

  handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.pageX - left) / width * 100
    const y = (e.pageY - top) / height * 100
    this.setState({ img: `${x}% ${y}%` })
  }

 IncrementItem = () => {
    this.setState({ clicks: this.state.clicks + 1 });
  }
  DecreaseItem = () => {
    this.setState({ clicks: this.state.clicks > 1 ? this.state.clicks - 1 :1});
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
   }



render(){
const {product} =this.props;
const {reviewsubmit}  = this.props;
const image_array = this.props.product?this.props.product[0].ingredients_images.split(','):'';
const prodid= product[0].id;
const {isAuthenticated, loading}=this.props.auth;
var wishlist = [];
var wishlistid =[];
if(this.props.wishlist){
    const id = this.props.wishlist.filter(post =>  post.product_id == prodid);

    if(id[0]){
      const abc=id[0].wishlist_id;
      wishlistid.push(abc);
    }
    const length= id.length;
    wishlist.push(length);
}

   return (
    <div>


        <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.product[0].meta_title}</title>
        <meta name="description" content={this.props.product[0].meta_description} />
        <meta name="Keywords" content={this.props.product[0].meta_keyword}/>
        </Helmet>

{product.map((post) => {
    const productdata={id:post.id,feature_image:post.feature_image,quantity:this.state.clicks,price:post.price,title:post.title,shipping:post.shipping,sizes:post.sizes,slug:post.slug};

var arrayImage= [{'original':IMG_BASE_URL+'products/'+post.feature_image,thumbnail:IMG_BASE_URL+'products/'+post.feature_image}];
var values=post.tof_product_galleries;
for(var i=0; i<values.length; i++){


  arrayImage.push({'original':IMG_BASE_URL+'gallery/'+values[i]['image'],thumbnail: IMG_BASE_URL+'gallery/'+values[i]['image']});
}


    return(
  <div className="single-product-section section pt-40 pt-lg-30 pt-md-70 pt-sm-60 pt-xs-50 pb-30 pb-lg-30 pb-md-70 pb-sm-30 pb-xs-20" id="ckproductid">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 prodmrglft">
                        <div className=""> 

                        <ProductSlider arrayImage={arrayImage}/>



                        
                       
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="product-details-content">
                     <div className="product-nav" style={{'display':'none'}}>
                        <a href="/#"><i className="fa fa-angle-left"></i></a>
                        <a href="/#"><i className="fa fa-angle-right"></i></a>
                    </div>
                    <h2>{post.title}</h2>
					<div className="single-product-price">
                        <span className="price new-price">{Currency}{post.price}</span>
                        {/*<span className="regular-price">${post.previous_price}</span>*/}
                    </div>
                   <div className="single-product-reviews" style={{'paddingBottom':'15px'}}>
                        <i className={"fa fa-star " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 1 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-star " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 2 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-star " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 2.5 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-star " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 3.5 ? 'unselctleaf' : '')}></i>
                        <i className={"fa fa-star " + (reviewsubmit.reduce((a, c) => (a +c.rating/reviewsubmit.length), 0) < 4.5 ? 'unselctleaf' : '')}></i>
                        &nbsp; <Link to ={'/allreviews/'+post.slug}>Read {reviewsubmit.length} reviews</Link>
                    </div>
                    <div className="product-description" style={{'color':'#383838','fontSize':'16px','lineHeight':'28px','paddingBottom':'15px','borderBottom':'2px solid #cccccc'}}>
                        {parse(post.short_description)}
                    </div>
                   <div className="product-description">
                        
                        {post.sizes=== null ||post.sizes=='undefined'||post.sizes==''?'':<span style={{'fontSize':'15px'}}><span style={{'marginRight':'10px', 'color':'#000000','fontWeight':'500'}}>Quantity</span>{/*{post.sizes}*/}</span>}
                    </div>
                       
                            <div className="single-product-quantity single-productpg">
                            <div className="col-md-0 displayblock"></div>
                            <div className="col-lg-6 col-md-6 col-12 displayblock" style={{'paddingLeft':'0px'}}>
                                 

                                <div className="pro-qty prodqntdiv">
                                    <button class="prodqnty" onClick={this.DecreaseItem}>-</button>
                                        <input type="text" value={this.state.clicks}  className="prodqntytxtbx" />
                                    <button className="prodqntyplus"  onClick={this.IncrementItem}>+</button>
                                </div>
                                <div className="add-to-link prodbtnbx" style={{'marginTop':'0px'}}>
                                    <Addtocart produtdata={ productdata} />
                                </div>
                               
                                </div>
                           



                            {!loading && (
                                     isAuthenticated?
                                   wishlist[0]==1?<div className="col-lg-3 col-md-6 col-12 displayblock prodwishicon"><a href={null} onClick={() => this.deletetoWishlist(wishlistid)}><i className={this.state.wishlisticon} style={{'fontSize':'20px !important'}}></i></a><span style={{'top':'-15px','position':'relative'}}></span></div>:<div className="col-lg-3 col-md-6 col-12 displayblock prodwishicon"><Addtowishlist produtdata={post}/></div>:<div className="col-lg-3 col-md-6 col-12 displayblock prodwishicon">{/*<Addtowishlist produtdata={post}/>*/}</div>)

                          }
                        <div className="col-md-12" style={{'clear':'both','height':'5px'}}></div>
                            <div className="col-md-2" style={{'display':'inlineBlock','float':'left'}}></div>
                            <div className="col-md-7" style={{'display':'inlineBlock','float':'left'}}>
                            {/*<Alert  msgdivid={"wishlist"+post.id}/>*/}
                            </div>
                            <div className="col-md-3" style={{'display':'inlineBlock','float':'left'}}></div>
                            
                           </div>

						   {/*<ProductShare producturl={post.slug} />*/}
<div className="wishlist-compare-btn" style={{'padding':'20px 0px','borderBottom':'2px solid rgb(204, 204, 204)','borderTop':'2px solid rgb(204, 204, 204)'}}>
<img src="/images/icons/stamp-1.png" alt="Cruelty Free" title="Cruelty Free" class="add-compare" style={{'width':'75px','height':'auto'}}/>
<img src="/images/icons/stamp-2.png" alt="Dermatologically Tested" title="Dermatologically Tested" class="add-compare" style={{'width':'75px','height':'auto'}}/>
<img src="/images/icons/stamp-4.png" alt="Toxic Free" title="Toxic Free" class="add-compare" style={{'width':'75px','height':'auto'}}/>
<img src="/images/icons/stamp-5.png" alt="Anti Pollution" title="Anti Pollution" class="add-compare" style={{'width':'75px','height':'auto'}}/>
<img src="/images/icons/stamp-7.png" alt="Plant Trees" title="Plant Trees" class="add-compare" style={{'width':'75px','height':'auto'}}/>
<img src="/images/icons/stamp-8.png" alt="For All Skin" title="For All Skin" class="add-compare" style={{'width':'75px','height':'auto'}}/>
</div>
                   
</div>
</div>
</div>
</div>
</div>)}
)}
</div>

        )
    }
}

const mapDispatchToProps = dispatch => {
  return {
      onDeletetowishlist: post => {
     dispatch(deleteWishlist(post));
    },
   };
};

const mapStateToProps = state => {
    return {
      auth: state.auth,
      wishlistdelete:state.wishlistdelete
    };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetail);
