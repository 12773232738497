import React, {Component} from 'react';
import { connect } from 'react-redux';

import Login from '../components/Login';
import { loginPost,authCheck } from '../actions/auth';

const mapDispatchToProps = dispatch => {
  return {
    onLoginPost: post => {
     dispatch(loginPost(post));
       
    }
  };
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
