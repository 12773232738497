import React, {Component} from 'react';
import Alert from '../layout/Alert';
import Header from '../components/Header';
import Footer from '../components/Footer';

const initialState =  {
	new_password :'',
	new_passwordError:'',
	confirm_password:'',
	confirm_passwordError:'',
	frm_show: 'none',
	token_msg: ''	
};
  
export default class Resetpassword extends Component {
	

	
	constructor(props) {
        super(props); 
		this.handleInputChange = this.handleInputChange.bind(this);
    }
	
	state = initialState;

	componentDidMount() {
        
        window.scrollTo(0, 0);
		const encodedata =this.props.match.params.data;
        let data = encodedata;
		let buff = new Buffer(data, 'base64');
		let mydata = buff.toString('ascii');
		var array = mydata.split("&");
			
		var userid = array[0].split("="),
		fordate = array[2].split("="),
		uid  = userid[1];	
		fordate = fordate[1];		
		
		//Add difference in minutes
		const diff = 7;
		var forget_date = new Date(fordate);
		forget_date.setMinutes(forget_date.getMinutes() + diff);
		forget_date = new Date(forget_date);
		console.log(forget_date);
		
		var current_date = new Date();		
		console.log(forget_date +'++++'+ current_date);
		
		if(current_date >= forget_date){
			alert("Your activation link is expired now !");
			this.setState({
			  frm_show: 'none',
			  token_msg: "Your activation link is expired now! Try again."
			});
		} else {
			//alert("Change your password here!");
			this.setState({
			  frm_show: '',
			  token_msg: ""
			});
		}				
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
		  [name]: value
		});
	}
	
	
	validate = () => {			
		let new_passwordError = "";	
		let confirm_passwordError = "";	
		 const passwordRegex = /[!@#$%^&*(),.?":{}|<>]/g;
	        if (!this.state.new_password) {
	        new_passwordError = "Password cannot be blank";
	        } 
	        else if (this.state.new_password.length < 6) {
	        new_passwordError = "Password must be 6 characters long.";
	        } 
	       else if (!passwordRegex.test(this.state.new_password)) {
	       new_passwordError = "Invalid password. Must contain one special character";
	      }
		if (!this.state.confirm_password) {
			confirm_passwordError = "Confirm Password cannot be blank";
		}
		
		if (this.state.new_password && this.state.confirm_password) {
			if (this.state.new_password != this.state.confirm_password) {
				confirm_passwordError = " Password and Confirm Password cannot be blank";
			}
		}
		
		if (new_passwordError || confirm_passwordError) {
			this.setState({new_passwordError,confirm_passwordError});
			return false;
		}
		return true;
	};
  
	handleSubmit = e => {
		e.preventDefault();
		const isValid = this.validate();
		if (isValid) {	
			this.props.onreset(this.state);
			this.setState(initialState);
			this.props.history.push('/login')
		}
	};

	
    render(){
		const { loading } = this.props.reset;
    return (
	<div>
	 <Header/>
			<div className="login-register-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
			   <div className="container">
				  <div className="row">
					 <div className="col-md-12 col-sm-12">
						<h1 className="tt-title-subpages noborder" style={{'textAlign':'center'}}>RESET PASSWORD</h1>
					 </div>
				
					<div className="col-md-6 col-sm-6" style={{'margin':'0px auto'}}>
						<div className="customer-login-register">
						   <div className="form-login-title" style={{'display':'none'}}>
							Reset Password
						   </div>
						    <Alert />	

						   <br /><br />
			<h3 style={{'text-align':'center'}}>{this.state.token_msg}</h3>


						   <div className="login-form" style={{'display': this.state.frm_show}}>
						   
								<form  method="post" onSubmit={ this.handleSubmit }>
								
									<div className="form-group">
										<label for="loginInputEmail">New Password *</label>
										<input name="new_password" placeholder="New Password" onChange={ this.handleInputChange } value={ this.state.new_password } className="form-control" type="password" required=""/>
									<div style={{ fontSize:14,color: "red"}}>{this.state.new_passwordError}</div>
									<div style={{ fontSize:14,color: "grey"}}>(Password has to be at least 6 characters and 1 special character).</div>
									</div>


									<div className="form-group">
										<label for="loginInputEmail">Confirm Password *</label>
										<input name="confirm_password" placeholder="Confirm Password" onChange={ this.handleInputChange } value={ this.state.confirm_password } className="form-control" type="password" required=""/>
									<div style={{ fontSize:14,color: "red"}}>{this.state.confirm_passwordError}</div></div>

									
									 <div className="login-submit" style={{'textAlign':'center'}}>
										 <button class="btn"  disabled={loading}>{loading && <span>Sending...</span>}
											{!loading && <span>Submit</span>}</button>							
									 </div>
								</form>
						   </div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
		<Footer/>
	</div>
	   )
    }
}