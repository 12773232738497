import { FETCH_ACCOUNTINFO,BASE_URL,ACCOUNT_UPDATE,INFO_SUBMIT,LOGOUT} from './types';
import axios from 'axios';
import { setAlert } from './alert';

const apiUrl = BASE_URL+'userprofile/';


export const fetchTAccountinfo = (fetchaccountinfo) => {
  return {
    type: FETCH_ACCOUNTINFO,
    fetchaccountinfo
  }
};

export const fetchAllTAccountinfo = () => {
  return (dispatch) => {

    return axios.get(apiUrl)
      .then(response => {
			if(response.data.logstatus ==='failed'){
			   dispatch({ type: LOGOUT });
			}else{
				 dispatch(fetchTAccountinfo(response.data))
			}
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

const addapiUrl = BASE_URL+'updateuserprofile';

export const updateAccount = ({name,lastname,email,phone,gender,sms_preference,email_preference,whatsapp_preference}) => { 
  return (dispatch) => {
    dispatch(infoSubmit());
    return axios.post(addapiUrl, {name,lastname,email,phone,gender,sms_preference,email_preference,whatsapp_preference})
    .then(response => {		
			if(response.data.logstatus ==='failed'){
				dispatch(setAlert('Account has not been Updated successfully.', 'danger','infoupdate'));
			}else{
				dispatch(updateaccountsuccess(response.data))
				dispatch(setAlert('Account Updated successfully.', 'success','infoupdate'));
			}
    })
    .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};

export const updateaccountsuccess =  (post) => {
  return {
    type: ACCOUNT_UPDATE,
    payload: {
     name: post.name,
     lastname: post.lastname,
     phone: post.phone,
     gender: post.gender,
     email: post.email,
     sms_preference: post.sms_preference,
     email_preference: post.email_preference,
     whatsapp_preference: post.whatsapp_preference,
   }
  }
};


function infoSubmit() {
    return {
        type: INFO_SUBMIT
    }
}

