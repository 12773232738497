import { FETCH_SOLUTIONS } from '../actions/types';

export default function fetchsolutionsReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SOLUTIONS:
      return action.fetchsolutions;
    default:
      return state;
  }
}
