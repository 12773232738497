import React, {Component} from 'react';
import CartSubContainer from '../containers/CartSubContainer';

import {NavLink } from 'react-router-dom'


export default class CartDropdown extends Component {

render(){


return (
<div className="header-cart color-white">
           <NavLink exact to="/cart" ><img src="/images/bag.png" alt="Shopping Cart"/>{/*<i className="fa fa-shopping-cart" style={{'color':'#084639','marginLeft':'2px'}}></i>*/} <span>{this.props.cartcount}</span></NavLink>
                    
                    <div className="header-cart-dropdown carvisible1" >
                        <CartSubContainer/>
                    </div>
              
                </div>


 )
    }
}




