import { combineReducers } from 'redux';
import fetchtestimonial from './fetchtestimonialReducer';
import ingredient from './ingredientReducer';
import createcontact from './createcontactusReducer';
import fetchingredientrelated from './fetchingredproductReducer';
import products from './productReducer';
import alert from './alert';
import search from './searchReducer';
import relatedpro from './relatedproReducer';
import subscribe from './subscribeReducer';
import tag from './tagReducer';
import auth from './authReducer';
import fetchsolutions from './fetchsolutionsReducer';
import blog from './blogReducer';
import blogdetail from './blogdetailReducer';
import categorypro from './categoryproReducer';
import subcategorypro from './subcategoryproReducer';
import review from './reviewReducer';
import wishlist from './wishlistReducer';
import changepass from './changepassReducer';
import orderhistory from './orderhistoryReducer';
import cartproduct from './cartReducer';
import order from './orderReducer';
import orderdetail from './orderdetailReducer';
import accountinfo from './accountinfoReducer';
import blogcomment from './blogcommentReducer';
import productdetail from './productdetailReducer';
import reviewsubmit from './reviewsubmitReducer';
import relatedproducts from './relatedproductsReducer';
import address from './addressReducer';
import cartpopup from './cartpopupReducer';
import forgot from './forgotReducer';
import reset from './resetReducer';
import orderstatus from './orderstatusReducer';
import allreviews from './allreviewsReducer';
import bestproducts from './bestproductReducer';
import dealproducts from './dealsproductReducer';
import newproducts from './newproductReducer';


export default combineReducers({
    fetchtestimonial:fetchtestimonial,
    alert:alert,
    ingredient:ingredient,
    createcontact:createcontact,
    fetchingredientrelated:fetchingredientrelated,
    products:products,
    search:search,
    relatedpro:relatedpro,
    subscribe:subscribe,
    tag:tag,
    auth:auth,
    fetchsolutions:fetchsolutions,
    blog:blog,
    blogdetail:blogdetail,
    categorypro:categorypro,
    subcategorypro:subcategorypro,
    review:review,
    wishlist:wishlist,
    changepass:changepass,
    orderhistory:orderhistory,
    cartproduct:cartproduct,
    order:order,
    orderdetail:orderdetail,
    accountinfo:accountinfo,
    blogcomment:blogcomment,
    productdetail:productdetail,
    reviewsubmit:reviewsubmit,
    relatedproducts:relatedproducts,
    address:address,
    cartpopup:cartpopup,
    forgot:forgot,
    reset:reset,
    orderstatus:orderstatus,
    allreviews:allreviews,
    bestproducts:bestproducts,
    dealproducts:dealproducts,
    newproducts:newproducts,

});

