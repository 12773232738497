import { DEALS_FETCH_PRODUCT } from '../actions/types';

export default function dealsproductReducer(state = [], action) {
  switch (action.type) {
    case DEALS_FETCH_PRODUCT:
      return action.fetchproduct;
    default:
      return state;
  }
}
