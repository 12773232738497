import { BASE_URL,FETCH_PROREVIEW,ADD_REVIEW,LOGOUT} from './types';
import { setAlert } from './alert';

import axios from 'axios';

const apiUrl = BASE_URL+'reviewfetch/'

export const fetchTproreview = (review) => {
  return {
    type: FETCH_PROREVIEW,   
      review
  }
};

export const fetchAllproreview = (slug) => {
  return (dispatch) => {
    return axios.get(apiUrl+slug)
    .then(response => {
		if(response.data.logstatus ==='failed'){
		}else{
		 dispatch(fetchTproreview(response.data))
		}	  
    })
    .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};




const apiUrlreview = BASE_URL+'sendreview/';

export const createReview = ({review,headline,review_date,rating,slug }) => {
  return (dispatch) => {
    return axios.post(apiUrlreview+slug, {review,headline,review_date,rating,slug})
		.then(response => {			
			if(response.data.logstatus ==='failed'){			   
				dispatch(setAlert('Review has not been Added Successfully', 'danger','reviewsubmit'));
			}else{
				dispatch(createReviewSuccess(response.data))
				dispatch(setAlert('Review Added Successfully', 'success','reviewsubmit'));
			}			
		})
		.catch(error => {
			if(error.response.status === 401){
				dispatch({ type: LOGOUT });
			}
        throw(error);
      });
  };
};

export const createReviewSuccess =  (post) => {
  return {
    type: ADD_REVIEW,
    payload: {
       review: post.review,
      headline: post.headline,
      review_date: post.review_date,
      rating:post.rating,
      slug:post.slug
    }
  }
};
