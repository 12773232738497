import { FETCH_ORDERDETAIL,BASE_URL,LOGOUT} from './types';
import axios from 'axios';

const apiUrl = BASE_URL+'orderdetail/';


export const fetchOrderDetail = (orderdetail) => {
  return {
    type: FETCH_ORDERDETAIL,
    orderdetail
  }
};

export const fetchAllOHistory = (ordernumber) => {
  return (dispatch) => {
    return axios.get(apiUrl+ordernumber)
      .then(response => {
        console.log(response.data);
        dispatch(fetchOrderDetail(response.data))
      })
      .catch(error => {
        if(error.response.status === 401){
        dispatch({ type: LOGOUT });
      }
        throw(error);
      });
  };
};
